import React, { useState, useEffect, useCallback } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { FieldText } from "../../components/FieldText";
import { FieldList } from "../../components/FieldList";
import { FieldListSearch } from "../../components/FieldListSearch";
import { RegistroPrefactura } from "../../components/RegistroPrefactura";
import { Dialog } from "../../components/Dialog";
import { Sort } from "../../components/Sort";
import { AUTORIZACIONES_POR_PAGINA } from "../../constants";
import { usePermissions } from "../../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroom, faCheckDouble } from "@fortawesome/free-solid-svg-icons";

export const Autorizaciones = () => {
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [autorizaciones, setAutorizaciones] = useState([]);
  const [metadataTable, setMetadataTable] = useState([]);
  const [metadataFilters, setMetadataFilters] = useState([]);
  const [listas, setListas] = useState({});
  const [filters, setFilters] = useState({});
  const [nextPage, setNextPage] = useState(0);
  const [sortActive, setSortActive] = useState({});
  const [selectedAutorizaciones, setSelectedAutorizaciones] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedAllFiltered, setSelectedAllFiltered] = useState(false);
  const [allFilteredTotal, setAllFilteredTotal] = useState(0);
  const [dialog, setDialog] = useState("");
  const [triggerGetAutorizaciones, setTriggerGetAutorizaciones] =
    useState(true);

  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({
      Modulo: "Autorizacion_Prefacturas",
      TipoDefinicion: "grilla",
    })
      .then((res) => isMounted && setMetadataTable(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerFiltrosBusqueda("Autorizacion_Prefacturas")
      .then((res) => isMounted && setMetadataFilters(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerListas("Autorizacion_Prefacturas")
      .then((res) => isMounted && setListas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    setAllSelected(
      autorizaciones.length > 0 &&
        autorizaciones.every((a) =>
          selectedAutorizaciones.some((sA) => sA.idEntidad === a.idEntidad)
        )
    );
  }, [autorizaciones, selectedAutorizaciones]);

  const getAutorizaciones = useCallback(
    (page = 1) => {
      const data = {};
      data.Paginacion = getPagination(page);
      if (Object.keys(filters).length > 0)
        Object.entries(filters).forEach(([nameFilter, valueFilter]) => {
          data[nameFilter] = valueFilter.Valor;
        });

      setLoading(true);
      API.obtenerAutorizacionesPrefacturas(data)
        .then((res) => {
          if (page === 1) setAutorizaciones(res);
          else setAutorizaciones([...autorizaciones, ...res]);

          if (res.length < AUTORIZACIONES_POR_PAGINA) setNextPage(0);
          else setNextPage(page + 1);
        })
        .catch((error) => {
          if (error.code !== 401)
            toast.error(
              "Error al obtener Autorizaciones: " + error.msg?.Message
            );
          API.DEVELOP && console.log(error);
        })
        .finally(() => setLoading(false));

      setTriggerGetAutorizaciones(false);
    },
    [filters, autorizaciones]
  );

  useEffect(() => {
    let isMounted = true;
    triggerGetAutorizaciones && isMounted && getAutorizaciones();
    return () => (isMounted = false);
  }, [triggerGetAutorizaciones, getAutorizaciones]);

  const handleChangeFilter = (id, value) => {
    const filtrosHijos = metadataFilters.filter(
      (filter) => filter.FiltroPadre === id
    );

    const newFilters = { ...filters };
    if (typeof value === "string") {
      if (value === "") delete newFilters[id];
      else {
        newFilters[id] = { Valor: value };
        filtrosHijos.forEach(
          (filter) => delete newFilters[filter.NombreFiltro]
        );
      }
    } else {
      if (Object.keys(value).length === 0) delete newFilters[id];
      else {
        newFilters[id] = value;
        filtrosHijos.forEach(
          (filter) => delete newFilters[filter.NombreFiltro]
        );
      }
    }

    setSelectedAutorizaciones([]);
    setAllSelected(false);
    setFilters(newFilters);
    setSelectedAllFiltered(false);
    setTriggerGetAutorizaciones(true);
  };

  const getPagination = (page) => ({
    Pagina: page,
    CantidadRegistros: AUTORIZACIONES_POR_PAGINA,
  });

  const sort = (field, value) => {
    setSortActive({
      IdCampo: field,
      Orden: value,
    });
    setTriggerGetAutorizaciones(true);
  };

  const handleSelectionChange = (id, checked) => {
    let newSelectedAutorizaciones = [...selectedAutorizaciones];
    if (checked) {
      const { idEntidad, idAutorizacion } = autorizaciones.find(
        (autorizacion) => autorizacion.idEntidad === id
      );
      const autorizacionSelectedProps = { idEntidad, idAutorizacion };
      newSelectedAutorizaciones.push(autorizacionSelectedProps);
    } else {
      newSelectedAutorizaciones = newSelectedAutorizaciones.filter(
        (autorizacion) => autorizacion.idEntidad !== id
      );
    }
    setSelectedAutorizaciones(newSelectedAutorizaciones);
  };

  const toggleAll = () => {
    if (allSelected) {
      setSelectedAutorizaciones((prevSelected) =>
        prevSelected.filter(
          (sA) => !autorizaciones.some((a) => a.idEntidad === sA.idEntidad)
        )
      );
    } else {
      const newSelectedAutorizaciones = structuredClone(selectedAutorizaciones);
      newSelectedAutorizaciones.push(
        ...autorizaciones
          .filter(
            (a) =>
              !selectedAutorizaciones.some((sA) => sA.idEntidad === a.idEntidad)
          )
          .map(({ idEntidad, idAutorizacion }) => ({
            idEntidad,
            idAutorizacion,
          }))
      );
      setSelectedAutorizaciones(newSelectedAutorizaciones);
    }
  };

  const selectAll = () => {
    setSelectedAllFiltered(!selectedAllFiltered);

    const data = {};
    for (const key in filters) {
      if (filters[key].hasOwnProperty("Valor")) {
        data[key] = filters[key].Valor;
      }
    }

    if (selectedAllFiltered) {
      setAllFilteredTotal(0);
    } else {
      setLoading(true);
      API.obtenerNumeroAutorizaciones(data)
        .then((res) => setAllFilteredTotal(res))
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    }
  };

  const darAutorizacionMasiva = () => {
    const data = {};
    data.allAutorizaciones = selectedAllFiltered;
    if (selectedAllFiltered) {
      const parsedFilters = {};
      for (const key in filters) {
        if (filters[key].hasOwnProperty("Valor")) {
          parsedFilters[key] = filters[key].Valor;
        }
      }
      data.filtros = parsedFilters;
    } else {
      data.autorizaciones = selectedAutorizaciones.map(
        (sA) => sA.idAutorizacion
      );
    }

    setLoading(true);
    API.darAutorizacion(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefacturas autorizadas");
          setSelectedAutorizaciones([]);
          setAllSelected(false);
          setDialog("");
          setTriggerGetAutorizaciones(true);
        } else if (res.Estado === "KO") {
          toast.info("No se pudo autorizar las prefacturas\n" + res.Mensaje);
        } else {
          toast.error("Error al autorizar las prefacturas\n" + res.Mensaje);
        }
      })
      .catch((error) => {
        toast.error(
          "Error al autorizar las prefacturas\n" + error.msg?.Message
        );
        API.DEVELOP && console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const cancelarAutorizacionMasiva = (message) => {
    const data = {};
    data.allAutorizaciones = selectedAllFiltered;
    data.comentario = `[Cancelación] ${message}`;
    if (selectedAllFiltered) {
      const parsedFilters = {};
      for (const key in filters) {
        if (filters[key].hasOwnProperty("Valor")) {
          parsedFilters[key] = filters[key].Valor;
        }
      }
      data.filtros = parsedFilters;
    } else {
      data.autorizaciones = selectedAutorizaciones.map((sA) => sA.idEntidad);
    }

    setLoading(true);
    API.cancelarAutorizacion(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefacturas rechazadas");
          setSelectedAutorizaciones([]);
          setAllSelected(false);
          setDialog("");
          setTriggerGetAutorizaciones(true);
        } else if (res.Estado === "KO") {
          toast.info("No se pudo rechazar las prefacturas\n" + res.Mensaje);
        } else {
          toast.error("Error al rechazar las prefacturas\n" + res.Mensaje);
        }
      })
      .catch((error) => {
        toast.error("Error al rechazar las prefacturas\n" + error.msg?.Message);
        API.DEVELOP && console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const totalSelected = allFilteredTotal || selectedAutorizaciones.length;

  return (
    <S.Container>
      <Loading hidden={!loading} />
      {dialog === "confirmacionValidar" && (
        <Dialog
          type="confirm"
          message={`Se van a se van a validar ${totalSelected} prefacturas, ¿desea continuar?`}
          labelConfirm="Continuar"
          confirm={darAutorizacionMasiva}
          cancel={() => setDialog("")}
        />
      )}
      {dialog === "confirmacionRechazar" && (
        <Dialog
          type="input"
          message={`Se van a se van a rechazar ${totalSelected} prefacturas, ¿desea continuar?\n\u00A0\nComentario cancelación`}
          labelConfirm="Continuar"
          confirm={cancelarAutorizacionMasiva}
          cancel={() => setDialog("")}
        />
      )}

      <S.Filters count={metadataFilters.length}>
        {metadataFilters.map((filter) => {
          const lista = listas[filter.NombreFiltro];
          const nombreFiltro = filter.NombreFiltro;
          const currentOption = filters[nombreFiltro]
            ? {
                value: filters[nombreFiltro].Valor,
                label: filters[nombreFiltro].Etiqueta,
              }
            : { value: "" };

          switch (filter.Tipo) {
            case "text":
            default:
              return (
                <FieldText
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro || ""}
                  label={filter.Etiqueta}
                  value={filters[filter.NombreFiltro]?.Valor || ""}
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            case "enum":
              const options = lista?.Valores.map((option) => ({
                value: option.Valor,
                label: option.Etiqueta,
              }));

              return (
                <FieldList
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro || ""}
                  type="enum"
                  label={filter.Etiqueta}
                  currentOption={currentOption}
                  options={options}
                  handleChange={handleChangeFilter}
                  canBeEmpty={true}
                  readOnly={false}
                  required={false}
                />
              );

            case "relate":
              return (
                <FieldListSearch
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro || ""}
                  label={filter.Etiqueta}
                  listName={filter.NombreFiltro || ""}
                  currentOption={currentOption}
                  handleChange={handleChangeFilter}
                  canBeEmpty={true}
                  readOnly={false}
                  required={false}
                  parent={filter.FiltroPadre}
                  idParent={
                    filters[
                      metadataFilters.find(
                        (f) => f.NombreFiltro === filter.FiltroPadre
                      )?.IdFiltro
                    ]?.Valor
                  }
                />
              );
          }
        })}
      </S.Filters>

      <S.ActionBar>
        <S.Buttons>
          <b>
            {(allFilteredTotal || selectedAutorizaciones.length) +
              " seleccionadas"}
          </b>
          <button
            id="cleanSelectedIds"
            title="Limpia toda la selección"
            disabled={
              selectedAutorizaciones.length === 0 && !selectedAllFiltered
            }
            onClick={() => {
              setSelectedAutorizaciones([]);
              setSelectedAllFiltered(false);
              setAllFilteredTotal(0);
            }}
          >
            <FontAwesomeIcon icon={faBroom} size="lg" pull="left" />
            Limpiar selección
          </button>
          <button
            id="selectAllByArea"
            title={`${
              selectedAllFiltered ? "Deselecciona" : "Selecciona"
            } todos las prefacturas filtradas (incluidas las no visibles)`}
            disabled={autorizaciones.length === 0}
            onClick={selectAll}
          >
            <FontAwesomeIcon icon={faCheckDouble} size="lg" pull="left" />
            {selectedAllFiltered ? "Deseleccionar" : "Seleccionar"} todas las
            filtradas
          </button>
        </S.Buttons>

        <S.Buttons>
          <b style={{ marginLeft: "auto" }}>Acciones Masivas</b>
          <button
            id="darAutorizacion"
            key="darAutorizacion"
            className="forward"
            title="Validar"
            disabled={totalSelected === 0}
            onClick={() => setDialog("confirmacionValidar")}
          >
            ✔ Validar
          </button>
          <button
            id="cancelarAutorizacion"
            key="cancelarAutorizacion"
            className="backward"
            title="Anular"
            disabled={totalSelected === 0}
            onClick={() => setDialog("confirmacionRechazar")}
          >
            ✘ Rechazar
          </button>
        </S.Buttons>
      </S.ActionBar>

      <S.Table>
        <S.ColGroup selectable>
          <S.Col />
          <S.Col />
          {metadataTable.map((campo) => (
            <S.Col key={campo.id} />
          ))}
          <S.Col />
        </S.ColGroup>
        <S.Tr>
          <S.Th>
            <S.Checkbox>
              <S.InputCheckbox
                id="allSelected"
                type="checkbox"
                checked={allSelected || selectedAllFiltered}
                onChange={toggleAll}
                disabled={selectedAllFiltered}
              />
            </S.Checkbox>
          </S.Th>
          <S.Th />
          {metadataTable.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              <S.HeaderColumn>
                <span>{campo.etiqueta}</span>
                {campo.ordenable && (
                  <Sort
                    field={campo.nombreCampo}
                    sortActive={sortActive}
                    onClick={(value) => sort(campo.nombreCampo, value)}
                  />
                )}
              </S.HeaderColumn>
            </S.Th>
          ))}
          <S.Th>Acciones</S.Th>
        </S.Tr>
        {autorizaciones.map((autorizacion) => (
          <RegistroPrefactura
            key={autorizacion?.id}
            identifier="id"
            metadataTable={metadataTable}
            prefactura={autorizacion}
            type="autorizaciones"
            update={() => setTriggerGetAutorizaciones(true)}
            selectable={true}
            handleSelectionChange={handleSelectionChange}
            selected={
              selectedAutorizaciones.some(
                (sA) => sA.idEntidad === autorizacion?.idEntidad
              ) || selectedAllFiltered
            }
            disabled={selectedAllFiltered}
            toast={toast}
            setLoading={setLoading}
            access={permissions}
          />
        ))}
      </S.Table>

      <S.Pagination>
        <button
          disabled={nextPage === 0}
          onClick={() => getAutorizaciones(nextPage)}
        >
          {nextPage === 0
            ? "No hay más autorizaciones"
            : "Mostrar más autorizaciones..."}
        </button>
      </S.Pagination>
    </S.Container>
  );
};
