import React, { useState, useEffect, useRef } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { FieldText } from "../../components/FieldText";
import { FieldDate } from "../../components/FieldDate";
import { Sort } from "../../components/Sort";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { PROVEEDORES_POR_PAGINA, DELAY_TEXT_FILTER } from "../../constants";
import { RegistroProveedor } from "../../components/RegistroProveedor";
import { usePermissions } from "../../context";

export const Proveedores = () => {
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [proveedores, setProveedores] = useState([]);
  const [metadataTable, setMetadataTable] = useState([]);
  const [metadataFilters, setMetadataFilters] = useState([]);
  const [filters, setFilters] = useState({});
  const [nextPage, setNextPage] = useState(0);
  const [sortActive, setSortActive] = useState({});
  const [triggerGetProveedores, setTriggerGetProveedores] = useState(true);
  const delayTimer = useRef();

  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({ Modulo: "Proveedores", TipoDefinicion: "grilla" })
      .then((res) => isMounted && setMetadataTable(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerFiltrosBusqueda("Proveedores")
      .then((res) => isMounted && setMetadataFilters(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;

    triggerGetProveedores && isMounted && getProveedores();

    return () => (isMounted = false);
  }, [triggerGetProveedores]); // eslint-disable-line react-hooks/exhaustive-deps

  const getProveedores = (page = 1) => {
    setLoading(true);
    const data = {};
    data.Paginacion = getPagination(page);
    if (Object.keys(filters).length > 0)
      data.Filtros = Object.entries(filters).map((filter) => ({
        IdFiltro: filter[0],
        ValorFiltro: filter[1].Valor,
      }));
    if (Object.keys(sortActive).length > 0) data.Ordenacion = sortActive;
    data.MostrarEliminados = false;

    API.obtenerProveedores(data)
      .then((res) => {
        if (res) {
          if (page === 1) setProveedores(res);
          else setProveedores([...proveedores, ...res]);

          if (res.length < PROVEEDORES_POR_PAGINA) setNextPage(0);
          else setNextPage(page + 1);
        }
      })
      .catch((error) => {
        if (error.code !== 401)
          toast.error("Error al obtener Proveedores: " + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));

    setTriggerGetProveedores(false);
  };

  const getPagination = (page) => ({
    Pagina: page,
    CantidadRegistros: PROVEEDORES_POR_PAGINA,
  });

  const handleChangeFilter = (id, value) => {
    const filtroPadreName = metadataFilters.find(
      (filter) => filter.IdFiltro === id
    ).NombreFiltro;
    const filtrosHijos = metadataFilters.filter(
      (filter) => filter.FiltroPadre === filtroPadreName
    );

    const newFilters = { ...filters };
    if (typeof value === "string") {
      if (value === "") delete newFilters[id];
      else {
        newFilters[id] = { Valor: value };
        filtrosHijos.forEach((filter) => delete newFilters[filter.IdFiltro]);
      }
    } else {
      if (Object.keys(value).length === 0) delete newFilters[id];
      else {
        newFilters[id] = value;
        filtrosHijos.forEach((filter) => delete newFilters[filter.IdFiltro]);
      }
    }

    setFilters(newFilters);
    API.defaultSource.cancel();
    clearTimeout(delayTimer.current);
    delayTimer.current = setTimeout(() => {
      setTriggerGetProveedores(true);
    }, DELAY_TEXT_FILTER);
  };

  const sort = (field, value) => {
    setSortActive({ IdCampo: field, Orden: value });
    setTriggerGetProveedores(true);
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.Filters count={metadataFilters.length}>
        {metadataFilters.map((filter) => {
          switch (filter.Tipo) {
            case "text":
              return (
                <FieldText
                  key={filter.IdFiltro}
                  id={filter.IdFiltro || ""}
                  label={filter.Etiqueta}
                  value={filters[filter.IdFiltro]?.Valor || ""}
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            case "date":
              return (
                <FieldDate
                  key={filter.IdFiltro}
                  id={filter.IdFiltro}
                  label={filter.Etiqueta}
                  value={filters[filter.IdFiltro]?.Valor.split("T")[0] || ""}
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            default:
              return null;
          }
        })}
      </S.Filters>
      <S.Buttons>
        <Link to={`/proveedores/create`}>
          <Button label={"Crear Nuevo Proveedor"} />
        </Link>
      </S.Buttons>
      <S.Table>
        <S.ColGroup>
          <S.Col />
          {metadataTable.map((campo) => (
            <S.Col key={campo.id} />
          ))}
          <S.Col />
        </S.ColGroup>
        <S.Tr>
          <S.Th />
          {metadataTable.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              <S.HeaderColumn>
                <span>{campo.etiqueta}</span>
                {campo.ordenable && (
                  <Sort
                    field={campo.nombreCampo}
                    sortActive={sortActive}
                    onClick={(value) => sort(campo.nombreCampo, value)}
                  />
                )}
              </S.HeaderColumn>
            </S.Th>
          ))}
          <S.Th />
        </S.Tr>
        {proveedores.map((proveedor) => (
          <RegistroProveedor
            key={proveedor.id}
            metadataTable={metadataTable}
            proveedor={proveedor}
            type="proveedores"
            update={() => setTriggerGetProveedores(true)}
            toast={toast}
            setLoading={setLoading}
            access={permissions}
          />
        ))}
      </S.Table>
      <S.Pagination>
        <button
          disabled={nextPage === 0}
          onClick={() => getProveedores(nextPage)}
        >
          {nextPage === 0
            ? "No hay más proveedores"
            : "Mostrar más proveedores..."}
        </button>
      </S.Pagination>
    </S.Container>
  );
};
