import { useCallback, useEffect, useState } from "react";
import * as API from "../../api";
import { toast } from "react-toastify";
import { usePermissions } from "../../context";
import { TABS } from "./constants.js";
import { handleDownload } from "../../utils";
import {
  EngagedSectionComponent,
  BillableSectionComponent,
  ListSectionComponent,
} from "./sections.jsx";

const useIONEngagedPage = () => {
  const { permissions } = usePermissions();
  const [area, setArea] = useState("");
  const [exercise, setExercise] = useState("");
  const [engagedFormData, setEngagedFormData] = useState({});
  const [
    cargarArchivoComprometidoRevisado,
    setCargarArchivoComprometidoRevisado,
  ] = useState({});
  const [disabledApplyBTN, setDisabledApplyBTN] = useState(true);
  const [disabledLoadExpensesTemplateBTN, setDisabledLoadExpensesTemplateBTN] =
    useState(true);
  const [accessEngagedSection, setAccessEngagedSection] = useState(false);
  const [accessBillabeSection, setAccessBillableSection] = useState(false);
  const [accessListSection, setAccessListSection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suplier, setSuplier] = useState({ value: "", label: "" });
  const [suplierFilter, setSuplierFilter] = useState({ value: "", label: "" });
  const [registers, setRegisters] = useState([]);
  const [businessUnitList, setBusinessUnitList] = useState([]);
  const [businessSelected, setBussinesSelected] = useState({
    value: "",
    label: "",
  });
  const [areaList, setAreaList] = useState([]);
  const [areaSelected, setAreaSelected] = useState({ value: "", label: "" });
  const [exerciseList, setExerciseList] = useState([]);
  const [exerciseSelected, setExerciseSelected] = useState({
    value: "",
    label: "",
  });
  const [stateOT, setStateOT] = useState({
    value: "",
    label: "",
  });
  const [stateOTList, setStateOTList] = useState([]);
  const [numberOT, setNumberOT] = useState("");
  const [page, setPage] = useState(1);
  const [totalStatusSummary, setTotalStatusSummary] = useState([]);
  const [isAdminIon, setIsAdminIon] = useState(false);
  const [isCoordinadorIon, setIsCoordinadorIon] = useState(false);
  const [exportOptions, setExportOptions] = useState({
    PF: false,
    SP: false,
    FD: false,
    NF: false,
    SF: false,
    CDPF: false,
  });
  const [metadataRegisters, setMetadataRegisters] = useState([]);
  const [typeBillable, setTypeBillable] = useState({ value: "", label: "" });
  const [billableDates, setBillableDates] = useState({});
  const [facturado, setFacturado] = useState({ value: "", label: "" });

  const LIST_SIZE = 20;
  const listTypeFacturable = {
    Lista: "TipoFacturableLista",
    MasFilas: false,
    MostrarVacio: 0,
    Valores: [
      { value: "PF", label: "Prefacturas PF" },
      { value: "SP", label: "Suplidos SP" },
      { value: "FD", label: "Factura Directa FD" },
      { value: "NF", label: "No Facturable NF" },
      { value: "SF", label: "Sin Factura SF" },
    ],
  };

  const listFacturado = {
    Lista: "FacturadoLista",
    MasFilas: false,
    MostrarVacio: 0,
    Valores: [
      { value: null, label: "" },
      { value: true, label: "Si" },
      { value: false, label: "No" },
    ],
  };

  const updateSummary = useCallback(() => {
    const data = {};
    data.Ejercicio = parseInt(exerciseSelected.value) || 0;
    data.AreaDepartamento = areaSelected.value;

    setLoading(true);
    API.obtenerResumen(data)
      .then((res) => {
        setTotalStatusSummary(res.ResumenComprometidos);
        setIsAdminIon(res.EsAdministradorION);
        setIsCoordinadorIon(res?.EsCoordinadorION);
        const hasPF = res.ResumenComprometidos.some(
          (e) => e.TipoFacturable === "PF"
        );
        setExportOptions({ ...exportOptions, PF: hasPF, CDPF: hasPF });
      })
      .catch((error) => {
        if (error.code !== 401)
          toast.error("Error al obtener tabla resumen: " + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  }, [areaSelected, exerciseSelected, exportOptions]);

  const callBusinessUnitList = () => {
    setLoading(true);
    const dataSend = {
      Lista: "UnidadesNegocio",
    };
    API.obtenerLista(dataSend)
      .then((result) => {
        const bussinesUnitList = result.Valores.map((item) => {
          return { label: item.Etiqueta, value: item.Valor };
        });
        setBusinessUnitList(bussinesUnitList);
      })
      .catch((e) =>
        toast.error(`No se ha podido cargar la lista de unidades de negocio`)
      )
      .finally(() => setLoading(false));
  };

  const callAreaList = useCallback(() => {
    setLoading(true);
    const dataSend = {
      Lista: "AreaComprometidos",
      ListaRelacional: {
        NombreLista: "UnidadesNegocio",
        IdEntidadRelacional: businessSelected.value,
      },
    };
    API.obtenerLista(dataSend)
      .then((result) => {
        const data = result.Valores.map((e) => {
          return { label: e.Etiqueta, value: e.Valor };
        });
        setAreaList(data);
      })
      .catch((e) => toast.error(`No se ha podido cargar la lista de área`))
      .finally(() => setLoading(false));
  }, [businessSelected.value]);

  const callExerciseList = useCallback(() => {
    setLoading(true);
    const dataSend = {
      Lista: "EjecicioArea",
      ListaRelacional: {
        NombreLista: "AreaComprometidos",
        IdEntidadRelacional: areaSelected.value,
      },
    };
    API.obtenerLista(dataSend)
      .then((result) => {
        const data = result.Valores.map((e) => {
          return { label: e.Etiqueta, value: e.Valor };
        });
        setExerciseList(data);
      })
      .catch((e) => toast.error(`No se ha podido cargar la lista de ejercicio`))
      .finally(() => setLoading(false));
  }, [areaSelected.value]);

  const callStateOTList = () => {
    setLoading(true);
    const dataSend = { Lista: "EstadosTrazabilidadInversa" };
    API.obtenerLista(dataSend)
      .then((result) => {
        const OTStateList = result.Valores.map((item) => {
          return { label: item.Etiqueta, value: item.Valor };
        });
        setStateOTList(OTStateList);
      })
      .catch((e) =>
        toast.error(`No se ha podido cargar la lista de unidades de negocio`)
      )
      .finally(setLoading(false));
  };

  useEffect(() => {
    callBusinessUnitList();
    callStateOTList();
  }, []);

  useEffect(() => {
    if (businessSelected?.value) {
      callAreaList();
    }
  }, [businessSelected, callAreaList]);

  useEffect(() => {
    if (areaSelected?.value) {
      callExerciseList();
    }
  }, [areaSelected, callExerciseList]);

  useEffect(() => {
    if (
      businessSelected?.value &&
      areaSelected?.value &&
      exerciseSelected?.value
    ) {
      setDisabledApplyBTN(false);
      setDisabledLoadExpensesTemplateBTN(false);
    }
  }, [
    businessSelected?.value,
    areaSelected?.value,
    exerciseSelected?.value,
    setDisabledApplyBTN,
    setDisabledLoadExpensesTemplateBTN,
  ]);

  useEffect(() => {
    if (
      !businessSelected?.value ||
      !areaSelected?.value ||
      (!exerciseSelected?.value && disabledApplyBTN === false)
    )
      setDisabledApplyBTN(true);
    setDisabledLoadExpensesTemplateBTN(true);
  }, [
    businessSelected?.value,
    areaSelected?.value,
    exerciseSelected?.value,
    setDisabledApplyBTN,
    disabledApplyBTN,
    setDisabledLoadExpensesTemplateBTN,
  ]);

  const getRegisters = useCallback(() => {
    if (
      !!businessSelected?.value &&
      !!areaSelected?.value &&
      !!exerciseSelected?.value
    ) {
      const data = {
        Ejercicio: parseInt(exerciseSelected.value),
        IdArea: areaSelected.value,
        Paginacion: {
          Pagina: page,
          CantidadRegistros: LIST_SIZE,
        },
      };
      if (suplierFilter.value) data.CodigoProveedor = suplierFilter.value;
      if (stateOT.value) data.IdEstadoTrazabilidadInversa = stateOT.value;
      if (numberOT) data.numeracionOT = numberOT;

      setLoading(true);
      API.obtenerComprometidos(data)
        .then((res) => setRegisters(res))
        .catch((error) => {
          API.DEVELOP && console.log(error);
          toast.error("Error al cargar comprometidos");
        })
        .finally(() => setLoading(false));
    }
  }, [
    areaSelected,
    businessSelected,
    exerciseSelected,
    page,
    suplierFilter,
    stateOT,
    numberOT,
  ]);

  const getMetadataRegisters = () => {
    setLoading(true);
    API.definicionPagina({
      Modulo: "IONComprometido",
      TipoDefinicion: "grilla",
    })
      .then((res) => setMetadataRegisters(res))
      .catch((error) => {
        API.DEVELOP && console.log(error);
        toast.error("Error al cargar metadata del Listado");
      })
      .finally(() => setLoading(false));
  };

  const loadListSection = useCallback(() => {
    getMetadataRegisters();
    getRegisters();
  }, [getRegisters]);

  const onChangeValuesListInput = (fieldId, fieldValue) => {
    const engagedSection = document.getElementById(TABS.label.engaged);
    const billableSection = document.getElementById(TABS.label.billable);
    const listSection = document.getElementById(TABS.label.list);

    const isEngagedSectionOpen = engagedSection.classList.contains("opened");
    const isBillableSectionOpen = billableSection.classList.contains("opened");
    const isListSectionOpen = listSection.classList.contains("opened");

    if (fieldId === "businessUnitSelect") {
      if (!businessSelected?.value || businessSelected?.value !== fieldValue) {
        setAreaSelected({ value: "", label: "" });
        setAreaList([]);
        setExerciseSelected({ value: "", label: "" });
        setExerciseList([]);
        setEngagedFormData({});
        setCargarArchivoComprometidoRevisado({});
        setSuplier({ value: "", label: "" });
        if (isEngagedSectionOpen) {
          engagedSection.click();
        }
        if (isBillableSectionOpen) {
          billableSection.click();
        }
        if (isListSectionOpen) {
          listSection.click();
        }

        setAccessEngagedSection(false);
        setAccessBillableSection(false);
        setAccessListSection(false);

        setSuplierFilter({ value: "", label: "" });
      }
    } else if (fieldId === "areaSelect") {
      if (!areaSelected?.value || areaSelected?.value !== fieldValue) {
        setExerciseSelected({ value: "", label: "" });
        setExerciseList([]);
        setEngagedFormData({});
        setCargarArchivoComprometidoRevisado({});
        setSuplier({ value: "", label: "" });
        if (isEngagedSectionOpen) {
          engagedSection.click();
        }
        if (isBillableSectionOpen) {
          billableSection.click();
        }
        if (isListSectionOpen) {
          listSection.click();
        }

        setAccessEngagedSection(false);
        setAccessBillableSection(false);
        setAccessListSection(false);

        setSuplierFilter({ value: "", label: "" });
      }
    } else if (fieldId === "exerciseSelect") {
      if (!exerciseSelected?.value || exerciseSelected?.value !== fieldValue) {
        setEngagedFormData({});
        setCargarArchivoComprometidoRevisado({});
        setSuplier({ value: "", label: "" });
        if (isEngagedSectionOpen) {
          engagedSection.click();
        }
        if (isBillableSectionOpen) {
          billableSection.click();
        }
        if (isListSectionOpen) {
          listSection.click();
        }

        setAccessEngagedSection(false);
        setAccessBillableSection(false);
        setAccessListSection(false);

        setSuplierFilter({ value: "", label: "" });
      }
    }
  };

  const handleChangeListInput = (fieldId, fieldValue, etiqueta) => {
    if (fieldId === "businessUnitSelect") {
      setBussinesSelected({ value: fieldValue || "", label: etiqueta || "" });
    } else if (fieldId === "areaSelect") {
      setAreaSelected({ value: fieldValue || "", label: etiqueta || "" });
      setArea(fieldValue);
    } else if (fieldId === "exerciseSelect") {
      setExerciseSelected({ value: fieldValue || "", label: etiqueta || "" });
      setExercise(fieldValue);
    }
    onChangeValuesListInput(fieldId, fieldValue);

    setExportOptions({
      PF: false,
      SP: false,
      FD: false,
      NF: false,
      SF: false,
      CDPF: false,
    });
  };

  /**
   * Cambiar los valores de la sección de Comprometido
   * @param {*} value
   * @param {*} prop
   */
  const handleEngagedDataChange = (value, prop) => {
    let auxData = { ...engagedFormData };
    auxData[prop] = value;

    setEngagedFormData(auxData);
  };

  /**
   * llenar valores de la data cunado se carga el fichero
   * @param {*} e
   */
  const handleLoadFileChange = (e) => {
    const idInput = e.target.id;
    const file = e.target.files[0];
    let reader = new FileReader();
    let base64;
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      base64 = reader.result.replace(/^data:.+;base64,/, "");
      let auxData = { ...engagedFormData };
      auxData.engagedFile = base64;
      auxData.engagedFileName = file.name;
      if (idInput === "comprometido") {
        setEngagedFormData(auxData);
      } else {
        setCargarArchivoComprometidoRevisado(auxData);
      }
    };
  };

  /**
   * Abrir popup para cargar archivo
   */
  const openPopUpLoadFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";
    input.id = "comprometido";
    input.onchange = handleLoadFileChange;
    // input.disabledle = showDeleted;
    input.click();
  };

  const openPopUpLoadFileComprometidoRevisado = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx";
    input.id = "comprometido-revisado";
    input.onchange = handleLoadFileChange;
    // input.disabledle = showDeleted;
    input.click();
  };

  /**
   * cargar comprometido
   */
  const handleSendEngaged = () => {
    const dataSend = {
      NombreFichero: engagedFormData.engagedFileName,
      Ejercicio: Number(exercise),
      AreaDepartamento: area,
      DatosArchivoComprometido: engagedFormData.engagedFile,
      Prorrateo: engagedFormData.prorrateo || false,
    };

    setLoading(true);
    API.subirComprometidos(dataSend)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("El fichero fue cargado exitosamente");
        } else if (res.Estado === "INFO") {
          toast.info(res.Mensaje);
        } else if (res.Estado === "WARNING") {
          toast.warning("El fichero no tiene el formato correcto");
          handleDownload(res);
        } else if (res.Estado === "KO") {
          toast.error(
            "Ha ocurrido un error en la carga del fichero\n" + res.Mensaje
          );
        }
      })
      .catch((error) => {
        toast.error(error.msg.Message);
      })
      .finally(() => {
        updateSummary();
        setLoading(false);
      });
  };

  const cargarComprometidoRevisado = () => {
    const dataSend = {
      NombreFichero: cargarArchivoComprometidoRevisado.engagedFileName,
      Ejercicio: Number(exercise),
      AreaDepartamento: area,
      DatosArchivoComprometido: cargarArchivoComprometidoRevisado.engagedFile,
      SaltarValidaciones: engagedFormData.skipValidations,
    };
    setLoading(true);
    API.subirComprometidosRevisados(dataSend)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("El fichero fue cargado exitosamente");
        } else {
          toast.error(
            `Ha ocurrido un error en la carga del fichero. ${res.Mensaje}`
          );
        }
      })
      .catch((e) => {
        toast.error(e.msg.Message);
      })
      .finally(() => {
        updateSummary();
        setLoading(false);
      });
  };

  /**
   * exportar datos comprometidos a excel
   */
  const exportEngagedData = () => {
    const dataSend = {
      Ejercicio: Number(exercise),
      AreaDepartamento: area,
      ExportarAcumulado: !!engagedFormData.exportAmount ? true : false,
      ExportarGestor: !!engagedFormData.exportGestor ? true : false,
      FechaDesdeComprometido: billableDates?.fechaDesde,
      FechaHastaComprometido: billableDates?.fechaHasta,
      Facturado: facturado?.value,
      TipoFacturable: typeBillable?.value,
    };
    setLoading(true);
    API.generarExcelComprometidos(dataSend)
      .then((res) => {
        if (res.Estado === "WARNING") {
          toast.success("Se han exportado los datos correctamente");
          handleDownload(res);
        } else if (res.Estado === "KO") {
          toast.error(res.Mensaje);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const downloadTemplateCargarGastosComprometidos = () => {
    setLoading(true);
    API.generarPlantillaCargarGastosComprometidos()
      .then((res) => {
        if (res.Estado === "OK") {
          handleDownload(res);
        } else {
          throw new Error(res.Mensaje);
        }
      })
      .catch((error) => {
        toast.error(`Error al descargar plantilla\n${error.message || ""}`);
        API.DEVELOP && console.error(error);
      })
      .finally(() => setLoading(false));
  };

  /**
   * boton aplicar abrir campos segun los permisos
   */
  const handleApplyBTN = async () => {
    const engagedAccess = permissions.IONComprometido.Acceso;
    const billableAccess = permissions.IONFacturable.Acceso;
    const listAccess = permissions.ION.Listar;

    await setAccessEngagedSection(engagedAccess);
    await setAccessBillableSection(billableAccess);
    await setAccessListSection(listAccess);

    const engagedSection = document.getElementById(TABS.label.engaged);
    const billableSection = document.getElementById(TABS.label.billable);
    // const listSection = document.getElementById(TABS.label.list);
    if (engagedAccess && !engagedSection.classList.contains("opened")) {
      engagedSection.click();
    }
    if (billableAccess && !billableSection.classList.contains("opened")) {
      billableSection.click();
    }
    setDisabledApplyBTN(true);

    updateSummary();
    setPage(1);
  };

  const handleLoadBillable = () => {
    const dataSend = {
      Ejercicio: Number(exercise),
      AreaDepartamento: area,
      Proveedor: suplier?.value,
    };
    setLoading(true);
    API.marcarFacturables(dataSend)
      .then((response) => {
        if (response.Estado === "OK") {
          toast.success(response.Mensaje);
        } else {
          toast.warning(response.Mensaje);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        updateSummary();
        setLoading(false);
      });
  };

  /**
   * cambio de proveedor
   * @param {*} val
   */
  const handleSupplierSelect = (id, value) => {
    setSuplier({ value: value.Valor, label: value.Etiqueta });
  };

  /**
   * cambio de proveedor para filtro de listado
   * @param {*} val
   */
  const handleSupplierFilterSelect = (id, value) => {
    setSuplierFilter({ value: value.Valor, label: value.Etiqueta });
  };

  const handleTypeBillableSelect = (id, value) => {
    setTypeBillable({ value: value.Valor, label: value.Etiqueta });
  };

  const handleFacturadoSelect = (id, value) => {
    setFacturado({ value: value.Valor, label: value.Etiqueta });
  };

  const handleBillableDatesChange = (name, value) => {
    setBillableDates((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const exportSelected = () => {
    const data = {
      Ejercicio: Number(exercise),
      AreaDepartamento: area,
      PF: exportOptions.PF,
      SP: exportOptions.SP,
      FD: exportOptions.FD,
      NF: exportOptions.NF,
      SF: exportOptions.SF,
      CDPF: exportOptions.CDPF && exportOptions.PF,
    };
    if (suplier.value) data.CodigoProveedor = suplier.value;

    setLoading(true);
    API.generarExcelFacturacion(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Fichero descargado");
          handleDownload(res);
        } else if (res.Estado === "INFO") {
          toast.info(res.Mensaje);
          if (res.FicheroData) handleDownload(res);
        } else if (res.Estado === "KO") {
          toast.error(res.Mensaje);
        }
      })
      .catch((e) => {
        toast.error("Ha ocurrido un error al exportar");
        console.log(e);
      })
      .finally(() => {
        updateSummary();
        setLoading(false);
      });
  };

  const handleStateOTSelect = (id, value) => {
    setStateOT({ value: value.Valor, label: value.Etiqueta });
  };

  const recalcularProrrateos = () => {
    const data = {
      IdAreaDepartamento: areaSelected.value,
      Ejercicio: parseInt(exerciseSelected.value) || 0,
    };

    setLoading(true);
    API.actualizacionProrrateo(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prorrateos recalculados");
        } else if (res.Estado === "KO") {
          toast.error(res.Mensaje);
        }
      })
      .catch((e) => {
        toast.error("Ha ocurrido un error al recalcular prorrateos");
        console.log(e);
      })
      .finally(() => {
        updateSummary(); ///tmp
        setLoading(false);
      });
  };

  const ACCORDION_METADATA = [
    {
      label: TABS.label.engaged,
      access: accessEngagedSection,
      content: (
        <EngagedSectionComponent
          loading={loading}
          engagedFormData={engagedFormData}
          setEngagedFormData={setEngagedFormData}
          openPopUpLoadFile={openPopUpLoadFile}
          permissions={permissions}
          exercise={exercise}
          area={area}
          handleSendEngaged={handleSendEngaged}
          handleEngagedDataChange={handleEngagedDataChange}
          exportEngagedData={exportEngagedData}
          cargarArchivoComprometidoRevisado={cargarArchivoComprometidoRevisado}
          openPopUpLoadFileComprometidoRevisado={
            openPopUpLoadFileComprometidoRevisado
          }
          cargarComprometidoRevisado={cargarComprometidoRevisado}
          totalStatusSummary={totalStatusSummary}
          recalcularProrrateos={recalcularProrrateos}
          isAdminIon={isAdminIon}
          isCoordinadorIon={isCoordinadorIon}
          typeBillable={typeBillable}
          handleTypeBillableSelect={handleTypeBillableSelect}
          billableDates={billableDates}
          handleBillableDatesChange={handleBillableDatesChange}
          listTypeFacturable={listTypeFacturable}
          facturado={facturado}
          handleFacturadoSelect={handleFacturadoSelect}
          listFacturado={listFacturado}
        />
      ),
    },
    {
      label: TABS.label.billable,
      access: accessBillabeSection,
      content: (
        <BillableSectionComponent
          loading={loading}
          suplier={suplier}
          handleSupplierSelect={handleSupplierSelect}
          area={area}
          handleLoadBillable={handleLoadBillable}
          permissions={permissions}
          exportOptionsState={{ exportOptions, setExportOptions }}
          handleExportSelected={exportSelected}
          totalStatusSummary={totalStatusSummary}
        />
      ),
    },
    {
      label: TABS.label.list,
      access: accessListSection,
      content: (
        <ListSectionComponent
          loading={loading}
          suplierFilter={suplierFilter}
          handleSupplierFilterSelect={handleSupplierFilterSelect}
          area={area}
          metadataRegisters={metadataRegisters}
          registers={registers}
          page={page}
          setPage={setPage}
          listSize={LIST_SIZE}
          stateOT={stateOT}
          stateOTList={stateOTList}
          handleStateOTSelect={handleStateOTSelect}
          numberOT={numberOT}
          handleChangeNumberOT={(field, value) => setNumberOT(value)}
        />
      ),
    },
  ];

  return {
    ACCORDION_METADATA,
    loading,
    setArea,
    setExercise,
    disabledApplyBTN,
    setDisabledApplyBTN,
    disabledLoadExpensesTemplateBTN,
    setDisabledLoadExpensesTemplateBTN,
    handleApplyBTN,
    downloadTemplateCargarGastosComprometidos,
    setEngagedFormData,
    setCargarArchivoComprometidoRevisado,
    businessSelected,
    businessUnitList,
    handleChangeListInput,
    areaSelected,
    areaList,
    exerciseList,
    exerciseSelected,
    loadListSection,
  };
};

export default useIONEngagedPage;
