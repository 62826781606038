import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { IONEngagedPage } from "../pages/IONComprometidos";
import { Movimientos } from "../pages/Movimientos";
import { Prefacturas } from "../pages/Prefacturas";
import { DetallePrefactura } from "../pages/DetallePrefactura";
import { Autorizaciones } from "../pages/Autorizaciones";
import { Areas } from "../pages/Areas";
import { AreasIon } from "../pages/AreasIon";
import { Proveedores } from "../pages/Proveedores";
import { DetalleProveedor } from "../pages/DetalleProveedor";
import { Partidas } from "../pages/Partidas";
import { DetallePartida } from "../pages/DetallePartida";
import { Usuarios } from "../pages/Usuarios";
import { DetalleUsuario } from "../pages/DetalleUsuario";
import { Roles } from "../pages/Roles";
import { DetalleRol } from "../pages/DetalleRol";
import { NotFound } from "../pages/NotFound";
import { NoAccess } from "../pages/NoAccess";
import { Auditoria } from "../pages/Auditoria";
import { ProtectedRoute } from "./protected";

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/gastos" />
      </Route>

      <Route exact path="/ion-comprometidos">
        <ProtectedRoute permit="ION" redirectPath="/noAccess">
          <IONEngagedPage />
        </ProtectedRoute>
      </Route>

      <Route exact path="/gastos">
        <ProtectedRoute permit="Movimientos" redirectPath="/noAccess">
          <Movimientos />
        </ProtectedRoute>
      </Route>

      <Route exact path="/prefacturas">
        <ProtectedRoute permit="Prefacturas" redirectPath="/noAccess">
          <Prefacturas />
        </ProtectedRoute>
      </Route>
      <Route exact path="/prefacturas/:idPrefactura">
        <ProtectedRoute permit="Prefacturas" redirectPath="/noAccess">
          <DetallePrefactura />
        </ProtectedRoute>
      </Route>
      <Route exact path="/autorizaciones">
        <ProtectedRoute
          permit="Autorizacion_Prefacturas"
          redirectPath="/noAccess"
        >
          <Autorizaciones />
        </ProtectedRoute>
      </Route>
      <Route exact path="/auditoria/:modulo/:idEntidad">
        <Auditoria />
      </Route>
      <Route exact path="/areas">
        <ProtectedRoute permit="Areas" redirectPath="/noAccess">
          <Areas />
        </ProtectedRoute>
      </Route>
      <Route exact path="/areas_ion">
        <ProtectedRoute permit="IONAreas" redirectPath="/noAccess">
          <AreasIon />
        </ProtectedRoute>
      </Route>
      <Route exact path="/proveedores">
        <ProtectedRoute permit="Proveedores" redirectPath="/noAccess">
          <Proveedores />
        </ProtectedRoute>
      </Route>
      <Route exact path="/proveedores/:idProveedor">
        <ProtectedRoute permit="Proveedores" redirectPath="/noAccess">
          <DetalleProveedor />
        </ProtectedRoute>
      </Route>
      <Route exact path="/partidas">
        <ProtectedRoute permit="IONPartidas" redirectPath="/noAccess">
          <Partidas />
        </ProtectedRoute>
      </Route>
      <Route exact path="/partidas/:idPartida">
        <ProtectedRoute permit="IONPartidas" redirectPath="/noAccess">
          <DetallePartida />
        </ProtectedRoute>
      </Route>
      <Route exact path="/usuarios">
        <ProtectedRoute permit="Usuarios" redirectPath="/noAccess">
          <Usuarios />
        </ProtectedRoute>
      </Route>
      <Route exact path="/usuarios/:idUsuario">
        <ProtectedRoute permit="Usuarios" redirectPath="/noAccess">
          <DetalleUsuario />
        </ProtectedRoute>
      </Route>
      <Route exact path="/roles">
        <Roles />
      </Route>
      <Route exact path="/roles/:idRol">
        <ProtectedRoute permit="Roles" redirectPath="/noAccess">
          <DetalleRol />
        </ProtectedRoute>
      </Route>
      <Route exact path="/noAccess">
        <NoAccess />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};
