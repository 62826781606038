import React, { useState, useEffect, useCallback } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { Button } from "../../components/Button";
import { FieldText } from "../../components/FieldText";
import { FieldDate } from "../../components/FieldDate";
import { FieldDatetime } from "../../components/FieldDatetime";
import { FieldList } from "../../components/FieldList";
import { FieldCheckbox } from "../../components/FieldCheckbox";
import { usePermissions } from "../../context";

export const DetallePartida = () => {
  const { permissions } = usePermissions();
  const { idPartida } = useParams();
  const [loading, setLoading] = useState(false);
  const [metadataPartida, setMetadataPartida] = useState([]);
  const [listas, setListas] = useState({});
  const [partida, setPartida] = useState({});
  const history = useHistory();
  const isCreation = idPartida === "create";
  const LISTA_UNIDAD_NEGOCIO = "UnidadesNegocio";
  const LISTA_AREA = "AreaComprometidos";

  useEffect(() => {
    API.definicionPagina({ Modulo: "IONPartidas", TipoDefinicion: "detalle" })
      .then((res) => setMetadataPartida(res))
      .catch((error) => API.DEVELOP && console.log(error));
  }, []);

  useEffect(() => {
    API.obtenerListas("IONPartidas")
      .then((res) => setListas((prevListas) => ({ ...prevListas, ...res })))
      .catch((error) => API.DEVELOP && console.log(error));
  }, []);

  const handleChangeUnidadNegocio = useCallback((value) => {
    if (!value) return;

    const data = {
      Lista: LISTA_AREA,
      ListaRelacional: {
        NombreLista: LISTA_UNIDAD_NEGOCIO,
        IdEntidadRelacional: value,
      },
    };

    setLoading(true);
    API.obtenerLista(data)
      .then((res) =>
        setListas((prevListas) => ({ ...prevListas, [LISTA_AREA]: res }))
      )
      .catch((error) => {
        toast.error("Error al obtener las Áreas");
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!isCreation) {
      setLoading(true);
      API.obtenerPartida(idPartida)
        .then((res) => {
          setPartida(res);
          handleChangeUnidadNegocio(res[LISTA_UNIDAD_NEGOCIO]?.Valor);
        })
        .catch((error) => {
          if (error.code !== 401) toast.error("Error al obtener Partida");
          API.DEVELOP && console.log(error);
        })
        .finally(() => setLoading(false));
    }
  }, [isCreation, idPartida, handleChangeUnidadNegocio]);

  const handleChange = (key, value) => {
    const newPartida = { ...partida };
    newPartida[key] = value;
    if (key === LISTA_UNIDAD_NEGOCIO) {
      newPartida[LISTA_AREA] = "";
      handleChangeUnidadNegocio(value.Valor);
    }
    setPartida(newPartida);
  };

  const handleChangeCheckbox = (modulo, propiedad, value) => {
    handleChange(propiedad, value);
  };

  const guardarPartida = () => {
    const data = { ...partida };
    if (isCreation) data.Id = "00000000-0000-0000-0000-000000000000";
    if (data.UnidadesNegocio) data.UnidadesNegocio = data.UnidadesNegocio.Valor;
    if (data.AreaComprometidos)
      data.AreaComprometidos = data.AreaComprometidos.Valor;
    data.Anulada = data.Anulada ? 1 : 0;

    setLoading(true);
    API.guardarPartida(data)
      .then((res) => {
        if (res?.Estado === "OK") {
          toast.success(`Partida ${isCreation ? "Creada" : "Guardada"}`);
          if (isCreation) history.push("/partidas");
        } else {
          toast.error(
            res?.Mensaje ||
              `Error al ${isCreation ? "crear" : "guardar"} Partida`
          );
        }
      })
      .catch((error) => {
        toast.error(
          `Error al ${isCreation ? "crear" : "guardar"} Partida\n${
            error.msg?.Message
          }`
        );
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <S.DetallePartida>
      <Loading hidden={!loading} />
      <S.Subtitle>
        Detalle de Partida - {partida.nombrePartida}
        <div>
          <Button
            label={isCreation ? "Crear Partida" : "Guardar"}
            onClick={guardarPartida}
            disabled={!permissions?.IONPartidas?.Editar}
          />
        </div>
      </S.Subtitle>
      <S.FieldsContainer>
        {metadataPartida.map((field) => {
          const idField = field.id;
          const nameField = field.nombreCampo;
          const type = field.tipo;
          const label = field.etiqueta;
          const value = partida[field.nombreCampo] || "";
          const editable = field.editable;
          const required = field.requerido;

          switch (type) {
            case "text":
              return (
                <FieldText
                  key={idField}
                  id={nameField}
                  label={label}
                  value={value || ""}
                  handleChange={handleChange}
                  readOnly={!editable}
                  required={required}
                  styles={"filter"}
                />
              );

            case "date":
              return (
                <FieldDate
                  key={idField}
                  id={nameField}
                  label={label}
                  value={value.split("T")[0] || ""}
                  handleChange={handleChange}
                  readOnly={!editable}
                  required={required}
                  styles={"filter"}
                />
              );

            case "datetime":
              return (
                <FieldDatetime
                  key={idField}
                  id={nameField}
                  label={label}
                  value={value || "T"}
                  handleChange={handleChange}
                  readOnly={!editable}
                  required={required}
                  styles={"filter"}
                />
              );

            case "bool":
              return (
                <FieldCheckbox
                  key={idField}
                  id={nameField}
                  propiedad={nameField}
                  label={label}
                  value={partida[field.nombreCampo] || false}
                  handleChange={handleChangeCheckbox}
                  readOnly={!editable}
                  centered={false}
                />
              );

            case "enum":
              const currentOption = {
                value: partida[nameField]?.Valor || "",
                label: partida[nameField]?.Etiqueta || "",
              };
              const options = listas[nameField]?.Valores.map((option) => ({
                value: option.Valor,
                label: option.Etiqueta,
              }));
              return (
                <FieldList
                  key={idField}
                  id={nameField}
                  type={type}
                  label={label}
                  currentOption={currentOption}
                  options={options}
                  handleChange={handleChange}
                  readOnly={!editable}
                  required={required}
                  isDisabled={
                    nameField === LISTA_AREA &&
                    !partida[LISTA_UNIDAD_NEGOCIO]?.Valor
                  }
                />
              );

            default:
              return (
                <FieldText
                  key={idField}
                  id={idField}
                  label={label}
                  value={value.toString()}
                  readOnly={true}
                  required={false}
                />
              );
          }
        })}
      </S.FieldsContainer>
    </S.DetallePartida>
  );
};
