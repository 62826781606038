import React, { useEffect, useState, useRef, useCallback } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { FieldText } from "../../components/FieldText";
import { Button } from "../../components/Button";
import { RegistroPartida } from "../../components/RegistroPartida";
import { PARTIDAS_POR_PAGINA, DELAY_TEXT_FILTER } from "../../constants";
import { usePermissions } from "../../context";

export const Partidas = () => {
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [partidas, setPartidas] = useState([]);
  const [metadataTable, setMetadataTable] = useState([]);
  const [metadataFilters, setMetadataFilters] = useState([]);
  const [filters, setFilters] = useState({});
  const [nextPage, setNextPage] = useState(0);
  const [triggerGetPartidas, setTriggerGetPartidas] = useState(true);
  const delayTimer = useRef();

  useEffect(() => {
    API.definicionPagina({ Modulo: "IONPartidas", TipoDefinicion: "grilla" })
      .then((res) => setMetadataTable(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerFiltrosBusqueda("IONPartidas")
      .then((res) => setMetadataFilters(res))
      .catch((error) => API.DEVELOP && console.log(error));
  }, []);

  const getPartidas = useCallback(
    (page = 1) => {
      setLoading(true);
      const data = {};
      data.Paginacion = getPagination(page);
      if (Object.keys(filters).length > 0)
        Object.entries(filters).forEach(
          ([filterName, value]) => (data[filterName] = value)
        );

      API.obtenerPartidas(data)
        .then((res) => {
          if (res) {
            if (page === 1) setPartidas(res);
            else setPartidas([...partidas, ...res]);

            if (res.length < PARTIDAS_POR_PAGINA) setNextPage(0);
            else setNextPage(page + 1);
          }
        })
        .catch((error) => {
          if (error.code !== 401)
            toast.error("Error al obtener Partidas\n" + error.msg?.Message);
          API.DEVELOP && console.log(error);
        })
        .finally(() => setLoading(false));
    },
    [filters, partidas]
  );

  useEffect(() => {
    triggerGetPartidas && getPartidas();
    setTriggerGetPartidas(false);
  }, [getPartidas, triggerGetPartidas, setTriggerGetPartidas]);

  const getPagination = (page = 1) => ({
    Pagina: page,
    CantidadRegistros: PARTIDAS_POR_PAGINA,
  });

  const handleChangeFilter = (id, value) => {
    const newFilters = { ...filters };
    if (value === "") delete newFilters[id];
    else newFilters[id] = value;

    setFilters(newFilters);
    API.defaultSource.cancel();
    clearTimeout(delayTimer.current);
    delayTimer.current = setTimeout(() => {
      setTriggerGetPartidas(true);
    }, DELAY_TEXT_FILTER);
  };

  return (
    <S.Partidas>
      <Loading hidden={!loading} />
      <S.Filters count={metadataFilters.length}>
        {metadataFilters.map((filter) => {
          switch (filter.Tipo) {
            case "text":
              return (
                <FieldText
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro}
                  label={filter.Etiqueta}
                  value={filters[filter.NombreFiltro] || ""}
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            default:
              return <div>{filter.Etiqueta} (tipo desconocido)</div>;
          }
        })}
      </S.Filters>

      <S.Buttons>
        <Link to={`/partidas/create`}>
          <Button label={"Crear Nueva Partida"} />
        </Link>
      </S.Buttons>

      <S.Table>
        <S.ColGroup>
          <S.Col />
          {metadataTable.map((campo) => (
            <S.Col key={campo.id} />
          ))}
          <S.Col />
        </S.ColGroup>
        <S.Tr>
          <S.Th />
          {metadataTable.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              <S.HeaderColumn>
                <span>{campo.etiqueta}</span>
              </S.HeaderColumn>
            </S.Th>
          ))}
          <S.Th />
        </S.Tr>
        {partidas.map((partida) => (
          <RegistroPartida
            key={partida.Id}
            metadataTable={metadataTable}
            partida={partida}
            access={permissions}
            update={() => setTriggerGetPartidas(true)}
            setLoading={setLoading}
            toast={toast}
          />
        ))}
      </S.Table>
      <S.Pagination>
        <button disabled={nextPage === 0} onClick={() => getPartidas(nextPage)}>
          {nextPage === 0 ? "No hay más partidas" : "Mostrar más partidas..."}
        </button>
      </S.Pagination>
    </S.Partidas>
  );
};
