import styled, { css } from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  .hidden {
    display: none;
  }
`;

export const Title = styled.div`
  color: ${COLORES.GRIS_OSCURO};
  margin-bottom: 0.2rem;
`;

export const SelectContainer = styled.div`
  display: block;

  ${(props) =>
    props.hiddenIndicators &&
    css`
      div[class$="IndicatorsContainer"] {
        display: none;
      }
    `}
`;

export const Text = styled.div`
  display: block;
  padding: 7px 11px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
