import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { FieldText } from "../FieldText";
import { FieldNumber } from "../FieldNumber";
import { FieldDate } from "../FieldDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";

export const RegistroMovimiento = ({
  metadataTable,
  movimiento: initialValues,
  type,
  handleSelectionChange,
  selected,
  disabled,
  setLoading,
  access,
}) => {
  const [editing, setEditing] = useState(false);
  const [movimiento, setMovimiento] = useState(initialValues);
  const form = useRef();

  const handleChange = (key, value) => {
    const newValues = { ...movimiento };
    newValues[key] = value;
    if (key === "Area") newValues["Proveedor"] = { Valor: "" };
    setMovimiento(newValues);
  };

  const cancelEdit = () => {
    setMovimiento(initialValues);
    setEditing(false);
  };

  const saveMovimiento = (event) => {
    event.preventDefault();
    setEditing(false);

    const formData = new FormData();
    for (const [key, value] of new FormData(form.current)) {
      const element = form.current.elements[key];
      if (!element.readOnly) {
        formData.append(key, value);
      }
    }
    const objectFormData = Object.fromEntries(formData);
    objectFormData.id = movimiento.id;
    objectFormData.fechaModificacion = movimiento.fechaModificacion;

    setLoading(true);
    API.guardarMovimiento(objectFormData)
      .then((res) => {
        if (res.Estado === "OK") {
          handleChange("fechaModificacion", res.UltimaModificacion);
          toast.success("Gasto Guardado");
        } else if (res.Estado === "NeedReload") {
          toast.warning(
            "Se encontró una versión más reciente del gasto. Se requiere recargar"
          );
        } else {
          toast.info(res.Mensaje);
        }
      })
      .catch((error) => {
        toast.error("No se pudo guardar el gasto");
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.Tr
      ref={form}
      onSubmit={saveMovimiento}
      highlighted={editing || selected}
      deleted={movimiento.eliminado}
    >
      <S.Checkbox>
        <S.InputCheckbox
          id="selected"
          type="checkbox"
          checked={selected}
          onChange={(e) =>
            handleSelectionChange(movimiento.id, e.target.checked)
          }
          disabled={disabled}
        />
      </S.Checkbox>
      {metadataTable.map((field) => {
        const type = field.tipo;
        const idField = field.nombreCampo;
        const value = movimiento[field.nombreCampo] || "";
        const editable = field.editable;
        const required = field.requerido;

        switch (type) {
          case "text":
          default:
            return (
              <S.Td key={idField} title={value}>
                <FieldText
                  id={idField}
                  value={value}
                  handleChange={handleChange}
                  readOnly={!editable || !editing}
                  required={required}
                />
              </S.Td>
            );

          case "int":
          case "float":
            return (
              <S.Td key={idField} title={value}>
                <FieldNumber
                  id={idField}
                  value={value || 0}
                  empty={value === "" || value === null}
                  handleChange={handleChange}
                  type={type}
                  readOnly={!editable || !editing}
                  required={required}
                />
              </S.Td>
            );

          case "date":
            return (
              <S.Td key={idField} title={value}>
                <FieldDate
                  id={idField}
                  value={value.split("T")[0] || ""}
                  handleChange={handleChange}
                  readOnly={!editable || !editing}
                  required={required}
                />
              </S.Td>
            );
        }
      })}
      {type === "list" && (
        <S.Td>
          <S.Buttons>
            {editing ? (
              <>
                <button
                  key="save"
                  className="action save"
                  type="submit"
                  title="Guardar"
                >
                  ✔
                </button>
                <button
                  key="cancel"
                  className="action cancel"
                  type="button"
                  title="Cancelar"
                  onClick={() => cancelEdit()}
                >
                  ✘
                </button>
              </>
            ) : (
              <>
                <Link to={`/auditoria/Movimientos/${movimiento.id}`}>
                  <button
                    key="verAuditoria"
                    className="action audit"
                    title="Ver auditoría"
                    disabled={!access?.Auditoria?.Acceso}
                  >
                    <FontAwesomeIcon icon={faSearchPlus} size="lg" />
                  </button>
                </Link>
                <button
                  key="edit"
                  className="edit"
                  type="button"
                  onClick={() => setEditing(!editing)}
                  disabled={!access.Movimientos?.Editar || movimiento.eliminado}
                >
                  <FontAwesomeIcon icon={faEdit} size="lg" />
                </button>
              </>
            )}
          </S.Buttons>
        </S.Td>
      )}
    </S.Tr>
  );
};

RegistroMovimiento.propTypes = {
  metadataTable: PropTypes.array,
  movimiento: PropTypes.object,
  listas: PropTypes.object,
  type: PropTypes.string,
  handleSelectionChange: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  setLoading: PropTypes.func,
  access: PropTypes.object,
};
