import React, { useState, useEffect, useCallback } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { Button } from "../../components/Button";
import { RegistroArea } from "../../components/RegistroArea";
import { FieldText } from "../../components/FieldText";
import { FieldDate } from "../../components/FieldDate";
import { FieldDatetime } from "../../components/FieldDatetime";
import { FieldCheckbox } from "../../components/FieldCheckbox";
import { AREAS_POR_PAGINA } from "../../constants";
import { usePermissions } from "../../context";
import { FieldList } from "../../components/FieldList";

export const DetalleProveedor = () => {
  const { permissions } = usePermissions();
  const { idProveedor } = useParams();
  const [loading, setLoading] = useState(true);
  const [metadataTableProveedores, setMetadataTableProveedores] = useState([]);
  const [metadataTableAreas, setMetadataTableAreas] = useState([]);
  const [metadataTableAreasVinculadas, setMetadataTableAreasVinculadas] =
    useState([]);
  const [listas, setListas] = useState({});
  const [listasAreas, setListasAreas] = useState({});
  const [selectedIdsVinculados, setSelectedIdsVinculados] = useState([]);
  const [allSelectedVinculados, setAllSelectedVinculados] = useState(false);
  const [selectedIdsNoVinculados, setSelectedIdsNoVinculados] = useState([]);
  const [allSelectedNoVinculados, setAllSelectedNoVinculados] = useState(false);
  const [areasNoVinculadas, setAreasNoVinculadas] = useState([]);
  const [showUnlinkedAreas, setShowUnlinkedAreas] = useState(false);
  const [triggerGetProveedor, setTriggerGetProveedor] = useState(true);
  const [nextPageNoVinculados, setNextPageNoVinculados] = useState(0);
  const [filtersAreas, setFiltersAreas] = useState([]);
  const [proveedor, setProveedor] = useState({});
  const [areasVinculadas, setAreasVinculadas] = useState([]);
  const history = useHistory();

  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({ Modulo: "Proveedores", TipoDefinicion: "detalle" })
      .then((res) => isMounted && setMetadataTableProveedores(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.definicionPagina({ Modulo: "Areas", TipoDefinicion: "grilla" })
      .then((res) => isMounted && setMetadataTableAreas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.definicionPagina({
      Modulo: "AreasVinculadas",
      TipoDefinicion: "grilla",
    })
      .then((res) => isMounted && setMetadataTableAreasVinculadas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerFiltrosBusqueda("Areas")
      .then((res) => isMounted && setFiltersAreas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerListas("Proveedores")
      .then((res) => isMounted && setListas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerListas("Areas")
      .then((res) => isMounted && setListasAreas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, [idProveedor]);

  const obtenerProveedor = useCallback(() => {
    API.obtenerProveedor(idProveedor)
      .then((res) => {
        setProveedor(res.Proveedor);
        setAreasVinculadas(res?.AreasVinculadas);
      })
      .catch((error) => {
        if (error.code !== 401) toast.error("Error al obtener Proveedor");
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

    setTriggerGetProveedor(false);
  }, [idProveedor]);

  useEffect(() => {
    let isMounted = true;
    triggerGetProveedor && isMounted && obtenerProveedor();
    return () => (isMounted = false);
  }, [obtenerProveedor, triggerGetProveedor]);

  const handleChange = (key, value) => {
    const newProveedor = { ...proveedor };
    newProveedor[key] = value;
    setProveedor(newProveedor);
  };

  const handleChangeBoolean = (modulo, key, value) => {
    const newProveedor = { ...proveedor };
    newProveedor[key] = value;
    setProveedor(newProveedor);
  };

  const guardarProveedor = () => {
    setLoading(true);
    const data = { ...proveedor };

    API.guardarProveedor(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Proveedor Guardado");
          if (idProveedor === "create") history.push("/proveedores");
        } else if (res.Estado === "KO") {
          toast.error("Error al guardar Proveedor: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al guardar Proveedor: " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al guardar Proveedor: " + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mostrarAreasNoVinculados = () => {
    if (!showUnlinkedAreas && areasNoVinculadas.length === 0) {
      setLoading(true);
      obtenerAreasNoVinculadas();
    }
    setShowUnlinkedAreas(!showUnlinkedAreas);
  };

  const obtenerAreasNoVinculadas = (page = 1) => {
    const data = {};
    data.Paginacion = {
      Pagina: page,
      CantidadRegistros: AREAS_POR_PAGINA,
    };
    data.Filtros = filtersAreas.map((filter) => {
      return {
        IdFiltro: filter.IdFiltro,
        ValorFiltro: proveedor.id,
      };
    });
    API.obtenerAreas(data)
      .then((res) => {
        if (page === 1) setAreasNoVinculadas(res);
        else setAreasNoVinculadas([...areasNoVinculadas, ...res]);

        if (res.length < AREAS_POR_PAGINA) setNextPageNoVinculados(0);
        else setNextPageNoVinculados(page + 1);
      })
      .catch((error) => {
        toast.error("Error al obtener Áreas");
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleSelectionChangeVinculados = (id, checked) => {
    let newSelectedIds = [...selectedIdsVinculados];
    if (checked) {
      newSelectedIds.push(id);
    } else {
      const index = newSelectedIds.indexOf(id);
      newSelectedIds.splice(index, 1);
    }
    setSelectedIdsVinculados(newSelectedIds);
  };

  const handleSelectionChangeNoVinculados = (id, checked) => {
    let newSelectedIds = [...selectedIdsNoVinculados];
    if (checked) {
      newSelectedIds.push(id);
    } else {
      const index = newSelectedIds.indexOf(id);
      newSelectedIds.splice(index, 1);
    }
    setSelectedIdsNoVinculados(newSelectedIds);
  };

  const desvincularAreas = () => {
    setLoading(true);
    const data = {};
    data.IdProveedor = idProveedor;
    data.Areas = selectedIdsVinculados;

    API.desvincularProveedorArea(data)
      .then((res) => {
        if (res.Estado === "OK") {
          setTriggerGetProveedor(true);
          toast.success(
            selectedIdsVinculados.length === 1
              ? "Área Desvinculada"
              : "Áreas Desvinculadas"
          );
          obtenerAreasNoVinculadas();
          setSelectedIdsVinculados([]);
          setSelectedIdsNoVinculados([]);
          setAllSelectedVinculados(false);
          setAllSelectedNoVinculados(false);
        } else if (res.Estado === "KO") {
          toast.error("No se pudo Desvincular las áreas: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al Desvincular área(s): " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al Desvincular área(s)" + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const vincularAreas = () => {
    setLoading(true);
    const data = {};
    data.IdProveedor = idProveedor;
    data.Areas = selectedIdsNoVinculados;

    API.vincularProveedorArea(data)
      .then((res) => {
        if (res.Estado === "OK") {
          setTriggerGetProveedor(true);
          toast.success(
            selectedIdsNoVinculados.length === 1
              ? "Área Vinculada"
              : "Áreas Vinculadas"
          );
          obtenerAreasNoVinculadas();
          setSelectedIdsVinculados([]);
          setSelectedIdsNoVinculados([]);
          setAllSelectedVinculados(false);
          setAllSelectedNoVinculados(false);
        } else if (res.Estado === "KO") {
          toast.error("No se pudo vincular las áreas: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al vincular área(s): " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al vincular área(s)" + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const toggleAllVinculados = () => {
    if (allSelectedVinculados) {
      setSelectedIdsVinculados([]);
      setAllSelectedVinculados(false);
    } else {
      const allVinculados = [];
      areasVinculadas.forEach((area) => allVinculados.push(area.id));
      setSelectedIdsVinculados(allVinculados);
      setAllSelectedVinculados(true);
    }
  };

  const toggleAllNoVinculados = () => {
    if (allSelectedNoVinculados) {
      setSelectedIdsNoVinculados([]);
      setAllSelectedNoVinculados(false);
    } else {
      const allNoVinculados = [];
      areasNoVinculadas.forEach((area) => allNoVinculados.push(area.id));
      setSelectedIdsNoVinculados(allNoVinculados);
      setAllSelectedNoVinculados(true);
    }
  };

  const validarAreas = () => {
    setLoading(true);
    const data = {};
    data.IdProveedor = idProveedor;
    data.Areas = selectedIdsVinculados;

    API.validarProveedorArea(data)
      .then((res) => {
        if (res.Estado === "OK") {
          setTriggerGetProveedor(true);
          toast.success(
            selectedIdsVinculados.length === 1
              ? "Validador activado para este área"
              : "Validador activado para estas áreas"
          );
          obtenerProveedor();
          setSelectedIdsVinculados([]);
          setAllSelectedVinculados(false);
        } else if (res.Estado === "KO") {
          toast.error(
            "No se pudo activar el validador para las áreas: " + res.Mensaje
          );
          setLoading(false);
        } else {
          toast.info(
            "No se pudo activar el validador para las áreas: " + res.Mensaje
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(
          "No se pudo activar el validador para las áreas" + error.msg?.Message
        );
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const desvalidarAreas = () => {
    setLoading(true);
    const data = {};
    data.IdProveedor = idProveedor;
    data.Areas = selectedIdsVinculados;

    API.invalidarProveedorArea(data)
      .then((res) => {
        if (res.Estado === "OK") {
          setTriggerGetProveedor(true);
          toast.success(
            selectedIdsVinculados.length === 1
              ? "Validador desactivado para este área"
              : "Validador desactivado para estas áreas"
          );
          obtenerProveedor();
          setSelectedIdsVinculados([]);
          setAllSelectedVinculados(false);
        } else if (res.Estado === "KO") {
          toast.error(
            "No se pudo desactivar el validador para las áreas " + res.Mensaje
          );
          setLoading(false);
        } else {
          toast.info(
            "No se pudo desactivar el validador para las áreas " + res.Mensaje
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(
          "No se pudo desactivar el validador para las áreas" +
            error.msg?.Message
        );
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.Subtitle id="first">
        Detalle de Proveedor - Código {proveedor.codigo}
        <div>
          <Button
            label="Guardar"
            onClick={guardarProveedor}
            disabled={!permissions?.Proveedores?.Editar}
          />
        </div>
      </S.Subtitle>
      <S.FieldsContainer>
        {metadataTableProveedores.map((field) => {
          const idField = field.id;
          const nameField = field.nombreCampo;
          const type = field.tipo;
          const label = field.etiqueta;
          const value = proveedor[field.nombreCampo] || "";
          const editable = field.editable;
          const required = field.requerido;
          switch (type) {
            case "text":
              return (
                <FieldText
                  key={idField}
                  id={nameField}
                  label={label}
                  value={value || ""}
                  handleChange={handleChange}
                  readOnly={!editable}
                  required={required}
                />
              );

            case "date":
              return (
                <FieldDate
                  key={idField}
                  id={nameField}
                  label={label}
                  value={value.split("T")[0] || ""}
                  handleChange={handleChange}
                  readOnly={!editable}
                  required={required}
                />
              );

            case "datetime":
              return (
                <FieldDatetime
                  key={idField}
                  id={nameField}
                  label={label}
                  value={value || "T"}
                  handleChange={handleChange}
                  readOnly={!editable}
                  required={required}
                />
              );

            case "bool":
              return (
                <FieldCheckbox
                  key={idField}
                  id={nameField}
                  propiedad={nameField}
                  label={label}
                  value={proveedor[field.nombreCampo] || false}
                  handleChange={handleChangeBoolean}
                  readOnly={!editable}
                  centered={false}
                />
              );

            case "enum":
              const currentOption = {
                value: proveedor[nameField]?.Valor || "",
                label: proveedor[nameField]?.Etiqueta || "",
              };
              const options = listas[nameField]?.Valores.map((option) => ({
                value: option.Valor,
                label: option.Etiqueta,
              }));
              return (
                <FieldList
                  key={idField}
                  id={nameField}
                  type={type}
                  label={label}
                  currentOption={currentOption}
                  options={options}
                  handleChange={handleChange}
                  canBeEmpty={!required}
                  readOnly={!editable}
                  required={required}
                />
              );

            default:
              return (
                <FieldText
                  key={idField}
                  id={idField}
                  label={label}
                  value={value.toString()}
                  readOnly={true}
                  required={false}
                />
              );
          }
        })}
      </S.FieldsContainer>
      {idProveedor !== "create" && (
        <S.Subtitle>
          <p>Áreas Vinculadas</p>
          <S.ButtonsBar>
            <button
              onClick={validarAreas}
              disabled={
                !permissions?.Proveedores?.Editar ||
                selectedIdsVinculados.length === 0
              }
            >
              Activar validador área
            </button>
            <button
              id="desvalidarArea"
              onClick={desvalidarAreas}
              disabled={
                !permissions?.Proveedores?.Editar ||
                selectedIdsVinculados.length === 0
              }
            >
              Desactivar validador área
            </button>
            <button
              onClick={desvincularAreas}
              disabled={
                !permissions?.Proveedores?.Editar ||
                selectedIdsVinculados.length === 0
              }
            >
              Desvincular seleccionados
              {selectedIdsVinculados.length
                ? ` (${selectedIdsVinculados.length})`
                : ""}
            </button>
          </S.ButtonsBar>
        </S.Subtitle>
      )}
      {idProveedor !== "create" && (
        <S.Table>
          <S.ColGroupAreas>
            <S.Col />
            {metadataTableAreasVinculadas.map((campo) => (
              <S.Col key={campo.id} />
            ))}
          </S.ColGroupAreas>
          <S.Tr>
            <S.Th>
              <S.Checkbox>
                <S.InputCheckbox
                  type="checkbox"
                  checked={allSelectedVinculados}
                  onChange={toggleAllVinculados}
                  disabled={false}
                />
              </S.Checkbox>
            </S.Th>
            {metadataTableAreasVinculadas.map((campo) => (
              <S.Th key={campo.id} title={campo.etiqueta}>
                <span>{campo.etiqueta}</span>
              </S.Th>
            ))}
          </S.Tr>
          {areasVinculadas?.map((area) => (
            <RegistroArea
              key={area.id}
              metadataTable={metadataTableAreasVinculadas}
              area={area}
              listas={listasAreas}
              type="detalleProveedor"
              handleSelectionChange={handleSelectionChangeVinculados}
              selected={selectedIdsVinculados.includes(area.id)}
              disabled={false}
              setLoading={setLoading}
            />
          ))}
        </S.Table>
      )}
      {idProveedor !== "create" && (
        <S.DropDown onClick={mostrarAreasNoVinculados}>
          Vincular más áreas
          <span>{showUnlinkedAreas ? " ▼" : " ▶"}</span>
        </S.DropDown>
      )}
      {showUnlinkedAreas && (
        <>
          <S.Subtitle>
            Áreas No Vinculadas
            <S.ButtonsBar>
              <button
                onClick={vincularAreas}
                disabled={
                  !permissions?.Proveedores?.Editar ||
                  selectedIdsNoVinculados.length === 0
                }
              >
                Vincular seleccionados
                {selectedIdsNoVinculados.length
                  ? ` (${selectedIdsNoVinculados.length})`
                  : ""}
              </button>
            </S.ButtonsBar>
          </S.Subtitle>
          {areasNoVinculadas.length === 0 && !loading ? (
            "No hay más áreas"
          ) : (
            <>
              <S.Table>
                <S.ColGroupAreas>
                  <S.Col />
                  {metadataTableAreas.map((campo) => (
                    <S.Col key={campo.id} />
                  ))}
                </S.ColGroupAreas>
                <S.Tr>
                  <S.Th>
                    <S.Checkbox>
                      <S.InputCheckbox
                        type="checkbox"
                        checked={allSelectedNoVinculados}
                        onChange={toggleAllNoVinculados}
                        disabled={false}
                      />
                    </S.Checkbox>
                  </S.Th>
                  {metadataTableAreas.map((campo) => (
                    <S.Th key={campo.id} title={campo.etiqueta}>
                      <span>{campo.etiqueta}</span>
                    </S.Th>
                  ))}
                </S.Tr>
                {areasNoVinculadas?.map((area) => (
                  <RegistroArea
                    key={area.id}
                    metadataTable={metadataTableAreas}
                    area={area}
                    listas={listasAreas}
                    type="detalleProveedor"
                    handleSelectionChange={handleSelectionChangeNoVinculados}
                    selected={selectedIdsNoVinculados.includes(area.id)}
                    disabled={false}
                    setLoading={setLoading}
                  />
                ))}
              </S.Table>
              <S.Pagination>
                <button
                  disabled={nextPageNoVinculados === 0}
                  onClick={() => obtenerAreasNoVinculadas(nextPageNoVinculados)}
                >
                  {nextPageNoVinculados === 0
                    ? "No hay más áreas"
                    : "Mostrar más áreas no vinculadas..."}
                </button>
              </S.Pagination>
            </>
          )}
        </>
      )}
    </S.Container>
  );
};
