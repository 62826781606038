import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { Link } from "react-router-dom";
import { FieldText } from "../FieldText";
import { FieldNumber } from "../FieldNumber";
import { FieldDate } from "../FieldDate";
import { FieldCheckbox } from "../FieldCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "../Dialog";

export const RegistroProveedor = ({
  metadataTable,
  proveedor: initialValues,
  setLoading,
  toast,
  update,
  type,
  access,
}) => {
  const [dialogConfirmarVisible, setDialogConfirmarVisible] = useState(false);
  const [proveedor, setProveedor] = useState(initialValues);
  useEffect(() => setProveedor(initialValues), [initialValues]);

  const handleChange = (key, value) => {
    const newValues = { ...proveedor };
    newValues[key] = value;
    setProveedor(newValues);
  };

  const eliminarProveedor = () => {
    setLoading(true);

    const data = { IdProveedor: proveedor.id };
    API.eliminarProveedor(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Proveedor Eliminado");
          update();
        } else if (res.Estado === "KO") {
          toast.error("No se pudo eliminar el proveedor: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al eliminar usuario: " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al eliminar usuario: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  return (
    <>
      <S.Tr deleted={proveedor.eliminado}>
        {type !== "detalleProveedor" && (
          <S.Td>
            <Link to={`/proveedores/${proveedor.id}`}>
              <button title="Ver detalle" className="edit">
                👁
              </button>
            </Link>
          </S.Td>
        )}
        {metadataTable.map((field) => {
          const fieldType = field.tipo;
          const idField = field.nombreCampo;
          const value = proveedor[field.nombreCampo];
          const editable = false;
          const required = field.requerido;

          switch (fieldType) {
            case "text":
              return (
                <S.Td key={idField} title={value}>
                  <FieldText
                    id={idField}
                    value={value || ""}
                    handleChange={handleChange}
                    readOnly={!editable}
                    required={required}
                  />
                </S.Td>
              );

            case "int":
            case "float":
              return (
                <S.Td key={idField} title={value}>
                  <FieldNumber
                    id={idField}
                    value={value || 0}
                    empty={value === "" || value === null}
                    handleChange={handleChange}
                    type={fieldType}
                    readOnly={!editable}
                    required={required}
                  />
                </S.Td>
              );

            case "date":
              return (
                <S.Td key={idField} title={value}>
                  <FieldDate
                    id={idField}
                    value={value?.split("T")[0] || ""}
                    handleChange={handleChange}
                    readOnly={!editable}
                    required={required}
                  />
                </S.Td>
              );

            case "bool":
              return (
                <S.Td key={idField}>
                  <FieldCheckbox
                    id={idField}
                    value={value || false}
                    handleChange={handleChange}
                    readOnly={!editable}
                    required={required}
                    centered={true}
                  />
                </S.Td>
              );

            case "enum":
              return (
                <S.Td key={idField} title={value}>
                  <FieldText
                    id={idField}
                    value={value.Etiqueta}
                    readOnly={true}
                    required={required}
                  />
                </S.Td>
              );

            default:
              return (
                <S.Td key={idField}>
                  {typeof value === "object" ? value.Etiqueta : value}
                </S.Td>
              );
          }
        })}
        <S.Td>
          <S.Buttons>
            <button
              key="eliminarProveedor"
              className="action delete"
              type="button"
              title="Eliminar Proveedor"
              onClick={() => setDialogConfirmarVisible(true)}
              disabled={!access?.Usuarios?.Eliminar}
            >
              <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            </button>
          </S.Buttons>
          {dialogConfirmarVisible && (
            <Dialog
              type="confirm"
              message="¿Quieres eliminar el proveedor?"
              labelConfirm="Eliminar"
              confirm={eliminarProveedor}
              cancel={() => setDialogConfirmarVisible(false)}
            />
          )}
        </S.Td>
      </S.Tr>
    </>
  );
};

RegistroProveedor.propTypes = {
  metadataTable: PropTypes.array,
  proveedor: PropTypes.object,
  type: PropTypes.string,
};
