import { useEffect, useState } from "react";
import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const AccordionPanel = ({ label, content, access, openAction }) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => visible && openAction(), [visible, openAction]);

  return (
    <S.Accordion>
      <S.TopBar
        id={label}
        className={`${visible ? " active" : ""}${!access ? " disabled" : ""}${
          visible ? " opened" : ""
        }`}
        onClick={() => access && setVisible(!visible)}
        visible={visible}
      >
        <span>{label}</span>
        <FontAwesomeIcon icon={faCaretDown} size="lg" />
      </S.TopBar>

      <S.Content className={visible ? " opened" : ""} hidden={!visible}>
        {content}
      </S.Content>
    </S.Accordion>
  );
};
export default AccordionPanel;
