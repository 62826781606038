import styled, { keyframes } from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #0003;
`;

const entry = keyframes`
  0% {transform: scale(0);}
  90% {transform: scale(1.1);}
  100% {transform: scale(1);}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 5rem auto;
  border-radius: 3px;
  box-shadow: 5px 5px 5px #555;
  width: 30rem;
  text-align: center;
  animation: ${entry} 0.2s linear forwards;
  button {
    margin: 0 1rem;
    color: white;
  }
`;

export const TitleBar = styled.div`
  height: 1.5rem;
  border-radius: 3px 3px 0 0;
  background-color: ${COLORES.DIALOG_COMMENT};

  button {
    margin: 0;
    padding: 0 0.25rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: ${COLORES.NEGRO};
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`;

export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  height: 1.5rem;
  line-height: 1.5rem;
`;

export const Content = styled.div`
  font-size: 0.875rem;
  margin: 1.5rem;
  p {
    text-align: start;
  }
  p.confirm {
    text-align: center;
  }
  input {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const Input = styled.input`
  height: 1.5rem;
  border: thin solid ${COLORES.GRIS_CLARO};
  border-radius: 2px;
  margin-top: 0.25rem;
`;

export const Buttons = styled.div`
  margin-top: 1rem;

  button {
    font-size: 0.8rem;
    color: ${COLORES.BUTTON_TEXT_DEFAULT};
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    border: none;
    border-radius: 0.2rem;
    height: 1.75rem;
    padding: 0 1rem;

    :disabled {
      background-color: lightgrey;
    }
  }
`;
