import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { FieldList } from "../../components/FieldList";

export const DialogCustom = ({
  objects,
  message,
  confirm,
  labelConfirm,
  cancel,
}) => {
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [currentOption, setCurrentOption] = useState({});
  const [fileInfoFecha, setfileInfoFecha] = useState("");
  const [fileInfoImporte, setfileInfoImporte] = useState("");
  useEffect(() => {
    let index = 0;
    const newOptions = objects.map((element) => {
      const opt = {
        value: element.IdArchivo,
        label: element.NombreArchivo,
      };
      if (index === 0) {
        setfileInfoFecha(element.FechaCreacionString);
        setfileInfoImporte(element.ImporteTotalString);
        setCurrentOption(opt);
      }
      index++;
      return opt;
    });

    setOptions(newOptions);
  }, [objects]);

  const handleChange = (id, value) => {
    setValue(value.Valor);

    let opt = {};
    opt.value = value.Valor;
    opt.label = value.Etiqueta;
    setCurrentOption(opt);

    objects.forEach((element) => {
      if (element.IdArchivo === value.Valor) {
        setfileInfoFecha(element.FechaCreacionString);
        setfileInfoImporte(element.ImporteTotalString);
        return;
      }
    });
  };

  return (
    <S.Container onClick={cancel}>
      <S.Wrapper onClick={(event) => event.stopPropagation()}>
        <S.TitleBar>
          <S.Close onClick={cancel}>×</S.Close>
        </S.TitleBar>
        <S.Content>
          <h4>{message}</h4>

          <FieldList
            key="key"
            id="FileFilter"
            type="enum"
            label="Seleccione el archivo"
            labelConfirm={labelConfirm}
            currentOption={currentOption}
            options={options}
            handleChange={handleChange}
            canBeEmpty={false}
            readOnly={false}
            required={false}
          />
          <br />
          <div>
            <small>
              <strong>Fecha Creacion Archivo: </strong>
              {fileInfoFecha}
              <br />
              <strong>Importe Total Archivo: </strong>
              {fileInfoImporte}
            </small>
          </div>

          <S.Buttons>
            <button disabled={!value.length} onClick={() => confirm(value)}>
              {labelConfirm}
            </button>

            {cancel && <button onClick={cancel}>Cancelar</button>}
          </S.Buttons>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

DialogCustom.propTypes = {
  message: PropTypes.string.isRequired,
  labelConfirm: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  objects: PropTypes.array,
};
