import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { RegistroRol } from "../../components/RegistroRol";

export const Roles = () => {
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [metadataTable, setMetadataTable] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({ Modulo: "Roles", TipoDefinicion: "grilla" })
      .then((res) => isMounted && setMetadataTable(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;
    isMounted && getRoles();
    return () => (isMounted = false);
  }, [page]);

  const getRoles = () => {
    setLoading(true);
    const data = {};
    data.Paginacion = { Pagina: page, CantidadRegistros: 10 };

    API.obtenerRoles(data)
      .then((res) => setRoles(res))
      .catch((error) => {
        if (error.code !== 401)
          toast.error("Error al obtener Roles: " + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.Table>
        <S.ColGroup>
          {metadataTable.map((campo) => (
            <S.Col key={campo.id} />
          ))}
        </S.ColGroup>
        <S.Tr>
          {metadataTable.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              <S.HeaderColumn>
                <span>{campo.etiqueta}</span>
              </S.HeaderColumn>
            </S.Th>
          ))}
        </S.Tr>
        {roles.map((rol) => (
          <RegistroRol key={rol.Id} metadataTable={metadataTable} rol={rol} />
        ))}
      </S.Table>
      <S.PaginationComp>
        <button onClick={() => setPage(page - 1)} disabled={page === 1}>
          {"<"}
        </button>
        <span>{page}</span>
        <button onClick={() => setPage(page + 1)} disabled={roles.length < 10}>
          {">"}
        </button>
      </S.PaginationComp>
    </S.Container>
  );
};
