import styled, { css } from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  height: ${({ styles }) => (styles === "accordion" ? "auto" : "1.5rem")};
  margin-bottom: ${(props) => (props.type === "detail" ? "2rem" : 0)};

  .hidden {
    display: none;
  }
`;

export const Title = styled.div`
  color: ${COLORES.BLACK};
  margin-bottom: 0.2rem;
`;

export const InputContainer = styled.div`
  height: 100%;
`;

export const Input = styled.input`
  background-color: ${COLORES.BACKGROUND};
  border: none;
  outline: thin solid ${COLORES.LIGHT_BORDER};
  box-sizing: border-box;
  padding-left: 0.45rem;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.styles === "filter" &&
    css`
      outline: none;
      border: thin solid ${COLORES.LIGHT_BORDER};
      border-radius: 0.25rem;
      height: 1.8rem;
      width: 100%;
      padding-left: 0.6rem;
      :focus {
        border-color: ${COLORES.LIST_ACTIVE};
      }
    `}

  ${(props) =>
    props.styles === "accordion" &&
    css`
      outline: none;
      border: thin solid ${COLORES.LIGHT_BORDER};
      border-radius: 0.25rem;
      height: 1.8rem;
      width: 100%;
      padding-left: 0.6rem;
      :focus {
        border-color: ${COLORES.LIST_ACTIVE};
      }
    `}
`;

export const InputWrapper = styled.div`
  display: contents;
`;

export const Text = styled.div`
  display: block;
  line-height: 1.5rem;
  padding-left: 0.45rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
