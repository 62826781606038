import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { Link } from "react-router-dom";
import { FieldText } from "../FieldText";
import { FieldDate } from "../FieldDate";
import { FieldDatetime } from "../FieldDatetime";
import { Button } from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "../Dialog";

export const RegistroUsuario = ({
  metadataTable,
  usuario,
  access,
  setLoading,
  toast,
  update,
}) => {
  const [dialogConfirmarVisible, setDialogConfirmarVisible] = useState(false);

  const eliminarUsuario = () => {
    setLoading(true);

    const data = { IdUsuario: usuario.Id };
    API.eliminarUsuario(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Usuario Eliminado");
          update();
        } else if (res.Estado === "KO") {
          toast.error("No se pudo eliminar el usuario: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al eliminar usuario: " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al eliminar usuario: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  return (
    <S.Tr>
      <S.Td button>
        <Link to={`/usuarios/${usuario.Id}`}>
          <Button
            label="👁"
            title="Ver detalle"
            className="edit"
            disabled={!access?.Usuarios?.Acceso}
          />
        </Link>
      </S.Td>
      {metadataTable.map((field) => {
        const type = field.tipo;
        const idField = field.nombreCampo;
        const value = usuario[field.nombreCampo] || "";
        const required = field.requerido;

        switch (type) {
          case "text":
            return (
              <S.Td key={idField} title={value}>
                <FieldText
                  id={idField}
                  value={value}
                  readOnly={true}
                  required={required}
                />
              </S.Td>
            );

          case "date":
            return (
              <S.Td key={idField} title={value}>
                <FieldDate
                  id={idField}
                  value={value.split("T")[0] || ""}
                  readOnly={true}
                  required={required}
                />
              </S.Td>
            );

          case "datetime":
            return (
              <S.Td key={idField} title={value}>
                <FieldDatetime
                  id={idField}
                  value={value || "T"}
                  readOnly={true}
                  required={required}
                />
              </S.Td>
            );

          case "enum":
            return (
              <S.Td key={idField} title={value}>
                <FieldText
                  id={idField}
                  value={value.Etiqueta}
                  readOnly={true}
                  required={required}
                />
              </S.Td>
            );

          case "bool":
            return (
              <S.Td key={idField}>
                <FieldText
                  id={idField}
                  value={value ? "Sí" : "No"}
                  readOnly={true}
                  required={required}
                />
              </S.Td>
            );

          default:
            return (
              <S.Td key={idField}>
                {typeof value === "object" ? value.Etiqueta : value}
              </S.Td>
            );
        }
      })}
      <S.Td>
        <S.Buttons>
          <button
            key="eliminarUsuario"
            className="action delete"
            type="button"
            title="Eliminar Usuario"
            onClick={() => setDialogConfirmarVisible(true)}
            disabled={!access?.Usuarios?.Eliminar}
          >
            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
          </button>
        </S.Buttons>
        {dialogConfirmarVisible && (
          <Dialog
            type="confirm"
            message="¿Quieres eliminar el usuario?"
            labelConfirm="Eliminar"
            confirm={eliminarUsuario}
            cancel={() => setDialogConfirmarVisible(false)}
          />
        )}
      </S.Td>
    </S.Tr>
  );
};

RegistroUsuario.propTypes = {
  metadataTable: PropTypes.array,
  usuario: PropTypes.object,
  access: PropTypes.object,
  setLoading: PropTypes.func,
  toast: PropTypes.func,
  update: PropTypes.func,
};
