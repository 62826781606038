import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
// import * as API from "../../api";
import { Link } from "react-router-dom";
import { FieldText } from "../FieldText";
import { FieldDate } from "../FieldDate";

export const RegistroRol = ({ metadataTable, rol }) => {
  return (
    <S.Tr deleted={rol.eliminado}>
      {metadataTable.map((field) => {
        const type = field.tipo;
        const idField = field.nombreCampo;
        const value = rol[field.nombreCampo] || "";

        switch (type) {
          case "text":
            return (
              <S.Td key={idField} title={value}>
                {idField === "Nombre" ? (
                  <Link to={"/roles/" + rol.Id}>{rol.Nombre}</Link>
                ) : (
                  <FieldText id={idField} value={value} readOnly={true} />
                )}
              </S.Td>
            );

          case "date":
            return (
              <S.Td key={idField} title={value}>
                <FieldDate
                  id={idField}
                  value={value.split("T")[0] || ""}
                  readOnly={true}
                />
              </S.Td>
            );

          default:
            return <S.Td key={idField}>{value}</S.Td>;
        }
      })}
    </S.Tr>
  );
};

RegistroRol.propTypes = {
  metadataTable: PropTypes.array,
  rol: PropTypes.object,
};
