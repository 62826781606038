import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { RegistroPrefactura } from "../../components/RegistroPrefactura";
import { RegistroMovimiento } from "../../components/RegistroMovimiento";
import { RegistroComentario } from "../../components/RegistroComentario";
import { Dialog } from "../../components/Dialog";
import {
  MOVIMIENTOS_POR_PAGINA,
  ESTADOS_PREFACTURA,
  METADATA_TABLE_COMENTARIOS,
} from "../../constants";
import { usePermissions } from "../../context";

export const DetallePrefactura = () => {
  const { permissions } = usePermissions();
  const { idPrefactura } = useParams();
  const [loading, setLoading] = useState(true);
  const [metadataTablePrefacturas, setMetadataTablePrefacturas] = useState([]);
  const [metadataTableMovimientos, setMetadataTableMovimientos] = useState([]);
  const [detallePrefactura, setDetallePrefactura] = useState({});
  const [selectedIdsVinculados, setSelectedIdsVinculados] = useState([]);
  const [allSelectedVinculados, setAllSelectedVinculados] = useState(false);
  const [selectedIdsNoVinculados, setSelectedIdsNoVinculados] = useState([]);
  const [allSelectedNoVinculados, setAllSelectedNoVinculados] = useState(false);
  const [otrosMovimientos, setOtrosMovimientos] = useState([]);
  const [showUnlinkedMovimientos, setShowUnlinkedMovimientos] = useState(false);
  const [filtersMovimientos, setFiltersMovimientos] = useState([]);
  const [triggerGetPrefactura, setTriggerGetPrefactura] = useState(true);
  const [dialogComentarioVisible, setDialogComentarioVisible] = useState(false);
  const [nextPageNoVinculados, setNextPageNoVinculados] = useState(0);
  const [nextPageDetallePrefactura, setNextPageDetallePrefactura] = useState(0);

  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({ Modulo: "Prefacturas", TipoDefinicion: "grilla" })
      .then((res) => isMounted && setMetadataTablePrefacturas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.definicionPagina({ Modulo: "Movimientos", TipoDefinicion: "detalle" })
      .then((res) => isMounted && setMetadataTableMovimientos(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerFiltrosBusqueda("Movimientos")
      .then((res) => isMounted && setFiltersMovimientos(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, [idPrefactura]);

  useEffect(() => {
    let isMounted = true;
    triggerGetPrefactura && isMounted && obtenerPrefactura();
    return () => (isMounted = false);
  }, [triggerGetPrefactura]); // eslint-disable-line react-hooks/exhaustive-deps

  const prefactura = detallePrefactura.Prefactura || {};
  const movimientos = detallePrefactura.MovimientosVinculados;
  const comentarios = detallePrefactura.Comentarios;

  const obtenerPrefactura = (page = 1) => {
    const data = {};
    data.IdPrefactura = idPrefactura;
    data.Paginacion = {
      Pagina: page,
      CantidadRegistros: MOVIMIENTOS_POR_PAGINA,
    };

    API.obtenerPrefactura(data)
      .then((res) => {
        if (page === 1) setDetallePrefactura(res);
        else
          res.MovimientosVinculados.forEach((element) =>
            movimientos.push(element)
          );

        if (res.MovimientosVinculados.length < MOVIMIENTOS_POR_PAGINA)
          setNextPageDetallePrefactura(0);
        else setNextPageDetallePrefactura(page + 1);
      })
      .catch((error) => {
        if (error.code !== 401) toast.error("Error al obtener Prefactura");
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
    setTriggerGetPrefactura(false);
  };

  const mostrarMovimientosNoVinculados = () => {
    if (!showUnlinkedMovimientos && otrosMovimientos.length === 0) {
      setLoading(true);
      obtenerMovimientosNoVinculados();
    }
    setShowUnlinkedMovimientos(!showUnlinkedMovimientos);
  };

  const obtenerMovimientosNoVinculados = (page = 1) => {
    const data = {};
    data.Paginacion = {
      Pagina: page,
      CantidadRegistros: MOVIMIENTOS_POR_PAGINA,
    };
    data.Filtros = filtersMovimientos.map((filter) => {
      return {
        IdFiltro: filter.IdFiltro,
        ValorFiltro: prefactura[filter.NombreFiltro]?.Valor,
      };
    });
    API.obtenerMovimientos(data)
      .then((res) => {
        if (page === 1) setOtrosMovimientos(res);
        else setOtrosMovimientos([...otrosMovimientos, ...res]);

        if (res.length < MOVIMIENTOS_POR_PAGINA) setNextPageNoVinculados(0);
        else setNextPageNoVinculados(page + 1);
      })
      .catch((error) => {
        toast.error("Error al obtener Gastos");
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleSelectionChangeVinculados = (id, checked) => {
    let newSelectedIds = [...selectedIdsVinculados];
    if (checked) {
      newSelectedIds.push(id);
    } else {
      const index = newSelectedIds.indexOf(id);
      newSelectedIds.splice(index, 1);
    }
    setSelectedIdsVinculados(newSelectedIds);
  };

  const handleSelectionChangeNoVinculados = (id, checked) => {
    let newSelectedIds = [...selectedIdsNoVinculados];
    if (checked) {
      newSelectedIds.push(id);
    } else {
      const index = newSelectedIds.indexOf(id);
      newSelectedIds.splice(index, 1);
    }
    setSelectedIdsNoVinculados(newSelectedIds);
  };

  const desvincularMovimientos = () => {
    setLoading(true);
    const data = {};
    data.IdPrefactura = idPrefactura;
    data.Movimientos = selectedIdsVinculados;

    API.desvincularMovimientos(data)
      .then((res) => {
        if (res.Estado === "OK") {
          setTriggerGetPrefactura(true);
          toast.success(
            selectedIdsVinculados.length === 1
              ? "Gasto Desvinculado"
              : "Gastos Desvinculados"
          );
          obtenerMovimientosNoVinculados();
          setSelectedIdsVinculados([]);
          setSelectedIdsNoVinculados([]);
          setAllSelectedVinculados(false);
          setAllSelectedNoVinculados(false);
        } else if (res.Estado === "KO") {
          toast.error("No se pudo Desvincular los gastos: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al Desvincular gasto(s): " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al Desvincular gasto(s)" + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const vincularMovimientos = () => {
    setLoading(true);
    const data = {};
    data.IdPrefactura = idPrefactura;
    data.Movimientos = selectedIdsNoVinculados;

    API.vincularMovimientos(data)
      .then((res) => {
        if (res.Estado === "OK") {
          setTriggerGetPrefactura(true);
          toast.success(
            selectedIdsNoVinculados.length === 1
              ? "Gasto Vinculado"
              : "Gastos Vinculados"
          );
          obtenerMovimientosNoVinculados();
          setSelectedIdsVinculados([]);
          setSelectedIdsNoVinculados([]);
          setAllSelectedVinculados(false);
          setAllSelectedNoVinculados(false);
        } else if (res.Estado === "KO") {
          toast.error("No se pudo Vincular los gastos: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al Vincular gasto(s): " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al vincular gasto(s)" + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const addComentario = (comentario) => {
    setLoading(true);

    const data = {};
    data.Id = "00000000-0000-0000-0000-000000000000";
    data.IdPrefactura = idPrefactura;
    data.Comentario = comentario;

    API.guardarComentario(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Comentario agregado");
          setDialogComentarioVisible(false);
          setTriggerGetPrefactura(true);
        } else if (res.Estado === "KO") {
          toast.error("No se pudo agregar el comentario: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al agregar comentario: " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al agregar comentario: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const toggleAllVinculados = () => {
    if (allSelectedVinculados) {
      setSelectedIdsVinculados([]);
      setAllSelectedVinculados(false);
    } else {
      const allVinculados = [];
      movimientos.forEach((movimiento) => allVinculados.push(movimiento.id));
      setSelectedIdsVinculados(allVinculados);
      setAllSelectedVinculados(true);
    }
  };

  const toggleAllNoVinculados = () => {
    if (allSelectedNoVinculados) {
      setSelectedIdsNoVinculados([]);
      setAllSelectedNoVinculados(false);
    } else {
      const allNoVinculados = [];
      otrosMovimientos.forEach((movimiento) =>
        allNoVinculados.push(movimiento.id)
      );
      setSelectedIdsNoVinculados(allNoVinculados);
      setAllSelectedNoVinculados(true);
    }
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      {dialogComentarioVisible && (
        <Dialog
          type="input"
          message="Comentario"
          labelConfirm="Añadir Comentario"
          confirm={addComentario}
          cancel={() => setDialogComentarioVisible(false)}
        />
      )}
      <S.Subtitle id="first">
        Detalle de Prefactura - Código {prefactura.codigo}
      </S.Subtitle>
      <S.Table>
        <S.ColGroupPrefacturas>
          {metadataTablePrefacturas.map((campo) => (
            <S.Col key={campo.id} />
          ))}
          <S.Col />
        </S.ColGroupPrefacturas>
        <S.Tr>
          {metadataTablePrefacturas.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              {campo.etiqueta}
            </S.Th>
          ))}
          <S.Th />
        </S.Tr>
        <RegistroPrefactura
          key={prefactura.id}
          metadataTable={metadataTablePrefacturas}
          prefactura={prefactura}
          type="detallePrefactura"
          update={() => setTriggerGetPrefactura(true)}
          toast={toast}
          setLoading={setLoading}
          access={permissions}
        />
      </S.Table>

      <S.Subtitle>
        <p>Gastos Vinculados</p>
        <button
          onClick={desvincularMovimientos}
          disabled={
            !permissions?.Prefacturas?.Editar ||
            selectedIdsVinculados.length === 0
          }
        >
          Desvincular seleccionados
          {selectedIdsVinculados.length
            ? ` (${selectedIdsVinculados.length})`
            : ""}
        </button>
      </S.Subtitle>
      <S.Table>
        <S.ColGroupMovimientos>
          <S.Col />
          {metadataTableMovimientos.map((campo) => (
            <S.Col key={campo.id} />
          ))}
        </S.ColGroupMovimientos>
        <S.Tr>
          <S.Th>
            <S.Checkbox>
              <S.InputCheckbox
                type="checkbox"
                checked={allSelectedVinculados}
                onChange={toggleAllVinculados}
                disabled={prefactura.idEstado !== ESTADOS_PREFACTURA.CREADA}
              />
            </S.Checkbox>
          </S.Th>
          {metadataTableMovimientos.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              <span>{campo.etiqueta}</span>
            </S.Th>
          ))}
        </S.Tr>
        {movimientos?.map((movimiento) => (
          <RegistroMovimiento
            key={movimiento.id}
            metadataTable={metadataTableMovimientos}
            movimiento={movimiento}
            type="detail"
            handleSelectionChange={handleSelectionChangeVinculados}
            selected={selectedIdsVinculados.includes(movimiento.id)}
            disabled={prefactura.idEstado !== ESTADOS_PREFACTURA.CREADA}
            setLoading={setLoading}
          />
        ))}
      </S.Table>
      <S.Pagination>
        <button
          disabled={nextPageDetallePrefactura === 0}
          onClick={() => obtenerPrefactura(nextPageDetallePrefactura)}
        >
          {nextPageDetallePrefactura === 0
            ? "No hay más gastos de esta prefactura"
            : "Mostrar más gastos de la prefactura..."}
        </button>
      </S.Pagination>
      {prefactura?.EstadoPrefactura?.Etiqueta !== "Anulada" && (
        <>
          <S.DropDown onClick={mostrarMovimientosNoVinculados}>
            Vincular más gastos
            <span>{showUnlinkedMovimientos ? " ▼" : " ▶"}</span>
          </S.DropDown>
          {showUnlinkedMovimientos && (
            <>
              <S.Subtitle>
                Gastos No Vinculados
                <button
                  onClick={vincularMovimientos}
                  disabled={
                    !permissions?.Prefacturas?.Editar ||
                    selectedIdsNoVinculados.length === 0
                  }
                >
                  Vincular seleccionados
                  {selectedIdsNoVinculados.length
                    ? ` (${selectedIdsNoVinculados.length})`
                    : ""}
                </button>
              </S.Subtitle>
              {otrosMovimientos.length === 0 && !loading ? (
                "No hay más gastos con el Área, Proveedor, Sociedad y Fiscalidad de esta prefactura"
              ) : (
                <>
                  <S.Table>
                    <S.ColGroupMovimientos>
                      <S.Col />
                      {metadataTableMovimientos.map((campo) => (
                        <S.Col key={campo.id} />
                      ))}
                    </S.ColGroupMovimientos>
                    <S.Tr>
                      <S.Th>
                        <S.Checkbox>
                          <S.InputCheckbox
                            type="checkbox"
                            checked={allSelectedNoVinculados}
                            onChange={toggleAllNoVinculados}
                            disabled={
                              prefactura.idEstado !== ESTADOS_PREFACTURA.CREADA
                            }
                          />
                        </S.Checkbox>
                      </S.Th>
                      {metadataTableMovimientos.map((campo) => (
                        <S.Th key={campo.id} title={campo.etiqueta}>
                          <span>{campo.etiqueta}</span>
                        </S.Th>
                      ))}
                    </S.Tr>
                    {otrosMovimientos?.map((movimiento) => (
                      <RegistroMovimiento
                        key={movimiento.id}
                        metadataTable={metadataTableMovimientos}
                        movimiento={movimiento}
                        type="detail"
                        handleSelectionChange={
                          handleSelectionChangeNoVinculados
                        }
                        selected={selectedIdsNoVinculados.includes(
                          movimiento.id
                        )}
                        disabled={
                          prefactura.idEstado !== ESTADOS_PREFACTURA.CREADA
                        }
                        setLoading={setLoading}
                      />
                    ))}
                  </S.Table>
                  <S.Pagination>
                    <button
                      disabled={nextPageNoVinculados === 0}
                      onClick={() =>
                        obtenerMovimientosNoVinculados(nextPageNoVinculados)
                      }
                    >
                      {nextPageNoVinculados === 0
                        ? "No hay más gastos con el Área, Proveedor, Sociedad y Fiscalidad de esta prefactura"
                        : "Mostrar más gastos no vinculados..."}
                    </button>
                  </S.Pagination>
                </>
              )}
            </>
          )}
        </>
      )}

      {detallePrefactura.ComentariosVisible && (
        <>
          <S.Subtitle>
            <p>Comentarios</p>
            <button
              onClick={() => setDialogComentarioVisible(true)}
              disabled={
                !permissions?.Prefacturas?.Editar ||
                prefactura.idEstado === ESTADOS_PREFACTURA.ANULADA ||
                prefactura.idEstado === ESTADOS_PREFACTURA.VALIDADA ||
                prefactura.idEstado === ESTADOS_PREFACTURA.ONBASE
              }
            >
              Agregar Comentario
            </button>
          </S.Subtitle>
          {comentarios === null || comentarios?.length === 0 ? (
            "No hay comentarios"
          ) : (
            <S.Table>
              <S.ColGroupComentarios>
                {METADATA_TABLE_COMENTARIOS.map((campo) => (
                  <S.Col key={campo.nombreCampo} />
                ))}
                <S.Col />
              </S.ColGroupComentarios>
              <S.Tr>
                {METADATA_TABLE_COMENTARIOS.map((campo) => (
                  <S.Th key={campo.nombreCampo} title={campo.etiqueta}>
                    <span>{campo.etiqueta}</span>
                  </S.Th>
                ))}
                <S.Th />
              </S.Tr>
              {comentarios?.map((comentario) => (
                <RegistroComentario
                  key={comentario.id}
                  metadataTable={METADATA_TABLE_COMENTARIOS}
                  comentario={comentario}
                  update={() => setTriggerGetPrefactura(true)}
                  addComentario={addComentario}
                  setLoading={setLoading}
                />
              ))}
            </S.Table>
          )}
        </>
      )}
    </S.Container>
  );
};
