import styled, { keyframes } from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #0003;
`;

const example = keyframes`
  0% {transform: scale(0);}
  90% {transform: scale(1.1);}
  100% {transform: scale(1);}
`;

export const Wrapper = styled.div`
  background-color: white;
  margin: 6rem auto;
  padding: 2rem;
  border: thin solid ${COLORES.LIGHT_BORDER};
  border-radius: 0.5rem;
  box-shadow: 0.3rem 0.3rem 0.3rem #555;
  width: 10%;
  text-align: center;
  animation: ${example} 0.2s linear forwards;
`;

export const Content = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: ${COLORES.LOADING_TEXT};
  line-height: 1rem;
  margin: 0;
`;
