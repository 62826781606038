import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const DetallePartida = styled.div`
  font-size: 0.8rem;
  padding: 1rem;
  background-color: ${COLORES.BACKGROUND};

  button {
    cursor: pointer;
  }

  div#first {
    margin-top: 0;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
`;

export const FieldsContainer = styled.ul`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(3rem, auto);
  background-color: ${COLORES.FONDO};
  padding: 1rem 0 3rem 0;
`;
