import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FieldCheckbox = ({
  modulo,
  propiedad,
  label,
  value,
  handleChange,
  readOnly,
  hidden,
  centered,
}) => {
  return (
    <S.Container className={hidden ? "hidden" : "visible"}>
      {label && <S.Label>{label}</S.Label>}
      <S.CheckboxContainer centered={centered}>
        <S.Input
          type="checkbox"
          value={value}
          checked={value}
          disabled={readOnly}
          onChange={(e) => handleChange(modulo, propiedad, e.target.checked)}
        />
      </S.CheckboxContainer>
    </S.Container>
  );
};

FieldCheckbox.propTypes = {
  modulo: PropTypes.string,
  propiedad: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  handleChange: PropTypes.func,
  readOnly: PropTypes.bool,
  hidden: PropTypes.bool,
  centered: PropTypes.bool,
};
