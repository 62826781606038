import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FieldDate } from "../FieldDate";

const CustomTable = ({ metadataRegisters, data, page, setPage, listSize }) => {
  const parseValue = (row, field) => {
    switch (field.tipo) {
      case "bool":
        return row[field.nombreCampo] ? "Sí" : "No";

      case "date":
        return (
          <FieldDate
            id={field.nombreCampo}
            value={row[field.nombreCampo]?.split("T")[0] || ""}
            readOnly={true}
          />
        );

      default:
        return row[field.nombreCampo];
    }
  };

  return (
    <>
      <S.Table>
        <S.ColGroup>
          {metadataRegisters.map((field) => (
            <S.Col key={field.id} />
          ))}
        </S.ColGroup>
        <S.Tr>
          {metadataRegisters.map((field, index) => (
            <S.Th key={index} title={field.etiqueta}>
              <S.HeaderColumn>{field.etiqueta}</S.HeaderColumn>
            </S.Th>
          ))}
        </S.Tr>
        {!!data?.length && (
          <>
            {data.map((row, index) => (
              <S.Tr key={index}>
                {metadataRegisters.map((field) => (
                  <S.Td
                    key={field.id}
                    title={row[field.nombreCampo]?.toString()}
                  >
                    {parseValue(row, field)}
                  </S.Td>
                ))}
              </S.Tr>
            ))}
          </>
        )}
      </S.Table>
      {!data?.length && (
        <S.EmptyRegs>
          <div>Sin registros</div>
        </S.EmptyRegs>
      )}

      {!!data.length && (
        <S.Pagination>
          <button
            onClick={() => setPage(page - 1)}
            disabled={page === 1 ? true : false}
          >
            <FontAwesomeIcon icon={faCaretLeft} size="lg" />
            <span>Anterior</span>
          </button>

          <span>Página: {page}</span>

          <button
            onClick={() => setPage(page + 1)}
            disabled={data.length < listSize}
          >
            <span>Siguiente</span>
            <FontAwesomeIcon icon={faCaretRight} size="lg" />
          </button>
        </S.Pagination>
      )}
    </>
  );
};

export default CustomTable;
