import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  font-size: 0.8rem;
  padding: 1rem;
  background-color: ${COLORES.BACKGROUND};

  button {
    cursor: pointer;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
`;

export const Table = styled.table`
  font-size: 0.7rem;
  border-collapse: collapse;
  margin: 0.5rem 0;
  table-layout: fixed;
  width: 100%;

  tr {
    height: 2rem;
    border: thin solid lightgray;
  }

  thead tr {
    color: ${COLORES.HEADER_TABLE_FONT};
    background-color: ${COLORES.HEADER_TABLE_BACKGROUND};
  }

  tbody tr td:first-child {
    font-weight: bold;
    padding-left: 1rem;
  }
`;
