import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { FieldText } from "../FieldText";
import { FieldNumber } from "../FieldNumber";
import { FieldDatetime } from "../FieldDatetime";

export const RegistroAuditoria = ({
  metadataTable,
  auditoria: initialValues,
  listas,
  type,
  handleSelectionChange,
  selected,
  disabled,
  setLoading,
  update,
}) => {
  const [editing] = useState(false);
  const [auditoria] = useState(initialValues);
  const form = useRef();

  return (
    <S.Tr ref={form} highlighted={editing || selected}>
      {metadataTable.map((field) => {
        const type = field.tipo;
        const idField = field.nombreCampo;
        const value = auditoria[field.nombreCampo] || "";
        const editable = field.editable;
        const required = field.requerido;

        switch (type) {
          case "text":
            return (
              <S.Td key={idField} title={value}>
                <FieldText
                  id={idField}
                  value={value}
                  readOnly={!editable || !editing}
                  required={required}
                />
              </S.Td>
            );

          case "int":
          case "float":
            return (
              <S.Td key={idField} title={value}>
                <FieldNumber
                  id={idField}
                  value={value || 0}
                  empty={false}
                  type={type}
                  readOnly={!editable || !editing}
                  required={required}
                />
              </S.Td>
            );

          case "date":
            return (
              <S.Td key={idField} title={value}>
                <FieldDatetime
                  id={idField}
                  value={value || ""}
                  readOnly={!editable || !editing}
                  required={required}
                />
              </S.Td>
            );

          default:
            return <S.Td key={idField}>{value}</S.Td>;
        }
      })}
    </S.Tr>
  );
};

RegistroAuditoria.propTypes = {
  metadataTable: PropTypes.array,
  auditoria: PropTypes.object,
  listas: PropTypes.object,
  type: PropTypes.string,
  handleSelectionChange: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  setLoading: PropTypes.func,
  update: PropTypes.func,
};
