import styled, { css } from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  height: 100%;

  .hidden {
    display: none;
  }
`;

export const Title = styled.div`
  color: ${COLORES.GRIS_OSCURO};
  margin-bottom: 0.2rem;
`;

export const InputContainer = styled.div`
  height: 100%;
`;

export const InputWrapper = styled.div`
  display: contents;
  height: 100%;
`;

export const Input = styled.input`
  background-color: ${COLORES.BACKGROUND};
  border: none;
  outline: thin solid ${COLORES.LIGHT_BORDER};
  box-sizing: border-box;
  padding: 0.25rem 0;
  height: 100%;
  width: 100%;

  ::-webkit-calendar-picker-indicator {
    margin-left: 0;
  }

  ${(props) =>
    props.styles === "filter" &&
    css`
      outline: none;
      border: thin solid ${COLORES.LIGHT_BORDER};
      border-radius: 0.25rem;
      height: 1.8rem;
      width: 100%;
      padding-left: 0.6rem;
      :focus {
        border-color: ${COLORES.LIST_ACTIVE};
      }
    `}
`;

export const Text = styled.div`
  display: block;
  padding: 1px 9px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
