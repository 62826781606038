import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  .hidden {
    display: none;
  }
`;

export const Label = styled.div`
  font-size: 0.75rem;
  color: ${COLORES.GRIS_OSCURO};
  margin-bottom: 0.2rem;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.centered ? "center" : "inherit")};
  align-items: center;
  height: 30px;
`;

export const Input = styled.input`
  height: 1rem;
  width: 1rem;
`;
