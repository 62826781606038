import styled, { css } from "styled-components/macro";
import { COLORES } from "../../constants";

export const IONEngagedPageContent = styled.div`
  font-size: 0.8rem;
  padding: 1rem;

  button {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
    }
  }
`;

export const AccordionContent = styled.div`
  display: grid;
  grid-template-columns: ${({ twoColumns }) => twoColumns && "50% 50%"};
  grid-template-columns: ${({ twoColumnsAsymmetric }) =>
    twoColumnsAsymmetric && "max-content auto"};
`;

export const LeftColumn = styled.div`
  border-right: thin solid ${COLORES.LIGHT_BORDER};
`;

export const RightColumn = styled.div``;

const AccordionSectionBase = styled.div`
  display: grid;
  padding: 1rem;
  column-gap: 1rem;
  row-gap: 0.25rem;
  border-bottom: thin solid ${COLORES.LIGHT_BORDER};

  .checkbox {
    grid-area: checkbox;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const AccordionSectionComprometido1 = styled(AccordionSectionBase)`
  grid-template-areas:
    "cell cell cell"
    ". . checkbox";
  grid-template-columns: 3fr 2fr 3fr;
`;

export const AccordionSectionComprometido2 = styled(AccordionSectionBase)`
  display: flex;
  align-items: flex-start;
`;

export const AccordionSectionComprometido2Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 23px 1fr 1fr 1fr;
  grid-row-gap: 10px;
`;

export const InputListFacturado = styled.div`
  grid-column: 1 / 3;
  grid-row: 2 / 3;
`;

export const InputListFacturable = styled.div`
  grid-column: 1 / 3;
  grid-row: 3 / 3;
`;

export const InputsDate = styled.div`
  grid-area: 4 / 1 / 5 / 3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 10px;
  align-items: center;
`;

export const AccordionSectionComprometido3 = styled(AccordionSectionBase)`
  grid-template-areas:
    "cell cell cell"
    "cell . .";
  grid-template-columns: 2fr 2fr 3fr;
`;

export const AccordionSectionComprometido4 = styled(AccordionSectionBase)`
  grid-template-areas: "cell .";
  grid-template-columns: 3fr 7fr;
`;

export const AccordionSectionComprometidoResumen = styled(AccordionSectionBase)`
  padding: 1rem 2rem;
  grid-template-areas: "cell";
  grid-template-columns: 1fr;
`;

export const AccordionSectionFacturable1 = styled(AccordionSectionBase)`
  grid-template-areas: "cell";
  grid-template-columns: 1fr;
`;

export const AccordionSectionFacturable2 = styled(AccordionSectionBase)`
  grid-template-areas: "cell cell cell cell .";
  grid-template-columns: 3fr 1fr 2fr 2fr 3fr;
`;

export const AccordionSectionListado = styled(AccordionSectionBase)`
  grid-template-areas: "cell";
  grid-template-columns: 1fr;
`;

export const FieldListSearchWrapper = styled.div`
  width: 20rem;
`;

export const Filters = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.count}, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  justify-content: space-between;
  ${({ billableSection }) =>
    billableSection &&
    css`
      align-items: flex-start;
      margin-top: 1.12rem;
    `}

  button {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
    color: ${COLORES.BUTTON_TEXT_DEFAULT};
    min-height: 1.75rem;

    :disabled {
      cursor: default;
    }
  }
`;

export const Checkboxes = styled.div`
  label,
  input {
    vertical-align: middle;
    cursor: pointer;
  }

  button {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
    }
  }

  input[type="checkbox"] {
    margin-right: 0.25rem;
  }
`;

export const CheckboxesVertical = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Checkbox = styled.div`
  display: flex;
  label {
    margin: 0.15rem 0.25rem;
  }
`;
