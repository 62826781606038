import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FieldText = ({
  id,
  label,
  value,
  handleChange,
  placeholder,
  readOnly,
  required,
  styles,
  type,
}) => {
  return (
    <S.Container type={type} styles={styles}>
      {label && <S.Title>{label}</S.Title>}
      <S.InputContainer>
        <S.InputWrapper className={!readOnly ? "visible" : "hidden"}>
          <S.Input
            type="text"
            name={id}
            value={value}
            onChange={(e) => handleChange(id, e.target.value)}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required}
            styles={styles}
          />
        </S.InputWrapper>
        <S.Text className={!readOnly ? "hidden" : "visible"}>{value}</S.Text>
      </S.InputContainer>
    </S.Container>
  );
};

FieldText.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  styles: PropTypes.string,
};
