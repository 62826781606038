export const METADATA_SUMMARY_TABLE = [
  { key: "TipoFacturable", label: "Tipo Facturable" },
  { key: "Validar", label: "Validar" },
  { key: "Facturable", label: "Facturable", type: "boolean" },
  { key: "Exportado", label: "Exportado" },
  { key: "Registros", label: "Registros" },
  { key: "ImporteTotal", label: "Importe Total", type: "currency" },
];

export const COLORS = {
  HEADER_BACKGROUND: "#44546a",
  ODD_ROWS_BACKGROUND: "white",
  EVEN_ROWS_BACKGROUND: "#eee",
  HEADER_FONT: "white",
  BORDER: "lightgray",
};
