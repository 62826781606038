import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  font-size: 0.8rem;
  padding: 1rem;
  background-color: ${COLORES.BACKGROUND};

  button {
    cursor: pointer;
  }

  div#first {
    margin-top: 0;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;

  button {
    font-size: 0.75rem;
    font-weight: 600;
    color: ${COLORES.BUTTON_TEXT_DEFAULT};
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    border: none;
    border-radius: 0.2rem;
    height: 1.75rem;
    padding: 0 0.75rem;

    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
      cursor: default;
    }
  }
`;

export const Table = styled.div`
  display: table;
  font-size: 0.6rem;
  border-collapse: collapse;
  margin: 1rem 0;
  table-layout: fixed;
  width: 100%;
`;

export const ColGroupPrefacturas = styled.div`
  display: table-column-group;
  div:last-child {
    width: 12%;
  }
`;

export const ColGroupMovimientos = styled.div`
  display: table-column-group;
  div:first-child {
    width: 2.5%;
  }
`;

export const ColGroupComentarios = styled.div`
  display: table-column-group;
  div:first-child {
    width: 10%;
  }
  div:nth-child(2) {
    width: 10%;
  }
  div:last-child {
    width: 3%;
  }
`;

export const Col = styled.div`
  display: table-column;
`;

export const Tr = styled.div`
  display: table-row;
  height: 1.75rem;
`;

export const Th = styled.div`
  display: table-cell;
  vertical-align: middle;
  border: thin solid ${COLORES.LIGHT_BORDER};
  font-weight: 600;
  padding-left: 0.5rem;
  color: ${COLORES.HEADER_TABLE_FONT};
  background-color: ${COLORES.HEADER_TABLE_BACKGROUND};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Checkbox = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`;

export const InputCheckbox = styled.input`
  vertical-align: middle;
`;

export const DropDown = styled.div`
  display: inline-block;
  color: blue;
  cursor: pointer;

  span {
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const Pagination = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;

  button {
    border: none;
    background-color: ${COLORES.BACKGROUND};
    color: ${COLORES.LINK};
    padding: 0;

    :disabled {
      color: ${COLORES.TEXT_DISABLED};
      cursor: default;
    }
  }
`;
