import styled from "styled-components/macro";
import { COLORS as C } from "./constants";

export const SummaryTable = styled.div`
  width: 100%;

  p {
    margin-bottom: 0.5rem;
  }
`;

export const Table = styled.div`
  display: table;
  width: 100%;
  border-radius: 0.2rem;
  border-collapse: collapse;
  overflow: hidden;
`;

export const Tr = styled.div`
  display: table-row;
  height: 1.5rem;
  color: ${({ header }) => header && C.HEADER_FONT};
  background-color: ${({ rowNum }) =>
    rowNum % 2 === 0 ? C.ODD_ROWS_BACKGROUND : C.EVEN_ROWS_BACKGROUND};
  background-color: ${({ header }) => header && C.HEADER_BACKGROUND};
`;

export const Td = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: middle;

  border: thin solid ${C.BORDER};
  border-top-color: ${({ header }) => header && C.HEADER_BACKGROUND};
  &:first-child {
    border-left-color: ${({ header }) => header && C.HEADER_BACKGROUND};
  }
  &:last-child {
    border-right-color: ${({ header }) => header && C.HEADER_BACKGROUND};
  }

  input {
    margin-top: 0.2rem;
  }

  ${({ noData }) =>
    noData && {
      padding: "0 0.5rem",
      borderRadius: "0.2rem",
    }}
`;
