import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { Loading } from "../Loading";
import { toast } from "react-toastify";

export const DialogGeneratePrefactura = ({
  selectedAllByArea,
  areaSelected,
  selectedMovimientos,
  confirm,
  cancel,
}) => {
  const [summaryTable, setSummaryTable] = useState([]);
  const [selectedIndexs, setSelectedIndexs] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const metadataSummaryTable = [
    { name: "Area", label: "Área" },
    { name: "Proveedor", label: "Proveedor" },
    { name: "Sociedad", label: "Sociedad" },
    { name: "Fiscalidad", label: "Fiscalidad" },
    { name: "Count", label: "Nº Gastos" },
    { name: "Total", label: "Importe", type: "currency" },
  ];

  useEffect(() => {
    setAllSelected(
      summaryTable.length > 0 &&
        summaryTable.every((r) => selectedIndexs.some((e) => e === r.index))
    );
  }, [summaryTable, selectedIndexs]);

  useEffect(() => {
    const generateTotalizedMovements = () => {
      const movimientos = structuredClone(selectedMovimientos);

      // ordenación
      const sortedMovimientos = movimientos.sort((a, b) => {
        const areaA = a.Area?.toLowerCase();
        const areaB = b.Area?.toLowerCase();
        if (areaA < areaB) return -1;
        if (areaA > areaB) return 1;

        const proveedorA = a.Proveedor?.toLowerCase();
        const proveedorB = b.Proveedor?.toLowerCase();
        if (proveedorA < proveedorB) return -1;
        if (proveedorA > proveedorB) return 1;

        const sociedadA = a.Sociedad?.toLowerCase();
        const sociedadB = b.Sociedad?.toLowerCase();
        if (sociedadA < sociedadB) return -1;
        if (sociedadA > sociedadB) return 1;

        const fiscalidadA = a.Fiscalidad?.toLowerCase();
        const fiscalidadB = b.Fiscalidad?.toLowerCase();
        if (fiscalidadA < fiscalidadB) return -1;
        if (fiscalidadA > fiscalidadB) return 1;

        return 0;
      });

      // agrupación y totalización
      const totalizedMovimientos = Object.values(
        sortedMovimientos.reduce(
          (
            accumulator,
            {
              id,
              idArea,
              idProveedor,
              idSociedad,
              idFiscalidad,
              Area,
              Proveedor,
              Sociedad,
              Fiscalidad,
              Unidades,
              CosteUnitario,
            }
          ) => {
            const key = `${idArea}_${idProveedor}_${idSociedad}_${idFiscalidad}`;
            if (!accumulator[key]) {
              accumulator[key] = {
                ids: [],
                idArea,
                idProveedor,
                idSociedad,
                idFiscalidad,
                Area,
                Proveedor,
                Sociedad,
                Fiscalidad,
                Count: 0,
                Total: 0,
              };
            }
            accumulator[key].ids = [...accumulator[key].ids, id];
            accumulator[key].Count++;
            accumulator[key].Total += Unidades * CosteUnitario;
            return accumulator;
          },
          {}
        )
      ).map((item, index) => ({ index, ...item }));

      return totalizedMovimientos;
    };

    if (selectedAllByArea) {
      const data = { IdArea: areaSelected };

      setLoading(true);
      API.obtenerPosiblesPrefacturas(data)
        .then((res) =>
          setSummaryTable(res.map((item, index) => ({ index, ...item })))
        )
        .catch((error) => {
          toast.error("Error al crear tabla resumen");
          console.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      const newSummaryTable = generateTotalizedMovements();
      setSummaryTable(newSummaryTable);
    }
  }, [selectedAllByArea, areaSelected, selectedMovimientos]);

  const handleCheckboxChange = (index) => {
    setSelectedIndexs((prevSelectedIndexs) => {
      if (prevSelectedIndexs.includes(index)) {
        return prevSelectedIndexs.filter((e) => e !== index);
      } else {
        return [...prevSelectedIndexs, index];
      }
    });
  };

  const toggleAll = () => {
    if (allSelected) {
      setSelectedIndexs([]);
    } else {
      setSelectedIndexs(summaryTable.map((row) => row.index));
    }
  };

  const generarPrefacturaData = () => {
    const selectedRows = summaryTable.filter((row) =>
      selectedIndexs.includes(row.index)
    );
    const dataResult = selectedRows.map((record) => {
      const { ids, idArea, idProveedor, idSociedad, idFiscalidad } = record;
      const result = {
        allMovements: selectedAllByArea,
        ids,
        idArea,
        idProveedor,
        idSociedad,
        idFiscalidad,
      };
      if (selectedAllByArea) delete result.ids;
      return result;
    });
    return dataResult;
  };

  const parsedValue = (field, value) => {
    switch (field.type) {
      case "currency":
        const localizedString = value.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        const [integer, decimal] = localizedString.split(".");
        const parsedInteger = integer.replace(/,/g, ".");
        return [parsedInteger, decimal].join(",") + " €";

      default:
        return value;
    }
  };

  return (
    <S.Container onClick={cancel}>
      <Loading hidden={!loading} />
      <S.Wrapper onClick={(event) => event.stopPropagation()}>
        <S.TitleBar>
          <S.Close onClick={cancel}>×</S.Close>
        </S.TitleBar>
        <S.Content>
          <S.Title>Generación de Prefacturas</S.Title>
          <S.Table>
            <S.ColGroup>
              <S.Col />
              {metadataSummaryTable.map((field) => (
                <S.Col key={field.name} />
              ))}
            </S.ColGroup>
            <S.Tr>
              <S.Th>
                <S.Checkbox>
                  <S.InputCheckbox
                    id="allGroupsSelected"
                    type="checkbox"
                    checked={allSelected}
                    onChange={toggleAll}
                  />
                </S.Checkbox>
              </S.Th>
              {metadataSummaryTable.map((field) => (
                <S.Th key={field.name}>
                  <S.HeaderColumn>
                    <span>{field.label}</span>
                  </S.HeaderColumn>
                </S.Th>
              ))}
            </S.Tr>
            {summaryTable.map((record, index) => (
              <S.Tr key={index}>
                <S.Td>
                  <S.InputCheckbox
                    id={"record" + index}
                    type="checkbox"
                    checked={selectedIndexs.includes(record.index)}
                    onChange={() => handleCheckboxChange(record.index)}
                  />
                </S.Td>
                {metadataSummaryTable.map((field) => (
                  <S.Td key={field.name}>
                    {parsedValue(field, record[field.name])}
                  </S.Td>
                ))}
              </S.Tr>
            ))}
          </S.Table>
          <S.Buttons>
            <button
              id="generarPrefacturas"
              type="button"
              onClick={() => confirm(generarPrefacturaData())}
              disabled={selectedIndexs.length === 0}
            >
              Generar Prefacturas Seleccionadas
            </button>
            <button onClick={cancel}>Cancelar</button>
          </S.Buttons>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

DialogGeneratePrefactura.propTypes = {
  selectedAllByArea: PropTypes.bool.isRequired,
  areaSelected: PropTypes.string,
  selectedMovimientos: PropTypes.array,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
