import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { COLORES } from "../../constants";
import Select from "react-select";

export const FieldList = ({
  id,
  type,
  label,
  currentOption,
  currentOptions,
  options,
  handleChange,
  canBeEmpty,
  readOnly,
  required,
  hiddenIndicators,
  isDisabled = false,
}) => {
  return (
    <S.Container>
      {label && <S.Title>{label}</S.Title>}
      <S.SelectContainer
        className={readOnly ? "hidden" : "visible"}
        hiddenIndicators={hiddenIndicators}
      >
        <Select
          name={"id" + id}
          styles={customStyles}
          value={type === "enum" ? currentOption : currentOptions}
          options={options}
          onChange={(e) => {
            let newValue = null;
            if (type === "enum") {
              newValue = e ? { Valor: e.value, Etiqueta: e.label } : {};
            } else {
              newValue = e
                ? e.map((e) => ({ Valor: e.value, Etiqueta: e.label }))
                : [];
            }
            handleChange(id, newValue);
          }}
          isClearable={canBeEmpty}
          placeholder="Seleccionar..."
          noOptionsMessage={() => "No hay opciones"}
          required={required}
          isMulti={type === "enum_multiple"}
          isDisabled={isDisabled}
        />
      </S.SelectContainer>
      <S.Text className={readOnly ? "visible" : "hidden"}>
        {type === "enum"
          ? currentOption?.label
          : currentOptions?.map(
              (option, i, array) =>
                option.label + (i + 1 < array.length ? ", " : "")
            )}
      </S.Text>
    </S.Container>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "1rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: state.isFocused ? COLORES.LIST_ACTIVE : COLORES.BORDER,
    },
    borderColor: state.isFocused ? COLORES.LIST_ACTIVE : COLORES.LIGHT_BORDER,
    backgroundColor: COLORES.BACKGROUND,
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: "5px",
  }),
  indicatorSeparator: (provided, state) => ({}),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    "&.indicatorContainer": {
      padding: "0 8px",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0 8px",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "0 8px",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "6px 12px",
    borderBottom: `1px solid ${COLORES.LIGHT_BORDER}`,
    backgroundColor: state.isSelected ? COLORES.LIST_ACTIVE : "white",
    "&:hover": {
      backgroundColor: state.isSelected
        ? COLORES.LIST_ACTIVE
        : COLORES.LIST_HOVER,
    },
  }),
};

FieldList.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  currentOption: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  currentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  options: PropTypes.array,
  handleChange: PropTypes.func,
  canBeEmpty: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  hiddenIndicators: PropTypes.bool,
};
