import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { Link } from "react-router-dom";
import { FieldText } from "../FieldText";
import { FieldNumber } from "../FieldNumber";
import { FieldDate } from "../FieldDate";
import { Dialog } from "../Dialog";
import { ESTADOS_PREFACTURA } from "../../constants";
import { handleDownload } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileExcel,
  faSearchPlus,
} from "@fortawesome/free-solid-svg-icons";

export const RegistroPrefactura = ({
  identifier = "id",
  metadataTable,
  prefactura: initialValues,
  type,
  update,
  esProveedor,
  selectable,
  handleSelectionChange,
  selected,
  disabled = false,
  toast,
  setLoading,
  access,
}) => {
  const [prefactura, setPrefactura] = useState(initialValues);
  const [dialogComentarioVisible, setDialogComentarioVisible] = useState("");
  useEffect(() => setPrefactura(initialValues), [initialValues]);

  const botonera = () => {
    return (
      <>
        <Link to={`/auditoria/Prefacturas/${prefactura.id}`}>
          <button
            key="verAuditoria"
            className="action audit"
            title="Ver auditoría"
            disabled={!access?.Auditoria?.Acceso}
          >
            <FontAwesomeIcon icon={faSearchPlus} size="lg" />
          </button>
        </Link>
        {type !== "autorizaciones" && (
          <>
            <button
              key="exportarPrefacturaPdf"
              className="action export"
              title="Exportar pdf"
              onClick={() => exportarPrefactura("pdf")}
              disabled={!access?.Prefacturas?.Exportar}
            >
              <FontAwesomeIcon icon={faFilePdf} size="lg" />
            </button>
            <button
              key="exportarPrefacturaXlsx"
              className="action export"
              title="Exportar xlsx"
              onClick={() => exportarPrefactura("xlsx")}
              disabled={!access?.Prefacturas?.Exportar}
            >
              <FontAwesomeIcon icon={faFileExcel} size="lg" />
            </button>
          </>
        )}
      </>
    );
  };

  const handleChange = (key, value) => {
    const newValues = { ...prefactura };
    newValues[key] = value;
    setPrefactura(newValues);
  };

  const anularPrefactura = (message) => {
    const data = {};
    data.allPrefacturas = false;
    data.IdPrefactura = [prefactura.id];
    data.Comentario = `[Anulación] ${message}`;

    setLoading(true);
    API.anularPrefactura(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefactura Anulada");
          setDialogComentarioVisible("");
          update();
        } else if (res.Estado === "KO") {
          toast.error("No se pudo anular la prefactura: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al anular prefactura: " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        toast.error("Error al anular Prefactura:" + error.msg?.Message);
      });
  };

  const crearAutorizacion = () => {
    const data = {};
    data.Modulo = "Prefacturas";
    data.allPrefacturas = false;
    data.IdEntidad = [prefactura.id];

    setLoading(true);
    API.crearAutorizacion(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefactura a Autorizar");
          update();
        } else if (res.Estado === "KO") {
          toast.error(
            "No se pudo enviar la Prefactura a Autorizar: " + res.Mensaje
          );
          setLoading(false);
        } else {
          toast.info(
            "Error al enviar la Prefactura a Autorizar: " + res.Mensaje
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(
          "Error al enviar la Prefactura a Autorizar: " + error.msg?.Message
        );
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const borrarAutorizacion = () => {
    const data = {};
    data.Modulo = "Prefacturas";
    data.allPrefacturas = false;
    data.IdEntidad = [prefactura.id];

    setLoading(true);
    API.borrarAutorizacion(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefactura Cancelada");
          update();
        } else if (res.Estado === "KO") {
          toast.error("No se pudo Cancelar la prefactura: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al Cancelar la prefactura: " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al Cancelar la prefactura: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const darAutorizacion = () => {
    const data = {};
    data.allAutorizaciones = false;
    data.autorizaciones = [prefactura.idAutorizacion];

    setLoading(true);
    API.darAutorizacion(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefactura Autorizada");
          setDialogComentarioVisible("");
          update();
        } else if (res.Estado === "KO") {
          toast.error("No se pudo Autorizar la prefactura: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al Autorizar la prefactura: " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al Autorizar la prefactura: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const cancelarAutorizacion = (message) => {
    const data = {};
    data.allAutorizaciones = false;
    data.autorizaciones = [prefactura.idEntidad];
    data.comentario = `[Cancelación] ${message}`;

    setLoading(true);
    API.cancelarAutorizacion(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Autorización Cancelada");
          update();
        } else if (res.Estado === "KO") {
          toast.error("No se pudo Cancelar la autorización: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al Cancelar la autorización: " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al Cancelar la autorización: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const validarProveedor = (message) => {
    const data = {};
    data.IdEntidad = prefactura.id;
    data.Comentario = `[Validación] ${message}`;

    setLoading(true);
    API.validarPrefacturaProveedor(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefactura Autorizada");
          setDialogComentarioVisible("");
          update();
        } else if (res.Estado === "KO") {
          toast.error("No se pudo Autorizar la prefactura\n" + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("No se pudo Autorizar la prefactura\n" + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al Autorizar la prefactura\n" + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const rechazarProveedor = (message) => {
    const data = {};
    data.IdEntidad = prefactura.id;
    data.Comentario = `[Rechazo] ${message}`;

    setLoading(true);
    API.rechazarPrefacturaProveedor(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefactura Rechazada");
          update();
        } else if (res.Estado === "KO") {
          toast.error("No se pudo Rechazar la prefactura\n" + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("No se pudo Rechazar la prefactura\n" + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al Rechazar la prefactura\n" + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const exportarPrefactura = (ext) => {
    const data = {};
    data.TipoExportacion = ext;
    data.IdPrefactura = [prefactura.id];

    setLoading(true);
    API.exportarPrefactura(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefactura exportada");
          handleDownload(res);
        } else if (res.Estado === "INFO") {
          toast.info("No se pudo exportar la prefactura\n" + res.Mensaje);
        } else if (res.Estado === "WARNING") {
          toast.warning("No se pudo exportar la prefactura\n" + res.Mensaje);
        } else {
          toast.error("No se pudo exportar la prefactura\n" + res.Mensaje);
        }
      })
      .catch((error) => {
        toast.error("Error al exportar la prefactura\n" + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const generarOnBase = () => {
    const data = {};
    data.allPrefacturas = false;
    data.IdPrefactura = [prefactura.id];

    setLoading(true);
    API.generarOnBase(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Se ha generado el fichero OnBase correctamente");
          setDialogComentarioVisible("");
          update();
        } else if (res.Estado === "KO") {
          toast.error(res.Mensaje);
          setDialogComentarioVisible("");
          update();
        } else {
          toast.info("Error al generar el fichero OnBase: " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        toast.error("Error al generar el fichero OnBase:" + error.msg?.Message);
        setLoading(false);
      });
  };

  const actions = (state) => {
    switch (Number(state)) {
      case ESTADOS_PREFACTURA.CREADA:
        return (
          <>
            {botonera()}
            {(type === "prefacturas" || type === "detallePrefactura") && (
              <>
                <button
                  key="crearAutorizacion"
                  className="action forward"
                  title="Enviar a validación"
                  onClick={() => crearAutorizacion(prefactura.id)}
                  disabled={
                    !access?.Prefacturas?.Editar ||
                    prefactura.importePrefactura === 0 ||
                    esProveedor
                  }
                >
                  ✔
                </button>
                <button
                  key="anularPrefactura"
                  className="action backward"
                  title="Anular prefactura"
                  onClick={() => setDialogComentarioVisible("anular")}
                  disabled={!access?.Prefacturas?.Editar || esProveedor}
                >
                  ✘
                </button>
              </>
            )}
          </>
        );

      case ESTADOS_PREFACTURA.POR_VALIDAR:
        return (
          <>
            {botonera()}
            {(type === "prefacturas" || type === "detallePrefactura") && (
              <button
                key="borrarAutorizacion"
                className="action backward"
                title="Cancelar envío a validación"
                onClick={borrarAutorizacion}
                disabled={!access?.Prefacturas?.Eliminar || esProveedor}
              >
                ✘
              </button>
            )}
            {type === "autorizaciones" && (
              <>
                <button
                  key="darAutorizacion"
                  className="action forward"
                  title="Validar"
                  onClick={darAutorizacion}
                  disabled={
                    !access?.Autorizacion_Prefacturas?.Editar ||
                    !prefactura.Validable ||
                    esProveedor
                  }
                >
                  ✔
                </button>
                <button
                  key="cancelarAutorizacion"
                  className="action backward"
                  title="Anular"
                  onClick={() => setDialogComentarioVisible("cancelar")}
                  disabled={
                    !access?.Autorizacion_Prefacturas?.Eliminar ||
                    !prefactura.Cancelable ||
                    esProveedor
                  }
                >
                  ✘
                </button>
              </>
            )}
          </>
        );

      case ESTADOS_PREFACTURA.POR_VALIDAR_PROVEEDOR:
        return (
          <>
            {botonera()}
            {(type === "prefacturas" || type === "detallePrefactura") && (
              <>
                <button
                  key="validarProveedor"
                  className="action forward"
                  title="Validar"
                  onClick={() => setDialogComentarioVisible("validarProveedor")}
                  disabled={
                    !esProveedor && !access?.Prefacturas?.GestionProveedor
                  }
                >
                  ✔
                </button>
                <button
                  key="rechazarProveedor"
                  className="action backward"
                  title="Rechazar"
                  onClick={() =>
                    setDialogComentarioVisible("rechazarProveedor")
                  }
                  disabled={
                    !esProveedor && !access?.Prefacturas?.GestionProveedor
                  }
                >
                  ✘
                </button>
              </>
            )}
          </>
        );

      case ESTADOS_PREFACTURA.VALIDADA_PARCIAL:
        return (
          <>
            {botonera()}
            {type === "autorizaciones" && (
              <>
                <button
                  key="darAutorizacion2"
                  className="action forward"
                  title="Validar"
                  onClick={darAutorizacion}
                  disabled={
                    !access?.Autorizacion_Prefacturas?.Editar ||
                    !prefactura.Validable ||
                    esProveedor
                  }
                >
                  ✔
                </button>
                <button
                  key="cancelarAutorizacion2"
                  className="action backward"
                  title="Anular"
                  onClick={() => setDialogComentarioVisible("cancelar")}
                  disabled={
                    !access?.Autorizacion_Prefacturas?.Eliminar ||
                    !prefactura.Cancelable ||
                    esProveedor
                  }
                >
                  ✘
                </button>
              </>
            )}
          </>
        );

      case ESTADOS_PREFACTURA.VALIDADA:
        return (
          <>
            {botonera()}
            {(type === "prefacturas" || type === "detallePrefactura") && (
              <>
                <button
                  key="generarOnBase"
                  className="action forward"
                  title="Generar OnBase"
                  onClick={generarOnBase}
                  disabled={!access?.Prefacturas?.Exportar || esProveedor}
                >
                  OB
                </button>
              </>
            )}
            {type === "autorizaciones" && (
              <>
                <button
                  key="darAutorizacion3"
                  className="action forward"
                  title="Validar"
                  disabled={true}
                >
                  ✔
                </button>
                <button
                  key="anularAutorizacion"
                  className="action backward"
                  title="Anular"
                  onClick={() => setDialogComentarioVisible("cancelar")}
                  disabled={
                    !access?.Autorizacion_Prefacturas?.Eliminar ||
                    !prefactura.Cancelable ||
                    esProveedor
                  }
                >
                  ✘
                </button>
              </>
            )}
          </>
        );

      default:
        return botonera();
    }
  };

  return (
    <>
      <S.Tr deleted={prefactura.eliminado}>
        {selectable && (
          <S.Checkbox>
            <S.InputCheckbox
              id={prefactura[identifier]}
              type="checkbox"
              checked={selected}
              onChange={(e) =>
                handleSelectionChange(prefactura[identifier], e.target.checked)
              }
              disabled={disabled}
            />
          </S.Checkbox>
        )}
        {type !== "detallePrefactura" && (
          <S.Td>
            <Link to={`/prefacturas/${prefactura.id}`}>
              <button title="Ver detalle" className="edit">
                👁
              </button>
            </Link>
          </S.Td>
        )}
        {metadataTable.map((field) => {
          const fieldType = field.tipo;
          const idField = field.nombreCampo;
          const value = prefactura[field.nombreCampo];
          const editable = false;
          const required = field.requerido;

          switch (fieldType) {
            case "text":
            default:
              return (
                <S.Td key={idField} title={value}>
                  <FieldText
                    id={idField}
                    value={value || ""}
                    handleChange={handleChange}
                    readOnly={!editable}
                    required={required}
                  />
                </S.Td>
              );

            case "int":
            case "float":
              return (
                <S.Td key={idField} title={value}>
                  <FieldNumber
                    id={idField}
                    value={value || 0}
                    empty={value === "" || value === null}
                    handleChange={handleChange}
                    type={fieldType}
                    readOnly={!editable}
                    required={required}
                  />
                </S.Td>
              );

            case "date":
              return (
                <S.Td key={idField} title={value}>
                  <FieldDate
                    id={idField}
                    value={value?.split("T")[0] || ""}
                    handleChange={handleChange}
                    readOnly={!editable}
                    required={required}
                  />
                </S.Td>
              );
          }
        })}
        <S.Td>
          <S.Buttons>{actions(prefactura.idEstado)}</S.Buttons>
          {dialogComentarioVisible === "anular" && (
            <Dialog
              type="input"
              message="Comentario Anulación"
              labelConfirm="Añadir Comentario"
              confirm={anularPrefactura}
              cancel={() => setDialogComentarioVisible("")}
            />
          )}
          {dialogComentarioVisible === "cancelar" && (
            <Dialog
              type="input"
              message="Comentario Cancelación"
              labelConfirm="Añadir Comentario"
              confirm={cancelarAutorizacion}
              cancel={() => setDialogComentarioVisible("")}
            />
          )}
          {dialogComentarioVisible === "validarProveedor" && (
            <Dialog
              type="input"
              message="Comentario Validación"
              labelConfirm="Validar"
              confirm={validarProveedor}
              cancel={() => setDialogComentarioVisible("")}
            />
          )}
          {dialogComentarioVisible === "rechazarProveedor" && (
            <Dialog
              type="input"
              message="Comentario Rechazo"
              labelConfirm="Rechazar"
              confirm={rechazarProveedor}
              cancel={() => setDialogComentarioVisible("")}
            />
          )}
        </S.Td>
      </S.Tr>
    </>
  );
};

RegistroPrefactura.propTypes = {
  metadataTable: PropTypes.array,
  prefactura: PropTypes.object,
  type: PropTypes.string,
  update: PropTypes.func,
  esProveedor: PropTypes.bool,
  selectable: PropTypes.bool,
  handleSelectionChange: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  toast: PropTypes.func,
  setLoading: PropTypes.func,
  access: PropTypes.object,
};
