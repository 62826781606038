import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Button = styled.button`
  font-size: ${(props) => props.fontSize || "0.75rem"};
  font-weight: ${(props) => props.fontWeight || 600};
  margin: ${(props) => (props.margin === null ? "0.5rem" : props.margin)};
  padding: ${(props) =>
    props.padding === null ? "0rem 0.5rem" : props.padding};
  height: ${(props) => props.height || "1.75rem"};
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderRadius || "0.2rem"};
  cursor: ${(props) => props.cursor || "pointer"};
  color: ${(props) => props.color || COLORES.BUTTON_TEXT_DEFAULT};
  background-color: ${(props) =>
    props.backgroundColor || COLORES.BUTTON_BACKGROUND_DEFAULT};

  :disabled {
    background-color: ${COLORES.BACKGROUND_DISABLED};
    border: none;
    cursor: default;
  }
`;
