import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { FieldList } from "../../components/FieldList";
import { FieldListSearch } from "../../components/FieldListSearch";
import { FieldText } from "../../components/FieldText";
import { FieldDate } from "../../components/FieldDate";
import { RegistroPrefactura } from "../../components/RegistroPrefactura";
import { Sort } from "../../components/Sort";
import { handleDownload } from "../../utils";
import { Dialog } from "../../components/Dialog";
import { ESTADOS_PREFACTURA, PREFACTURAS_POR_PAGINA } from "../../constants";
import { usePermissions } from "../../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBroom,
  faCheckDouble,
  faFileArchive,
} from "@fortawesome/free-solid-svg-icons";

export const Prefacturas = () => {
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [prefacturas, setPrefacturas] = useState([]);
  const [metadataTable, setMetadataTable] = useState([]);
  const [metadataFilters, setMetadataFilters] = useState([]);
  const [listas, setListas] = useState({});
  const [filters, setFilters] = useState({});
  const [nextPage, setNextPage] = useState(0);
  const [showAnnulled, setShowAnnulled] = useState(false);
  const [sortActive, setSortActive] = useState({});
  const [esProveedor, setEsProveedor] = useState(false);
  const [selectedPrefacturas, setSelectedPrefacturas] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedAllFiltered, setSelectedAllFiltered] = useState(false);
  const [triggerGetPrefacturas, setTriggerGetPrefacturas] = useState(true);
  const [dialogComentarioVisible, setDialogComentarioVisible] = useState("");

  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({ Modulo: "Prefacturas", TipoDefinicion: "grilla" })
      .then((res) => isMounted && setMetadataTable(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerFiltrosBusqueda("Prefacturas")
      .then((res) => isMounted && setMetadataFilters(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerListas("Prefacturas")
      .then((res) => isMounted && setListas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    setAllSelected(
      prefacturas.length > 0 &&
        prefacturas.every((p) =>
          selectedPrefacturas.some((sP) => sP.id === p.id)
        )
    );
  }, [prefacturas, selectedPrefacturas]);

  const getPrefacturas = useCallback(
    (page = 1) => {
      const data = {};
      data.Paginacion = getPagination(page);
      data.MostrarEliminados = showAnnulled;
      if (Object.keys(sortActive).length > 0) data.Ordenacion = sortActive;
      if (Object.keys(filters).length > 0)
        Object.entries(filters).forEach(([nameFilter, valueFilter]) => {
          data[nameFilter] = valueFilter.Valor;
        });

      setLoading(true);
      API.obtenerPrefacturas(data)
        .then((res) => {
          if (page === 1) setPrefacturas(res.Registros);
          else setPrefacturas([...prefacturas, ...res.Registros]);

          if (res.Registros?.length < PREFACTURAS_POR_PAGINA) setNextPage(0);
          else setNextPage(page + 1);

          setEsProveedor(res.EsProveedor);
        })
        .catch((error) => {
          if (error.code !== 401)
            toast.error("Error al obtener Prefacturas\n" + error.msg?.Message);
          API.DEVELOP && console.log(error);
        })
        .finally(() => setLoading(false));

      setTriggerGetPrefacturas(false);
    },
    [filters, prefacturas, showAnnulled, sortActive]
  );

  useEffect(() => {
    let isMounted = true;
    isMounted && triggerGetPrefacturas && getPrefacturas();
    return () => (isMounted = false);
  }, [triggerGetPrefacturas, getPrefacturas]);

  const getPagination = (page) => ({
    Pagina: page,
    CantidadRegistros: PREFACTURAS_POR_PAGINA,
  });

  const handleChangeFilter = (id, value) => {
    const filtrosHijos = metadataFilters.filter(
      (filter) => filter.FiltroPadre === id
    );

    const newFilters = { ...filters };
    if (typeof value === "string") {
      if (value === "") delete newFilters[id];
      else {
        newFilters[id] = { Valor: value };
        filtrosHijos.forEach(
          (filter) => delete newFilters[filter.NombreFiltro]
        );
      }
    } else {
      if (Object.keys(value).length === 0) delete newFilters[id];
      else {
        newFilters[id] = value;
        filtrosHijos.forEach(
          (filter) => delete newFilters[filter.NombreFiltro]
        );
      }
    }
    setSelectedPrefacturas([]);
    setSelectedAllFiltered(false);
    setFilters(newFilters);
    setTriggerGetPrefacturas(true);
  };

  const sort = (field, value) => {
    setSortActive({
      IdCampo: field,
      Orden: value,
    });
    setTriggerGetPrefacturas(true);
  };

  const handleSelectionChange = (id, checked) => {
    let newSelectedPrefacturas = [...selectedPrefacturas];
    if (checked) {
      const { id: idPrefactura, idEstado } = prefacturas.find(
        (p) => p.id === id
      );
      const prefacturaSelectedProps = { id: idPrefactura, idEstado };
      newSelectedPrefacturas.push(prefacturaSelectedProps);
    } else {
      newSelectedPrefacturas = newSelectedPrefacturas.filter(
        (p) => p.id !== id
      );
    }
    setSelectedPrefacturas(newSelectedPrefacturas);
  };

  const toggleAll = () => {
    if (allSelected) {
      setSelectedPrefacturas((prevSelected) =>
        prevSelected.filter((sP) => !prefacturas.some((p) => p.id === sP.id))
      );
    } else {
      const newSelectedPrefacturas = structuredClone(selectedPrefacturas);
      newSelectedPrefacturas.push(
        ...prefacturas
          .filter((p) => !selectedPrefacturas.some((sP) => sP.id === p.id))
          .map((p) => ({ id: p.id, idEstado: p.idEstado }))
      );
      setSelectedPrefacturas(newSelectedPrefacturas);
    }
  };

  //region Acciones Masivas
  const exportarPrefacturasZip = () => {
    const data = {};
    data.TipoExportacion = "zip";
    data.IdPrefactura = selectedPrefacturas.map((sP) => sP.id);

    setLoading(true);
    API.exportarPrefactura(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefactura exportada");
          handleDownload(res);
        } else if (res.Estado === "INFO") {
          toast.info("No se pudo exportar las prefacturas\n" + res.Mensaje);
        } else if (res.Estado === "WARNING") {
          toast.warning("No se pudo exportar las prefacturas\n" + res.Mensaje);
        } else {
          toast.error("No se pudo exportar las prefacturas\n" + res.Mensaje);
        }
      })
      .catch((error) => {
        toast.error("Error al exportar las prefacturas\n" + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const crearAutorizacionMasiva = () => {
    const data = {};
    data.Modulo = "Prefacturas";
    data.allPrefacturas = selectedAllFiltered;
    if (selectedAllFiltered) {
      const parsedFilters = {};
      for (const key in filters) {
        if (filters[key].hasOwnProperty("Valor")) {
          parsedFilters[key] = filters[key].Valor;
        }
      }
      data.Filtros = parsedFilters;
    } else {
      data.IdEntidad = selectedPrefacturas.map((sP) => sP.id);
    }

    setLoading(true);
    API.crearAutorizacion(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefactura a Autorizar");
          setTriggerGetPrefacturas(true);
        } else if (res.Estado === "KO") {
          toast.error(
            "No se pudo enviar las prefacturas a autorizar\n" + res.Mensaje
          );
          setLoading(false);
        } else {
          toast.info(
            "Error al enviar las prefacturas a autorizar\n" + res.Mensaje
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(
          "Error al enviar las prefacturas a autorizar\n" + error.msg?.Message
        );
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const anularPrefacturaMasiva = (message) => {
    const data = {};
    data.allPrefacturas = selectedAllFiltered;
    data.comentario = `[Anulación] ${message}`;
    if (selectedAllFiltered) {
      const parsedFilters = {};
      for (const key in filters) {
        if (filters[key].hasOwnProperty("Valor")) {
          parsedFilters[key] = filters[key].Valor;
        }
      }
      data.Filtros = parsedFilters;
    } else {
      data.IdPrefactura = selectedPrefacturas.map((sP) => sP.id);
    }

    setLoading(true);
    API.anularPrefactura(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefacturas anuladas");
          setDialogComentarioVisible("");
          setTriggerGetPrefacturas(true);
        } else if (res.Estado === "KO") {
          toast.error("No se pudo anular las prefacturas\n" + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al anular prefacturas\n" + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        toast.error("Error al anular prefacturas\n" + error.msg?.Message);
      });
  };

  const borrarAutorizacionMasiva = () => {
    const data = {};
    data.allPrefacturas = selectedAllFiltered;
    data.Modulo = "Prefacturas";
    if (selectedAllFiltered) {
      const parsedFilters = {};
      for (const key in filters) {
        if (filters[key].hasOwnProperty("Valor")) {
          parsedFilters[key] = filters[key].Valor;
        }
      }
      data.Filtros = parsedFilters;
    } else {
      data.IdEntidad = selectedPrefacturas.map((sP) => sP.id);
    }

    setLoading(true);
    API.borrarAutorizacion(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefacturas canceladas");
          setTriggerGetPrefacturas(true);
        } else if (res.Estado === "KO") {
          toast.error("No se pudo cancelar las prefacturas\n" + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al cancelar las prefacturas\n" + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al cancelar las prefacturas\n" + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const generarOnBaseMasiva = () => {
    const data = {};
    data.allPrefacturas = selectedAllFiltered;
    if (selectedAllFiltered) {
      const parsedFilters = {};
      for (const key in filters) {
        if (filters[key].hasOwnProperty("Valor")) {
          parsedFilters[key] = filters[key].Valor;
        }
      }
      data.Filtros = parsedFilters;
    } else {
      data.IdPrefactura = selectedPrefacturas.map((sP) => sP.id);
    }

    setLoading(true);
    API.generarOnBase(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Se ha generado el fichero OnBase correctamente");
          setTriggerGetPrefacturas(true);
        } else if (res.Estado === "KO") {
          toast.error(res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al generar el fichero OnBase\n" + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        toast.error(
          "Error al generar el fichero OnBase\n" + error.msg?.Message
        );
        setLoading(false);
      });
  };

  const massiveBackwardAction = () => {
    if (
      filters.EstadoPrefactura?.Valor ===
        ESTADOS_PREFACTURA.CREADA.toString() ||
      (selectedPrefacturas.length > 0 &&
        selectedPrefacturas.every(
          (sP) => sP.idEstado === ESTADOS_PREFACTURA.CREADA
        ))
    ) {
      setDialogComentarioVisible("anular");
    }

    if (
      filters.EstadoPrefactura?.Valor ===
        ESTADOS_PREFACTURA.POR_VALIDAR.toString() ||
      (selectedPrefacturas.length > 0 &&
        selectedPrefacturas.every(
          (sP) => sP.idEstado === ESTADOS_PREFACTURA.POR_VALIDAR
        ))
    ) {
      borrarAutorizacionMasiva();
    }
  };

  const getBackwardButtonTitle = () => {
    if (
      filters.EstadoPrefactura?.Valor === ESTADOS_PREFACTURA.CREADA.toString()
    )
      return "Anular prefactura";

    if (
      filters.EstadoPrefactura?.Valor ===
      ESTADOS_PREFACTURA.POR_VALIDAR.toString()
    )
      return "Cancelar envío a validación";

    if (selectedPrefacturas.length === 0)
      return "Anular prefactura | Cancelar envío a validación";

    if (
      selectedPrefacturas.every(
        (sP) => sP.idEstado === ESTADOS_PREFACTURA.CREADA
      )
    )
      return "Anular prefactura";

    if (
      selectedPrefacturas.every(
        (sP) => sP.idEstado === ESTADOS_PREFACTURA.POR_VALIDAR
      )
    )
      return "Cancelar envío a validación";

    return "Anular prefactura | Cancelar envío a validación";
  };

  const getBackwardButtonDisabled = () => {
    if (selectedAllFiltered) {
      return (
        filters.EstadoPrefactura?.Valor !==
          ESTADOS_PREFACTURA.CREADA.toString() &&
        filters.EstadoPrefactura?.Valor !==
          ESTADOS_PREFACTURA.POR_VALIDAR.toString()
      );
    } else {
      return (
        selectedPrefacturas.length === 0 ||
        !(
          selectedPrefacturas.every(
            (sP) => sP.idEstado === ESTADOS_PREFACTURA.CREADA
          ) ||
          selectedPrefacturas.every(
            (sP) => sP.idEstado === ESTADOS_PREFACTURA.POR_VALIDAR
          )
        )
      );
    }
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.Filters count={metadataFilters.length}>
        {metadataFilters.map((filter) => {
          const lista = listas[filter.NombreFiltro];
          const nombreFiltro = filter.NombreFiltro;
          const currentOption = filters[nombreFiltro]
            ? {
                value: filters[nombreFiltro].Valor,
                label: filters[nombreFiltro].Etiqueta,
              }
            : { value: "" };

          switch (filter.Tipo) {
            case "text":
              return (
                <FieldText
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro || ""}
                  label={filter.Etiqueta}
                  value={filters[filter.NombreFiltro]?.Valor || ""}
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            case "date":
              return (
                <FieldDate
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro}
                  label={filter.Etiqueta}
                  value={
                    filters[filter.NombreFiltro]?.Valor.split("T")[0] || ""
                  }
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            case "enum":
              const options = lista?.Valores.map((option) => ({
                value: option.Valor,
                label: option.Etiqueta,
              }));

              return (
                <FieldList
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro || ""}
                  type="enum"
                  label={filter.Etiqueta}
                  currentOption={currentOption}
                  options={options}
                  handleChange={handleChangeFilter}
                  canBeEmpty={true}
                  readOnly={false}
                  required={false}
                />
              );

            case "relate":
              return (
                <FieldListSearch
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro || ""}
                  label={filter.Etiqueta}
                  listName={filter.NombreFiltro || ""}
                  currentOption={currentOption}
                  handleChange={handleChangeFilter}
                  canBeEmpty={true}
                  readOnly={false}
                  required={false}
                  parent={filter.FiltroPadre}
                  idParent={
                    filters[
                      metadataFilters.find(
                        (f) => f.NombreFiltro === filter.FiltroPadre
                      )?.IdFiltro
                    ]?.Valor
                  }
                />
              );

            default:
              return null;
          }
        })}
      </S.Filters>

      {dialogComentarioVisible === "anular" && (
        <Dialog
          type="input"
          message="Comentario Anulación"
          labelConfirm="Añadir Comentario"
          confirm={anularPrefacturaMasiva}
          cancel={() => setDialogComentarioVisible("")}
        />
      )}
      <S.ActionBar>
        <S.Buttons>
          <b>
            {selectedAllFiltered
              ? "Todas los prefacturas filtradas"
              : selectedPrefacturas.length + " prefacturas seleccionadas"}
          </b>
          <button
            id="cleanSelectedIds"
            title="Limpia toda la selección"
            disabled={selectedPrefacturas.length === 0 && !selectedAllFiltered}
            onClick={() => {
              setSelectedPrefacturas([]);
              setSelectedAllFiltered(false);
            }}
          >
            <FontAwesomeIcon icon={faBroom} size="lg" pull="left" />
            Limpiar selección
          </button>
          <button
            id="selectAllByArea"
            title={`${
              selectedAllFiltered
                ? "Deselecciona todas las prefacturas filtradas (incluidas las no visibles)"
                : "Selecciona todas las prefacturas filtradas (incluidas las no visibles), se requiere filtro 'Estado'"
            }`}
            disabled={
              !filters.EstadoPrefactura ||
              prefacturas.length === 0 ||
              showAnnulled
            }
            onClick={() => setSelectedAllFiltered(!selectedAllFiltered)}
          >
            <FontAwesomeIcon icon={faCheckDouble} size="lg" pull="left" />
            {selectedAllFiltered ? "Deseleccionar" : "Seleccionar"} todas las
            filtradas
          </button>
        </S.Buttons>

        <S.Buttons>
          <b>Acciones Masivas</b>
          <button
            id="exportSelectedIds"
            title="Exportar zip (pdf y xlsx)"
            disabled={selectedPrefacturas.length === 0 || selectedAllFiltered}
            onClick={exportarPrefacturasZip}
          >
            <FontAwesomeIcon icon={faFileArchive} size="lg" pull="left" />
          </button>

          <button
            key="darAutorizacion"
            className="forward"
            title="Enviar a validación"
            onClick={crearAutorizacionMasiva}
            disabled={
              selectedAllFiltered
                ? filters.EstadoPrefactura?.Valor !==
                  ESTADOS_PREFACTURA.CREADA.toString()
                : selectedPrefacturas.length === 0 ||
                  selectedPrefacturas.some(
                    (sP) => sP.idEstado !== ESTADOS_PREFACTURA.CREADA
                  )
            }
          >
            ✔
          </button>

          <button
            key="cancelarAutorizacion"
            className="backward"
            title={getBackwardButtonTitle()}
            onClick={massiveBackwardAction}
            disabled={getBackwardButtonDisabled()}
          >
            ✘
          </button>

          <button
            key="generarOnBase"
            className="action forward"
            title="Generar OnBase"
            onClick={generarOnBaseMasiva}
            disabled={
              selectedAllFiltered
                ? filters.EstadoPrefactura?.Valor !==
                  ESTADOS_PREFACTURA.VALIDADA.toString()
                : selectedPrefacturas.length === 0 ||
                  selectedPrefacturas.some(
                    (sP) => sP.idEstado !== ESTADOS_PREFACTURA.VALIDADA
                  )
            }
          >
            OB
          </button>
        </S.Buttons>

        <S.Buttons>
          <S.ShowDeleted>
            <input
              id="showAnnulled"
              type="checkbox"
              checked={showAnnulled}
              onChange={(e) => {
                setShowAnnulled(e.target.checked);
                setTriggerGetPrefacturas(true);
              }}
            />
            <label htmlFor="showAnnulled" className="checkbox">
              Mostrar Anuladas
            </label>
          </S.ShowDeleted>
        </S.Buttons>
      </S.ActionBar>

      <S.Table>
        <S.ColGroup selectable>
          <S.Col />
          <S.Col />
          {metadataTable.map((campo) => (
            <S.Col key={campo.id} />
          ))}
          <S.Col />
        </S.ColGroup>
        <S.Tr>
          <S.Th>
            <S.Checkbox>
              <S.InputCheckbox
                id="allSelected"
                type="checkbox"
                checked={allSelected || selectedAllFiltered}
                onChange={toggleAll}
                disabled={selectedAllFiltered}
              />
            </S.Checkbox>
          </S.Th>
          <S.Th />
          {metadataTable.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              <S.HeaderColumn>
                <span>{campo.etiqueta}</span>
                {campo.ordenable && (
                  <Sort
                    field={campo.nombreCampo}
                    sortActive={sortActive}
                    onClick={(value) => sort(campo.nombreCampo, value)}
                  />
                )}
              </S.HeaderColumn>
            </S.Th>
          ))}
          <S.Th>Acciones</S.Th>
        </S.Tr>
        {prefacturas.map((prefactura) => (
          <RegistroPrefactura
            key={prefactura.id}
            metadataTable={metadataTable}
            prefactura={prefactura}
            listas={listas}
            type="prefacturas"
            update={() => setTriggerGetPrefacturas(true)}
            esProveedor={esProveedor}
            selectable={true}
            handleSelectionChange={handleSelectionChange}
            selected={
              selectedPrefacturas.some(
                (idPrefactura) => idPrefactura.id === prefactura.id
              ) || selectedAllFiltered
            }
            disabled={selectedAllFiltered}
            toast={toast}
            setLoading={setLoading}
            access={permissions}
          />
        ))}
      </S.Table>
      <S.Pagination>
        <button
          disabled={nextPage === 0}
          onClick={() => getPrefacturas(nextPage)}
        >
          {nextPage === 0
            ? "No hay más prefacturas"
            : "Mostrar más prefacturas..."}
        </button>
      </S.Pagination>
    </S.Container>
  );
};

Prefacturas.propTypes = {
  access: PropTypes.object,
};
