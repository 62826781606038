import React, { useState, useEffect, useCallback } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { FieldText } from "../../components/FieldText";
import { FieldDate } from "../../components/FieldDate";
import { FieldList } from "../../components/FieldList";
import { FieldListSearch } from "../../components/FieldListSearch";
import { RegistroMovimiento } from "../../components/RegistroMovimiento";
import { Sort } from "../../components/Sort";
import { MOVIMIENTOS_POR_PAGINA } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileDownload,
  faUpload,
  faTrash,
  faPlusCircle,
  faFileAlt,
  faBroom,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "../../components/Dialog";
import { DialogCustom } from "../../components/DialogCustom";
import { DialogGeneratePrefactura } from "../../components/DialogGeneratePrefactura";
import { handleDownload } from "../../utils";
import { usePermissions } from "../../context";

export const Movimientos = () => {
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [movimientos, setMovimientos] = useState([]);
  const [fileList, setfileList] = useState([]);
  const [metadataTable, setMetadataTable] = useState([]);
  const [metadataFilters, setMetadataFilters] = useState([]);
  const [listas, setListas] = useState({});
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(-1);
  const [noRecords, setNoRecords] = useState(false);
  const [selectedMovimientos, setSelectedMovimientos] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [sortActive, setSortActive] = useState({});
  const [dialogGenerarPrefacturaVisible, setDialogGenerarPrefacturaVisible] =
    useState(false);
  const [dialogEliminarPrefacturaVisible, setDialogEliminarPrefacturaVisible] =
    useState(false);
  const [dialogValidacionDuplicados, setDialogValidacionDuplicados] =
    useState(false);
  const [dialogExcluirVendidos, setDialogExcluirVendidos] = useState(false);
  const [dialogGenerarPrefactura, setDialogGenerarPrefactura] = useState(false);
  const [triggerGetMovimientos, setTriggerGetMovimientos] = useState(true);
  const [filenameSubirMovimientos, setFilenameSubirMovimientos] = useState("");
  const [base64SubirMovimientos, setBase64SubirMovimientos] = useState("");
  const [excluirVendidos, setExcluirVendidos] = useState(false);
  const [recordsByPage, setRecordsByPage] = useState(MOVIMIENTOS_POR_PAGINA);
  const [selectedAllByArea, setSelectedAllByArea] = useState(false);

  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({ Modulo: "Movimientos", TipoDefinicion: "grilla" })
      .then((res) => isMounted && setMetadataTable(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerFiltrosBusqueda("Movimientos")
      .then((res) => isMounted && setMetadataFilters(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerListas("Movimientos")
      .then((res) => isMounted && setListas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    setAllSelected(
      movimientos.length > 0 &&
        movimientos.every((mov) =>
          selectedMovimientos.some((sMov) => sMov.id === mov.id)
        )
    );
  }, [movimientos, selectedMovimientos]);

  useEffect(() => {
    if (
      Object.keys(filters).length !== 1 ||
      !filters.hasOwnProperty("Area") ||
      showDeleted
    ) {
      setSelectedAllByArea(false);
    }
  }, [filters, showDeleted]);

  const getPagination = useCallback(
    (page) => ({
      Pagina: page,
      CantidadRegistros: recordsByPage,
    }),
    [recordsByPage]
  );

  const getMovimientos = useCallback(
    (page = 1) => {
      const data = {};
      data.Paginacion = getPagination(page);
      data.MostrarEliminados = showDeleted;
      if (Object.keys(sortActive).length > 0) data.Ordenacion = sortActive;
      if (Object.keys(filters).length > 0)
        Object.entries(filters).forEach(([nameFilter, valueFilter]) => {
          data[nameFilter] = valueFilter.Valor;
        });

      setLoading(true);
      API.obtenerMovimientos(data)
        .then((res) => {
          if (page === 1 || res.length > 0) {
            if (res.length < recordsByPage) setLastPage(page);
            setMovimientos(res);
            setCurrentPage(page);

            if (page === 1 && res.length === 0) setNoRecords(true);
          } else {
            // caso en que los movimientos sean múltiplo exacto de recordsByPage
            // y por lo tanto al darle siguiente se retornen 0 registros
            setLastPage(page - 1);
          }
        })
        .catch((error) => {
          if (error.code !== 401)
            toast.error("Error al obtener Gastos\n" + error.msg?.Message);
          API.DEVELOP && console.log(error);
        })
        .finally(() => setLoading(false));

      setTriggerGetMovimientos(false);
    },
    [filters, showDeleted, sortActive, getPagination, recordsByPage]
  );

  useEffect(() => {
    if (triggerGetMovimientos) {
      setLastPage(-1);
      setNoRecords(false);
      getMovimientos();
    }
  }, [triggerGetMovimientos, getMovimientos]);

  const handleChangeFilter = (id, value) => {
    const filtrosHijos = metadataFilters.filter(
      (filter) => filter.FiltroPadre === id
    );

    const newFilters = { ...filters };
    if (typeof value === "string") {
      if (value === "") delete newFilters[id];
      else {
        newFilters[id] = { Valor: value };
        filtrosHijos.forEach(
          (filter) => delete newFilters[filter.NombreFiltro]
        );
      }
    } else {
      if (Object.keys(value).length === 0) delete newFilters[id];
      else {
        newFilters[id] = value;
        filtrosHijos.forEach(
          (filter) => delete newFilters[filter.NombreFiltro]
        );
      }
    }

    setSelectedMovimientos([]);
    setAllSelected(false);
    setFilters(newFilters);
    setTriggerGetMovimientos(true);
  };

  const generarPrefacturaArchivo = () => {
    setLoading(true);

    API.traerArchivosMovimientos()
      .then((res) => {
        setLoading(false);
        setfileList(res);
        setDialogGenerarPrefacturaVisible(true);
      })
      .catch((error) => {
        toast.error("Error al cargar Gastos: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const eliminarArchivo = () => {
    setLoading(true);

    API.traerArchivosMovimientos()
      .then((res) => {
        setLoading(false);
        setfileList(res);
        setDialogEliminarPrefacturaVisible(true);
      })
      .catch((error) => {
        toast.error("Error al cargar Gastos: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const uploadMovimientos = (e) => {
    setLoading(true);
    let filename;
    let base64;

    if (e.target.files.length === 0) setLoading(false);
    Array.from(e.target.files).forEach((archivo) => {
      var reader = new FileReader();
      reader.readAsDataURL(archivo);
      reader.onloadend = function () {
        base64 = reader.result.replace(/^data:.+;base64,/, "");
        filename = archivo.name;
        const data = {};
        data.ArchivoMovimientos = base64;
        data.NombreFichero = filename;
        data.ExcluirVendidos = excluirVendidos;
        API.existeGastosPromocion(data)
          .then((res) => {
            if (res.Estado === "OK") {
              if (res.Mensaje === "False") {
                API.validacionGastosDuplicados(data) ///
                  .then((res) => {
                    if (res === "True") {
                      subirMovimientos(data);
                    } else {
                      setLoading(false);
                      setBase64SubirMovimientos(base64);
                      setFilenameSubirMovimientos(archivo.name);
                      setExcluirVendidos(false);
                      setDialogValidacionDuplicados(true);
                    }
                  })
                  .catch((error) => {
                    toast.error(
                      "Error al cargar gastos\n" + error.msg?.Message
                    );
                    setLoading(false);
                    API.DEVELOP && console.log(error);
                  });
              } else {
                setLoading(false);
                setBase64SubirMovimientos(base64);
                setFilenameSubirMovimientos(archivo.name);
                setDialogExcluirVendidos(true);
              }
            } else {
              toast.error("Error al cargar gastos\n" + res.Mensaje);
              setLoading(false);
            }
          })
          .catch((error) => {
            toast.error(
              "Ha ocurrido un error al cargar Gastos: " + error.msg?.Message
            );
            setLoading(false);
            API.DEVELOP && console.log(error);
          });
      };
    });
  };

  const uploadMovimientosAfterExcluirVendido = (e) => {
    setDialogExcluirVendidos(false);
    setExcluirVendidos(e);
    setLoading(true);
    const data = {};
    data.ArchivoMovimientos = base64SubirMovimientos;
    data.NombreFichero = filenameSubirMovimientos;
    data.ExcluirVendidos = e;

    API.validacionGastosDuplicados(data)
      .then((res) => {
        setLoading(false);
        if (res === "True") {
          uploadMovimientosAfterValidation(e);
        } else {
          setDialogValidacionDuplicados(true);
        }
      })
      .catch((error) => {
        toast.error("Error al cargar Gastos: " + error.msg?.Message);
        setLoading(false);
        setBase64SubirMovimientos("");
        setFilenameSubirMovimientos("");
        setExcluirVendidos(false);
        API.DEVELOP && console.log(error);
      });
  };

  const uploadMovimientosAfterValidation = (e) => {
    setDialogValidacionDuplicados(false);
    setLoading(true);
    const data = {};
    data.ArchivoMovimientos = base64SubirMovimientos;
    data.NombreFichero = filenameSubirMovimientos;
    data.ExcluirVendidos = e || false;
    subirMovimientos(data);
  };

  const subirMovimientos = (data) => {
    API.subirMovimientos(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Gastos cargados\n" + (res.Mensaje || ""));
          setTriggerGetMovimientos(true);
        }

        if (res.Estado === "WARNING") {
          toast.warning(
            "No se cargaron los movimientos. Revise el archivo de errores para más detalles"
          );
          handleDownload(res);
        }

        if (res.Estado === "KO") {
          throw new Error(res?.Mensaje);
        }
      })
      .catch((error) => {
        toast.error("Error al cargar gastos\n" + (error.message || ""));
        API.DEVELOP && console.log("API.subirMovimientos->catch", error);
      })
      .finally(() => {
        setBase64SubirMovimientos("");
        setFilenameSubirMovimientos("");
        setExcluirVendidos(false);
        setLoading(false);
      });
  };

  const revisionPromociones = (e) => {
    setLoading(true);
    let filename;
    let base64;
    if (e.target.files.length === 0) return setLoading(false);
    Array.from(e.target.files).forEach((archivo) => {
      var reader = new FileReader();
      reader.readAsDataURL(archivo);
      reader.onloadend = function () {
        base64 = reader.result.replace(/^data:.+;base64,/, "");
        filename = archivo.name;

        const data = {};
        data.ArchivoMovimientos = base64;
        data.NombreFichero = filename;
        API.revisionPromociones(data)
          .then((res) => {
            if (res.Estado === "OK") {
              toast.success("Reparto de promociones completado");
              handleDownload(res);
            } else if (res.Estado === "KO") {
              toast.error(
                "No se repartieron las promociones. Revise el archivo de errores para más detalles"
              );
              handleDownload(res);
            } else {
              toast.error("No se repartieron las promociones");
            }
          })
          .catch((error) => {
            toast.error("Error al repartir Promociones\n" + error.msg?.Message);
            API.DEVELOP && console.log(error);
          })
          .finally(() => setLoading(false));
      };
    });
  };

  const extractProps = (record, propNames) => {
    const selectedProps = {};
    propNames.forEach((propName) => {
      if (record.hasOwnProperty(propName)) {
        selectedProps[propName] = record[propName];
      }
    });
    return selectedProps;
  };

  const handleSelectionChange = (id, checked) => {
    let newSelectedMovimientos = [...selectedMovimientos];
    if (checked) {
      const movimiento = movimientos.find((mov) => mov.id === id);
      const movimientoSelectedProps = extractProps(movimiento, [
        "id",
        "idArea",
        "idProveedor",
        "idSociedad",
        "idFiscalidad",
        "Area",
        "Proveedor",
        "Sociedad",
        "Fiscalidad",
        "Unidades",
        "CosteUnitario",
      ]);

      newSelectedMovimientos.push(movimientoSelectedProps);
    } else {
      newSelectedMovimientos = newSelectedMovimientos.filter(
        (mov) => mov.id !== id
      );
    }
    setSelectedMovimientos(newSelectedMovimientos);
  };

  const deleteMovimientos = () => {
    setLoading(true);
    API.eliminarMovimientos(selectedMovimientos.map((m) => m.id))
      .then((res) => {
        if (selectedMovimientos.length === 1) toast.success("Gasto Eliminado");
        else toast.success("Gastos Eliminados");
        setSelectedMovimientos([]);
        setAllSelected(false);
        setTriggerGetMovimientos(true);
      })
      .catch((error) => {
        toast.error("Error al eliminar el Gasto: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const recoveryMovimientos = () => {
    setLoading(true);
    API.recuperarMovimientos(selectedMovimientos.map((m) => m.id))
      .then((res) => {
        if (selectedMovimientos.length === 1) toast.success("Gasto Recuperado");
        else toast.success("Gastos Recuperados");
        setShowDeleted(false);
        setMovimientos([]);
        setTriggerGetMovimientos(true);
        setSelectedMovimientos([]);
        setAllSelected(false);
      })
      .catch((error) => {
        toast.error("Error al recuperar el Gasto: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const generarPrefactura = (rawData) => {
    const data = { MovimientosVinculados: rawData };

    setLoading(true);
    API.generarPrefactura(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefactura(s) generada(s)");
          setSelectedAllByArea(false);
          setSelectedMovimientos([]);
          setTriggerGetMovimientos(true);
          setDialogGenerarPrefactura(false);
        } else if (res.Estado === "KO") {
          toast.error("No se pudo generar la(s) prefactura(s)\n" + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al generar la(s) prefactura(s)\n" + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(
          "Error al generar la(s) prefactura(s)\n" + error.msg?.Message
        );
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const generarPlantillaCargarGastos = () => {
    setLoading(true);
    API.generarPlantillaCargarGastos()
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Plantilla generada");
          handleDownload(res);
        } else if (res.Estado === "INFO") {
          toast.info("No se pudo generar plantilla\n" + res.Mensaje);
        } else if (res.Estado === "WARNING") {
          toast.warning("No se pudo generar plantilla\n" + res.Mensaje);
        } else {
          toast.error("No se pudo generar plantilla\n" + res.Mensaje);
        }
      })
      .catch((error) => {
        toast.error("Error al generar plantilla\n" + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const sort = (field, value) => {
    setSortActive({
      IdCampo: field,
      Orden: value,
    });
    setTriggerGetMovimientos(true);
  };

  const toggleAll = () => {
    if (allSelected) {
      setSelectedMovimientos((prevSelected) =>
        prevSelected.filter(
          (sMov) => !movimientos.some((m) => m.id === sMov.id)
        )
      );
    } else {
      const newSelectedMovimientos = structuredClone(selectedMovimientos);
      newSelectedMovimientos.push(
        ...movimientos
          .filter((m) => !selectedMovimientos.some((sMov) => sMov.id === m.id))
          .map((movimiento) =>
            extractProps(movimiento, [
              "id",
              "idArea",
              "idProveedor",
              "idSociedad",
              "idFiscalidad",
              "Area",
              "Proveedor",
              "Sociedad",
              "Fiscalidad",
              "Unidades",
              "CosteUnitario",
            ])
          )
      );
      setSelectedMovimientos(newSelectedMovimientos);
    }
  };

  const cargarMovimientos = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.onchange = uploadMovimientos;
    input.disabledle = showDeleted;
    input.click();
  };

  const revisarPromociones = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.onchange = revisionPromociones;
    input.disabledle = showDeleted;
    input.click();
  };

  const addFilePrefactura = (idCarga) => {
    setLoading(true);
    let params = { IdCarga: idCarga };

    API.generarPrefacturaArchivo(params)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Prefactura Generada");
          setLoading(false);
          setDialogGenerarPrefacturaVisible(false);
          setTriggerGetMovimientos(true);
        } else if (res.Estado === "KO") {
          toast.error("No se pudo generar la prefactura: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al generar la prefactura: " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al generar la prefactura: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const removeFile = (idCarga) => {
    setLoading(true);
    let params = { IdCarga: idCarga };

    API.eliminarArchivoMovimientos(params)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Archivo Eliminado");
          setLoading(false);
          setDialogEliminarPrefacturaVisible(false);
          setTriggerGetMovimientos(true);
        } else if (res.Estado === "KO") {
          toast.error(
            "No se pudo eliminar el archivo seleccionado: " + res.Mensaje
          );
          setLoading(false);
        } else {
          toast.info(
            "Error al eliminar el archivo seleccionado: " + res.Mensaje
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al eliminar el archivo: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      {dialogGenerarPrefacturaVisible && (
        <DialogCustom
          objects={fileList}
          message="Generar Prefactura desde un archivo"
          labelConfirm="Generar Prefactura"
          confirm={addFilePrefactura}
          cancel={() => setDialogGenerarPrefacturaVisible(false)}
        />
      )}

      {dialogEliminarPrefacturaVisible && (
        <DialogCustom
          objects={fileList}
          message="Eliminar un archivo de gastos"
          labelConfirm="Eliminar archivo"
          confirm={removeFile}
          cancel={() => setDialogEliminarPrefacturaVisible(false)}
        />
      )}

      {dialogValidacionDuplicados && (
        <Dialog
          type="confirm"
          message="Va a cargar gastos duplicados en el sistema, ¿desea continuar?"
          labelConfirm="Continuar"
          confirm={() => uploadMovimientosAfterValidation(excluirVendidos)}
          cancel={() => setDialogValidacionDuplicados(false)}
        />
      )}

      {dialogExcluirVendidos && (
        <Dialog
          type="confirm"
          message="Se detectaron gastos de promocion. Indique por favor si quiere que el reparto excluya los activos vendidos anteriores al mes anterior al de la fecha del gasto?"
          labelConfirm="Excluir"
          confirm={() => uploadMovimientosAfterExcluirVendido(true)}
          cancel={() => uploadMovimientosAfterExcluirVendido(false)}
          labelCancel="NO Excluir"
        />
      )}

      {dialogGenerarPrefactura && (
        <DialogGeneratePrefactura
          selectedAllByArea={selectedAllByArea}
          areaSelected={filters.Area?.Valor}
          selectedMovimientos={selectedMovimientos}
          confirm={generarPrefactura}
          cancel={() => setDialogGenerarPrefactura(false)}
        />
      )}

      <S.Filters count={metadataFilters.length}>
        {metadataFilters.map((filter) => {
          const lista = listas[filter.NombreFiltro];
          const nombreFiltro = filter.NombreFiltro;
          const currentOption = filters[nombreFiltro]
            ? {
                value: filters[nombreFiltro].Valor,
                label: filters[nombreFiltro].Etiqueta,
              }
            : { value: "" };

          switch (filter.Tipo) {
            case "text":
              return (
                <FieldText
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro || ""}
                  label={filter.Etiqueta}
                  value={filters[filter.NombreFiltro]?.Valor || ""}
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            case "date":
              return (
                <FieldDate
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro}
                  label={filter.Etiqueta}
                  value={
                    filters[filter.NombreFiltro]?.Valor.split("T")[0] || ""
                  }
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            case "enum":
              const options = lista?.Valores.map((option) => ({
                value: option.Valor,
                label: option.Etiqueta,
              }));

              return (
                <FieldList
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro || ""}
                  type="enum"
                  label={filter.Etiqueta}
                  currentOption={currentOption}
                  options={options}
                  handleChange={handleChangeFilter}
                  canBeEmpty={true}
                  readOnly={false}
                  required={false}
                />
              );

            case "relate":
              return (
                <FieldListSearch
                  key={filter.IdFiltro}
                  id={filter.NombreFiltro || ""}
                  label={filter.Etiqueta}
                  listName={filter.NombreFiltro || ""}
                  currentOption={currentOption}
                  handleChange={handleChangeFilter}
                  canBeEmpty={true}
                  readOnly={false}
                  required={false}
                  parent={filter.FiltroPadre}
                  idParent={
                    filters[
                      metadataFilters.find(
                        (f) => f.NombreFiltro === filter.FiltroPadre
                      )?.IdFiltro
                    ]?.Valor
                  }
                />
              );

            default:
              return null;
          }
        })}
      </S.Filters>

      <S.Buttons>
        <button
          id="generarPlantillaCargarGastos"
          title="Descarga plantilla para la carga de gastos"
          disabled={!permissions?.Movimientos?.Editar || showDeleted}
          onClick={generarPlantillaCargarGastos}
        >
          <FontAwesomeIcon icon={faFileDownload} size="lg" pull="left" />
          Plantilla Cargar Gastos
        </button>

        <button
          id="uploadMovimientos"
          disabled={!permissions?.Movimientos?.Editar || showDeleted}
          onClick={cargarMovimientos}
        >
          <FontAwesomeIcon icon={faUpload} size="lg" pull="left" />
          Cargar Gastos
        </button>

        <button
          id="deleteMovimiento"
          disabled={
            !permissions?.Movimientos?.Eliminar ||
            showDeleted ||
            selectedMovimientos.length === 0 ||
            selectedAllByArea
          }
          onClick={deleteMovimientos}
        >
          <FontAwesomeIcon icon={faTrash} size="lg" pull="left" />
          Eliminar Seleccionados
        </button>

        <button
          id="deleteMovimientoArchivo"
          disabled={!permissions?.Movimientos?.Eliminar || showDeleted}
          onClick={eliminarArchivo}
        >
          <FontAwesomeIcon icon={faTrash} size="lg" pull="left" />
          Eliminar Archivos
        </button>

        <button
          id="generarPrefactura"
          title="Generar prefacturas con los gastos seleccionados"
          disabled={
            !permissions?.Prefacturas?.Editar ||
            showDeleted ||
            (selectedMovimientos.length === 0 && !selectedAllByArea)
          }
          onClick={() => setDialogGenerarPrefactura(true)}
        >
          <FontAwesomeIcon icon={faPlusCircle} size="lg" pull="left" />
          Generar Prefactura
        </button>

        <button
          id="generarPrefacturaArchivo"
          title="Generacion de Prefacturas a partir de archivos"
          disabled={!permissions?.Movimientos?.Editar || showDeleted}
          onClick={generarPrefacturaArchivo}
        >
          <FontAwesomeIcon icon={faPlusCircle} size="lg" pull="left" />
          Generar Prefactura Archivo
        </button>

        <button
          id="revisarPromociones"
          title="Procesa un fichero de promociones y devuelve el resultado (no carga Movimientos en la aplicación)"
          disabled={!permissions?.Movimientos?.Editar || showDeleted}
          onClick={revisarPromociones}
        >
          <FontAwesomeIcon icon={faFileAlt} size="lg" pull="left" />
          Reparto Promociones
        </button>

        <S.ShowDeleted>
          <input
            id="showDeleted"
            type="checkbox"
            checked={showDeleted}
            onChange={(e) => {
              setSelectedMovimientos([]);
              setShowDeleted(e.target.checked);
              setTriggerGetMovimientos(true);
            }}
          />
          <label htmlFor="showDeleted" className="checkbox">
            Mostrar Eliminados
          </label>
          {showDeleted && (
            <button
              disabled={
                !permissions?.Movimientos?.Editar ||
                !showDeleted ||
                selectedMovimientos.length === 0
              }
              onClick={recoveryMovimientos}
            >
              Recuperar Seleccionados
            </button>
          )}
        </S.ShowDeleted>
      </S.Buttons>

      <S.Buttons>
        <b>
          {selectedAllByArea
            ? "Todos los gastos del área seleccionados"
            : selectedMovimientos.length + " gastos seleccionados"}
        </b>
        <button
          id="cleanSelectedIds"
          title="Limpia toda la selección de elementos"
          disabled={selectedMovimientos.length === 0 && !selectedAllByArea}
          onClick={() => {
            setSelectedMovimientos([]);
            setSelectedAllByArea(false);
          }}
        >
          <FontAwesomeIcon icon={faBroom} size="lg" pull="left" />
          Limpiar selección
        </button>
        <button
          id="selectAllByArea"
          title={`${
            selectedAllByArea ? "Deselecciona" : "Selecciona"
          } todos los gastos del Área seleccionada (incluidos los no visibles)`}
          disabled={
            Object.keys(filters).length !== 1 ||
            !filters.hasOwnProperty("Area") ||
            movimientos.length === 0 ||
            showDeleted
          }
          onClick={() => setSelectedAllByArea(!selectedAllByArea)}
        >
          <FontAwesomeIcon icon={faCheckDouble} size="lg" pull="left" />
          {selectedAllByArea ? "Deseleccionar" : "Seleccionar"} todos los gastos
          del Área
        </button>
      </S.Buttons>

      <S.Table>
        <S.ColGroup>
          <S.Col />
          {metadataTable.map((campo) => (
            <S.Col key={campo.id} />
          ))}
          <S.Col />
        </S.ColGroup>
        <S.Tr>
          <S.Th>
            <S.Checkbox>
              <S.InputCheckbox
                id="allSelected"
                type="checkbox"
                checked={allSelected || selectedAllByArea}
                onChange={toggleAll}
                disabled={selectedAllByArea}
              />
            </S.Checkbox>
          </S.Th>
          {metadataTable.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              <S.HeaderColumn>
                <span>{campo.etiqueta}</span>
                {campo.ordenable && (
                  <Sort
                    field={campo.nombreCampo}
                    sortActive={sortActive}
                    onClick={(value) => sort(campo.nombreCampo, value)}
                  />
                )}
              </S.HeaderColumn>
            </S.Th>
          ))}
          <S.Th />
        </S.Tr>
        {movimientos.map((movimiento) => (
          <RegistroMovimiento
            key={movimiento.id}
            metadataTable={metadataTable}
            movimiento={movimiento}
            listas={listas}
            type="list"
            handleSelectionChange={handleSelectionChange}
            selected={
              selectedMovimientos.some((sMov) => sMov.id === movimiento.id) ||
              selectedAllByArea
            }
            disabled={selectedAllByArea}
            setLoading={setLoading}
            access={permissions}
          />
        ))}
      </S.Table>
      {noRecords && <div>No hay registros</div>}

      <S.Buttons>
        <button
          id="backPage"
          disabled={currentPage === 1}
          onClick={() => getMovimientos(currentPage - 1)}
        >
          Anterior
        </button>
        <b>Página {currentPage}</b>
        <button
          id="nextPage"
          disabled={currentPage === lastPage}
          onClick={() => getMovimientos(currentPage + 1)}
        >
          Siguiente
        </button>
        <label htmlFor="recordsByPage">Registros por página</label>
        <S.RecordsByPage
          id="recordsByPage"
          value={recordsByPage}
          onChange={(e) => {
            setRecordsByPage(e.target.value);
            setTriggerGetMovimientos(true);
          }}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </S.RecordsByPage>
      </S.Buttons>
    </S.Container>
  );
};
