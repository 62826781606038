import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FieldNumber = ({
  id,
  label,
  value: numericValue,
  empty,
  handleChange,
  type,
  readOnly,
  required,
  styles,
}) => {
  const [value, setValue] = useState("");
  useEffect(() => setValue(empty ? "" : numericValue), [empty, numericValue]);

  const editing = !readOnly;
  const numberOptions = {
    style: type === "currency" ? "currency" : "decimal",
    minimumFractionDigits: type === "int" ? 0 : 2,
    maximumFractionDigits: type === "int" ? 0 : 2,
    currency: "EUR",
  };

  return (
    <S.Container>
      {label && <S.Title>{label}</S.Title>}
      <S.InputContainer>
        <S.InputWrapper>
          {editing ? (
            <S.Input
              type="number"
              value={value}
              onChange={(e) =>
                handleChange(id, e.target.value ? Number(e.target.value) : "")
              }
              step={type === "int" ? 1 : 0.01}
              min="0"
              required={required}
              styles={styles}
            />
          ) : empty ? (
            ""
          ) : (
            <S.Text>
              {Number(value).toLocaleString("es-ES", numberOptions)}
            </S.Text>
          )}
        </S.InputWrapper>
        <input
          className="hidden"
          name={id}
          type="number"
          defaultValue={value}
        />
      </S.InputContainer>
    </S.Container>
  );
};

FieldNumber.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.number,
  empty: PropTypes.bool,
  handleChange: PropTypes.func,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};
