import styled, { css } from "styled-components/macro";
import { COLORES } from "../../constants";

export const Tr = styled.div`
  display: table-row;
  height: 2rem;

  background-color: ${(props) =>
    props.highlighted ? COLORES.HIGHLIGHTED : COLORES.BACKGROUND};

  :hover {
    background-color: ${COLORES.HIGHLIGHTED};
  }

  color: ${(props) => props.deleted && COLORES.DELETED};
`;

export const Td = styled.div`
  display: table-cell;
  vertical-align: middle;
  border: thin solid ${COLORES.LIGHT_BORDER};

  ${(props) =>
    props.type !== "enum" &&
    props.type !== "relate" &&
    css`
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}

  button {
    line-height: 1.5rem;
  }
`;

export const Buttons = styled.div`
  display: flex;

  button {
    flex-grow: 1;
    border: none;
    border-radius: 0.2rem;
    margin-left: 0.2rem;
    color: ${COLORES.BUTTON_TEXT_DEFAULT};
    width: 2rem;

    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
      cursor: default;
    }
  }

  button:last-of-type {
    margin-right: 0.2rem;
  }

  .action {
    font-weight: bold;
    font-size: 0.75rem;
  }

  .delete {
    background-color: ${COLORES.BACKWARD};
  }
`;
