import { toast } from "react-toastify";

export const handleDownload = async (res) => {
  const { FicheroData, FicheroMimeType, FicheroNombre } = res;

  try {
    // Obtener el archivo utilizando fetch
    const response = await fetch(
      `data:${FicheroMimeType};base64,${FicheroData}`
    );
    if (!response.ok) {
      throw new Error("Error al decodificar el archivo");
    }

    // Convertir la respuesta a un Blob
    const fileBlob = await response.blob();

    // Crear un objeto URL para el Blob
    const downloadUrl = URL.createObjectURL(fileBlob);

    // Crear un enlace de descarga y simular un clic en él
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", FicheroNombre);
    link.click();

    // Revocar la URL del objeto Blob
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Error al procesar descarga:", error);
    toast.error(`Error al procesar descarga\n${error.message || ""}`);
  }
};
