import React, { useEffect, useState, useRef } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { FieldText } from "../../components/FieldText";
import { RegistroUsuario } from "../../components/RegistroUsuario";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { USUARIOS_POR_PAGINA, DELAY_TEXT_FILTER } from "../../constants";
import { usePermissions } from "../../context";

export const Usuarios = () => {
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [metadataTable, setMetadataTable] = useState([]);
  const [metadataFilters, setMetadataFilters] = useState([]);
  const [filters, setFilters] = useState({});
  const [nextPage, setNextPage] = useState(0);
  const [triggerGetUsuarios, setTriggerGetUsuarios] = useState(true);
  const delayTimer = useRef();
  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({ Modulo: "Usuarios", TipoDefinicion: "grilla" })
      .then((res) => isMounted && setMetadataTable(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerFiltrosBusqueda("Usuarios")
      .then((res) => isMounted && setMetadataFilters(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, []);
  useEffect(() => {
    let isMounted = true;
    isMounted && triggerGetUsuarios && getUsuarios();
    return () => (isMounted = false);
  }, [triggerGetUsuarios]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUsuarios = (page = 1) => {
    setLoading(true);
    const data = {};
    data.Paginacion = getPagination(page);
    if (Object.keys(filters).length > 0)
      data.Filtros = Object.entries(filters).map((filter) => ({
        IdFiltro: filter[0],
        ValorFiltro: filter[1]?.Valor,
      }));

    API.obtenerUsuarios(data)
      .then((res) => {
        if (res) {
          if (page === 1) setUsuarios(res);
          else setUsuarios([...usuarios, ...res]);

          if (res.length < USUARIOS_POR_PAGINA) setNextPage(0);
          else setNextPage(page + 1);
        }
      })
      .catch((error) => {
        if (error.code !== 401)
          toast.error("Error al obtener Usuarios: " + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));

    setTriggerGetUsuarios(false);
  };

  const getPagination = (page = 1) => ({
    Pagina: page,
    CantidadRegistros: USUARIOS_POR_PAGINA,
  });

  const handleChangeFilter = (id, value) => {
    const newFilters = { ...filters };
    if (value === "") delete newFilters[id];
    else newFilters[id] = { Valor: value };

    setFilters(newFilters);
    API.defaultSource.cancel();
    clearTimeout(delayTimer.current);
    delayTimer.current = setTimeout(() => {
      setTriggerGetUsuarios(true);
    }, DELAY_TEXT_FILTER);
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.Filters count={metadataFilters.length}>
        {metadataFilters.map((filter) => {
          switch (filter.Tipo) {
            case "text":
              return (
                <FieldText
                  key={filter.IdFiltro}
                  id={filter.IdFiltro || ""}
                  label={filter.Etiqueta}
                  value={filters[filter.IdFiltro]?.Valor || ""}
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            default:
              return <div>Unknown</div>;
          }
        })}
      </S.Filters>
      <S.Buttons>
        <Link to={`/usuarios/create`}>
          <Button label={"Crear Nuevo Usuario"} />
        </Link>
      </S.Buttons>
      <S.Table>
        <S.ColGroup>
          <S.Col />
          {metadataTable.map((campo) => (
            <S.Col key={campo.id} />
          ))}
          <S.Col />
        </S.ColGroup>
        <S.Tr>
          <S.Th />
          {metadataTable.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              <S.HeaderColumn>
                <span>{campo.etiqueta}</span>
              </S.HeaderColumn>
            </S.Th>
          ))}
          <S.Th />
        </S.Tr>
        {usuarios.map((usuario) => (
          <RegistroUsuario
            key={usuario.Id}
            metadataTable={metadataTable}
            usuario={usuario}
            access={permissions}
            update={() => setTriggerGetUsuarios(true)}
            setLoading={setLoading}
            toast={toast}
          />
        ))}
      </S.Table>
      <S.Pagination>
        <button disabled={nextPage === 0} onClick={() => getUsuarios(nextPage)}>
          {nextPage === 0 ? "No hay más usuarios" : "Mostrar más usuarios..."}
        </button>
      </S.Pagination>
    </S.Container>
  );
};
