import * as S from "./styles";
import { METADATA_SUMMARY_TABLE } from "./constants";

export const SummaryTable = ({ totalStatusSummary }) => {
  const renderField = (type, value) => {
    switch (type) {
      case "boolean":
        return <input type="checkbox" checked={value} readOnly />;

      case "currency":
        return value.toLocaleString("es-ES", {
          style: "currency",
          currency: "EUR",
        });

      default:
        return value;
    }
  };

  return (
    <S.SummaryTable>
      <p>Resumen estados Total</p>
      {totalStatusSummary.length > 0 ? (
        <S.Table>
          <S.Tr header>
            {METADATA_SUMMARY_TABLE.map(({ key, label }) => (
              <S.Td key={key} header>
                {label}
              </S.Td>
            ))}
          </S.Tr>
          {totalStatusSummary.map((record, index) => (
            <S.Tr key={index} rowNum={index}>
              {METADATA_SUMMARY_TABLE.map(({ key, type }) => (
                <S.Td key={key}>{renderField(type, record[key])}</S.Td>
              ))}
            </S.Tr>
          ))}
        </S.Table>
      ) : (
        <S.Tr>
          <S.Td noData>No existen datos</S.Td>
        </S.Tr>
      )}
    </S.SummaryTable>
  );
};
