import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import AsyncSelect from "react-select/async";
import { COLORES } from "../../constants";

export const FieldListSearch = ({
  id,
  label,
  listName,
  currentOption,
  handleChange,
  canBeEmpty,
  readOnly,
  required,
  hiddenIndicators,
  parent,
  idParent,
}) => {
  const editing = !readOnly;

  const promiseOptions = (e) => {
    const data = {};
    data.Lista = listName;
    return new Promise((resolve) => {
      data.TextoBusqueda = e;
      if (idParent)
        data.ListaRelacional = {
          NombreLista: parent,
          IdEntidadRelacional: idParent,
        };
      API.obtenerLista(data)
        .then((res) => {
          const values = res.Valores;
          if (values.length > 0)
            return resolve([
              ...values.map((option) => ({
                value: option.Valor,
                label: option.Etiqueta,
              })),
            ]);
          else {
            return resolve([]);
          }
        })
        .catch((error) => API.DEVELOP && console.log(error));
    });
  };

  return (
    <S.Container>
      {label && <S.Title>{label}</S.Title>}
      <S.SelectContainer
        className={editing ? "visible" : "hidden"}
        hiddenIndicators={hiddenIndicators}
      >
        <AsyncSelect
          name={"id" + id}
          styles={customStyles}
          value={currentOption}
          defaultOptions={[
            {
              value: currentOption.value,
              label: "Buscar y seleccionar...",
              isDisabled: true,
            },
          ]}
          loadOptions={promiseOptions}
          onChange={(e) =>
            handleChange(id, e ? { Valor: e.value, Etiqueta: e.label } : {})
          }
          isClearable={canBeEmpty}
          noOptionsMessage={() => "No hay opciones"}
          loadingMessage={() => "Cargando..."}
          required={required}
        />
      </S.SelectContainer>
      <S.Text className={editing ? "hidden" : "visible"}>
        {currentOption?.label}
      </S.Text>
    </S.Container>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "1rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: state.isFocused ? COLORES.LIST_ACTIVE : COLORES.BORDER,
    },
    borderColor: state.isFocused ? COLORES.LIST_ACTIVE : COLORES.LIGHT_BORDER,
    backgroundColor: COLORES.BACKGROUND,
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: "5px",
  }),
  indicatorSeparator: (provided, state) => ({}),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    "&.indicatorContainer": {
      padding: "0 8px",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0 8px",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "0 8px",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "6px 12px",
    borderBottom: `1px solid ${COLORES.LIGHT_BORDER}`,
    backgroundColor: state.isSelected ? COLORES.LIST_ACTIVE : "white",
    "&:hover": {
      backgroundColor: state.isSelected
        ? COLORES.LIST_ACTIVE
        : COLORES.LIST_HOVER,
    },
    overflow: "hidden",
  }),
};

FieldListSearch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  listName: PropTypes.string,
  currentOption: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  canBeEmpty: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  hiddenIndicators: PropTypes.bool,
  parent: PropTypes.string,
  idParent: PropTypes.string,
};
