import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { Sort } from "../../components/Sort";
import { AUDITORIAS_POR_PAGINA } from "../../constants";
import { useParams } from "react-router";
import { RegistroAuditoria } from "../../components/RegistroAuditoria";
import { FieldText } from "../../components/FieldText";
import { FieldDate } from "../../components/FieldDate";
import { FieldList } from "../../components/FieldList";
import { FieldListSearch } from "../../components/FieldListSearch";

export const Auditoria = () => {
  const { modulo, idEntidad } = useParams();
  const [loading, setLoading] = useState(true);
  const [auditorias, setAuditorias] = useState([]);
  const [metadataTable, setMetadataTable] = useState([]);
  const [metadataFilters, setMetadataFilters] = useState([]);
  const [listas, setListas] = useState({});
  const [filters, setFilters] = useState({});
  const [nextPage, setNextPage] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [sortActive, setSortActive] = useState({});
  const [triggerGetAuditorias, setTriggerGetAuditorias] = useState(true);
  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({ Modulo: "Auditoria", TipoDefinicion: "grilla" })
      .then((res) => isMounted && setMetadataTable(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerFiltrosBusqueda("Auditoria")
      .then((res) => isMounted && setMetadataFilters(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerListas("Auditoria")
      .then((res) => isMounted && setListas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, []);
  useEffect(() => {
    let isMounted = true;
    isMounted && triggerGetAuditorias && getAuditorias();
    return () => (isMounted = false);
  }, [triggerGetAuditorias]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAuditorias = (page = 1) => {
    setLoading(true);
    const data = {};
    data.Paginacion = getPagination(page);
    if (Object.keys(filters).length > 0)
      data.Filtros = Object.entries(filters).map((filter) => ({
        IdFiltro: filter[0],
        ValorFiltro: filter[1].Valor,
      }));
    data.IdEntidad = idEntidad;
    data.ModuloAudit = modulo;
    API.obtenerAuditoria(data)
      .then((res) => {
        if (page === 1) setAuditorias(res);
        else setAuditorias([...auditorias, ...res]);

        if (res.length < AUDITORIAS_POR_PAGINA) setNextPage(0);
        else setNextPage(page + 1);
      })
      .catch((error) => {
        if (error.code !== 401)
          toast.error("Error al obtener Auditorías: " + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));

    setTriggerGetAuditorias(false);
  };

  const getPagination = (page) => ({
    Pagina: page,
    CantidadRegistros: AUDITORIAS_POR_PAGINA,
  });

  const handleChangeFilter = (id, value) => {
    setLoading(true);

    const filtroPadreName = metadataFilters.find(
      (filter) => filter.IdFiltro === id
    ).NombreFiltro;
    const filtrosHijos = metadataFilters.filter(
      (filter) => filter.FiltroPadre === filtroPadreName
    );

    const newFilters = { ...filters };
    if (typeof value === "string") {
      if (value === "") delete newFilters[id];
      else {
        newFilters[id] = { Valor: value };
        filtrosHijos.forEach((filter) => delete newFilters[filter.IdFiltro]);
      }
    } else {
      if (Object.keys(value).length === 0) delete newFilters[id];
      else {
        newFilters[id] = value;
        filtrosHijos.forEach((filter) => delete newFilters[filter.IdFiltro]);
      }
    }

    setFilters(newFilters);
    setTriggerGetAuditorias(true);
  };

  const handleSelectionChange = (id, checked) => {
    let newSelectedIds = [...selectedIds];
    if (checked) {
      newSelectedIds.push(id);
    } else {
      const index = newSelectedIds.indexOf(id);
      newSelectedIds.splice(index, 1);
    }
    setSelectedIds(newSelectedIds);
  };

  const sort = (field, value) => {
    setSortActive({
      IdCampo: field,
      Orden: value,
    });
    setTriggerGetAuditorias(true);
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.Filters count={metadataFilters.length}>
        {metadataFilters.map((filter) => {
          const lista = listas[filter.NombreFiltro];
          const currentOption = filters[filter.IdFiltro]
            ? {
                value: filters[filter.IdFiltro].Valor,
                label: filters[filter.IdFiltro].Etiqueta,
              }
            : { value: "" };

          switch (filter.Tipo) {
            case "text":
              return (
                <FieldText
                  key={filter.IdFiltro}
                  id={filter.IdFiltro || ""}
                  label={filter.Etiqueta}
                  value={filters[filter.IdFiltro]?.Valor || ""}
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            case "date":
              return (
                <FieldDate
                  key={filter.IdFiltro}
                  id={filter.IdFiltro}
                  label={filter.Etiqueta}
                  value={filters[filter.IdFiltro]?.Valor.split("T")[0] || ""}
                  handleChange={handleChangeFilter}
                  readOnly={false}
                  required={false}
                  styles={"filter"}
                />
              );

            case "enum":
              const options = lista?.Valores.map((option) => ({
                value: option.Valor,
                label: option.Etiqueta,
              }));
              return (
                <FieldList
                  key={filter.IdFiltro}
                  id={filter.IdFiltro || ""}
                  type="enum"
                  label={filter.Etiqueta}
                  currentOption={currentOption}
                  options={options}
                  handleChange={handleChangeFilter}
                  canBeEmpty={true}
                  readOnly={false}
                  required={false}
                />
              );

            case "relate":
              return (
                <FieldListSearch
                  key={filter.IdFiltro}
                  id={filter.IdFiltro || ""}
                  label={filter.Etiqueta}
                  listName={filter.NombreFiltro || ""}
                  currentOption={currentOption}
                  handleChange={handleChangeFilter}
                  canBeEmpty={true}
                  readOnly={false}
                  required={false}
                  parent={filter.FiltroPadre}
                  idParent={
                    filters[
                      metadataFilters.find(
                        (f) => f.NombreFiltro === filter.FiltroPadre
                      )?.IdFiltro
                    ]?.Valor
                  }
                />
              );

            default:
              return null;
          }
        })}
      </S.Filters>
      <S.Buttons></S.Buttons>
      <S.Table>
        <S.ColGroup>
          <S.Col />
          {metadataTable.map((campo) => (
            <S.Col key={campo.id} />
          ))}
          <S.Col />
        </S.ColGroup>
        <S.Tr>
          {metadataTable.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              <S.HeaderColumn>
                <span>{campo.etiqueta}</span>
                {campo.ordenable && (
                  <Sort
                    field={campo.nombreCampo}
                    sortActive={sortActive}
                    onClick={(value) => sort(campo.nombreCampo, value)}
                  />
                )}
              </S.HeaderColumn>
            </S.Th>
          ))}
        </S.Tr>
        {auditorias.map((auditoria) => (
          <RegistroAuditoria
            key={auditoria.Id}
            metadataTable={metadataTable}
            auditoria={auditoria}
            listas={listas}
            type="list"
            handleSelectionChange={handleSelectionChange}
            selected={selectedIds.includes(auditoria.id)}
            setLoading={setLoading}
            update={() => setTriggerGetAuditorias(true)}
          />
        ))}
      </S.Table>
      <S.Pagination>
        <button
          disabled={nextPage === 0}
          onClick={() => getAuditorias(nextPage)}
        >
          {nextPage === 0
            ? "No hay más auditorías"
            : "Mostrar más auditorías..."}
        </button>
      </S.Pagination>
    </S.Container>
  );
};
