import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  font-size: 0.8rem;
  padding: 1rem;
  background-color: ${COLORES.BACKGROUND};

  button {
    cursor: pointer;
  }
`;

export const Filters = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.count}, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const Buttons = styled.div`
  display: flex;

  button {
    font-size: 0.75rem;
    font-weight: 600;
    margin-right: 1rem;
    padding: 0.25rem 0.5rem;
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
    color: ${COLORES.BUTTON_TEXT_DEFAULT};
    height: 1.75rem;

    :disabled {
      cursor: default;
    }
  }
`;

export const Table = styled.div`
  display: table;
  font-size: 0.6rem;
  border-collapse: collapse;
  margin: 1rem 0;
  table-layout: fixed;
  width: 100%;
`;

export const ColGroup = styled.div`
  display: table-column-group;
  div:first-child {
    width: 20%;
  }
  div:last-child {
    width: 0%;
  }
`;

export const Col = styled.div`
  display: table-column;
`;

export const Tr = styled.div`
  display: table-row;
  height: 1.75rem;
`;

export const Th = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  border: thin solid ${COLORES.LIGHT_BORDER};
  font-weight: 600;
  padding-left: 0.5rem;
  color: ${COLORES.HEADER_TABLE_FONT};
  background-color: ${COLORES.HEADER_TABLE_BACKGROUND};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const HeaderColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.1rem;
`;

export const Pagination = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;

  button {
    border: none;
    background-color: ${COLORES.BACKGROUND};
    color: ${COLORES.LINK};
    padding: 0;

    :disabled {
      color: ${COLORES.TEXT_DISABLED};
      cursor: default;
    }
  }
`;
