import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { FieldText } from "../FieldText";
import { FieldDatetime } from "../FieldDatetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export const RegistroComentario = ({
  metadataTable,
  comentario,
  update,
  setLoading,
}) => {
  const deleteComentario = () => {
    setLoading(true);

    const data = [comentario.id];
    API.eliminarComentario(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Comentario Eliminado");
          update();
        } else if (res.Estado === "KO") {
          toast.error("No se pudo eliminar el comentario: " + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al eliminar comentario: " + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al eliminar comentario: " + error.msg?.Message);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  return (
    <S.Tr deleted={comentario.eliminado}>
      {metadataTable.map((field) => {
        const type = field.tipo;
        const idField = field.nombreCampo;
        const value = comentario[field.nombreCampo] || "";

        switch (type) {
          case "text":
            return (
              <S.Td key={idField} title={value}>
                <FieldText id={idField} value={value || ""} readOnly={true} />
              </S.Td>
            );

          case "datetime":
            return (
              <S.Td key={idField} title={value}>
                <FieldDatetime
                  id={idField}
                  value={value || ""}
                  readOnly={true}
                />
              </S.Td>
            );

          default:
            return <S.Td key={idField}>{value}</S.Td>;
        }
      })}
      <S.Td>
        <S.Buttons>
          <button
            className="action delete"
            title="Eliminar"
            onClick={deleteComentario}
            disabled={!comentario.mismoUsuario}
          >
            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
          </button>
        </S.Buttons>
      </S.Td>
    </S.Tr>
  );
};

RegistroComentario.propTypes = {
  metadataTable: PropTypes.array,
  comentario: PropTypes.object,
  update: PropTypes.func,
  setLoading: PropTypes.func,
};
