import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  font-size: 0.8rem;
  padding: 1rem;
  background-color: ${COLORES.BACKGROUND};

  button {
    cursor: pointer;
  }

  div#first {
    margin-top: 0;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
`;

export const FieldsContainer = styled.ul`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(3rem, auto);
  background-color: ${COLORES.FONDO};
  padding: 1rem 0 3rem 0;
`;

export const Table = styled.table`
  font-size: 0.7rem;
  border-collapse: collapse;
  margin: 1rem 0 3rem 0;
  table-layout: fixed;
  width: 100%;

  tr {
    height: 2rem;
    border: thin solid lightgray;
  }

  thead tr {
    color: ${COLORES.HEADER_TABLE_FONT};
    background-color: ${COLORES.HEADER_TABLE_BACKGROUND};
  }

  tbody tr td:first-child {
    font-weight: bold;
    padding-left: 1rem;
  }

  tbody tr td:nth-child(2) {
    text-align: center;
  }
`;

export const Tr = styled.tr`
  background-color: ${({ index }) => (index % 2 === 0 ? "white" : "#eee")};
`;

export const ColGroup = styled.colgroup`
  col:first-child {
    width: 30%;
  }
`;
