export const DELAY_TEXT_FILTER = 500; // milisegundos

// MENÚ PRINCIPAL
const COMPROMETIDOS = {
  key: "ion-comprometidos",
  label: "ION Comprometidos",
  accessProperty: "ION",
};
const GASTOS = {
  key: "gastos",
  label: "Gastos",
  accessProperty: "Movimientos",
};
const PREFACTURAS = {
  key: "prefacturas",
  label: "Prefacturas",
  accessProperty: "Prefacturas",
};
const AUTORIZACIONES = {
  key: "autorizaciones",
  label: "Autorizaciones",
  accessProperty: "Autorizacion_Prefacturas",
};
export const INFORMES = {
  key: "informes",
  label: "Informes",
  accessProperty: "Informes",
};
export const MENUS = [COMPROMETIDOS, GASTOS, PREFACTURAS, AUTORIZACIONES];

const AREAS = {
  key: "areas",
  label: "ÁREAS Prefacturas",
  accessProperty: "Areas",
};
const AREAS_ION = {
  key: "areas_ion",
  label: "ÁREAS Ion",
  accessProperty: "IONAreas",
};
const PROVEEDORES = {
  key: "proveedores",
  label: "PROVEEDORES",
  accessProperty: "Proveedores",
};
const PARTIDAS = {
  key: "partidas",
  label: "PARTIDAS",
  accessProperty: "IONPartidas",
};
const USUARIOS = {
  key: "usuarios",
  label: "USUARIOS",
  accessProperty: "Usuarios",
};
const ROLES = {
  key: "roles",
  label: "ROLES",
  accessProperty: "Roles",
};
export const MENUS_EXTRA = [
  AREAS,
  AREAS_ION,
  PROVEEDORES,
  PARTIDAS,
  USUARIOS,
  ROLES,
];

// MOVIMIENTOS
export const MOVIMIENTOS_POR_PAGINA = 20;

// PREFACTURAS
export const PREFACTURAS_POR_PAGINA = 20;

export const METADATA_TABLE_COMENTARIOS = [
  {
    nombreCampo: "fecha",
    etiqueta: "Fecha",
    tipo: "datetime",
  },
  {
    nombreCampo: "usuario",
    etiqueta: "Usuario",
    tipo: "text",
  },
  {
    nombreCampo: "comentario",
    etiqueta: "Comentario",
    tipo: "text",
  },
];

export const ESTADOS_PREFACTURA = {
  ANULADA: 0,
  CREADA: 1,
  POR_VALIDAR: 5,
  POR_VALIDAR_PROVEEDOR: 6,
  VALIDADA_PARCIAL: 8,
  VALIDADA: 10,
  ONBASE: 15,
};

// AUTORIZACIONES
export const AUTORIZACIONES_POR_PAGINA = 20;

// AREAS
export const AREAS_POR_PAGINA = 20;

// PROVEEDORES
export const PROVEEDORES_POR_PAGINA = 20;

// PROVEEDORES
export const PARTIDAS_POR_PAGINA = 20;

// AUDITORIA
export const AUDITORIAS_POR_PAGINA = 20;

// USUARIOS
export const USUARIOS_POR_PAGINA = 20;

// PALETA DE COLORES
export const COLORES = {
  BACKGROUND: "white",
  BORDER: "grey",
  LIGHT_BORDER: "lightgrey",
  MENU_BACKGROUND: process.env.REACT_APP_BAR_COLOR,
  MENU_HOVER: "#ffd0d0",
  MENU_ACTIVE: "indianred",
  LOADING_TEXT: "indianred",
  BLACK: "black",
  LIST_ACTIVE: "indianred",
  LIST_HOVER: "#ffd0d0",
  LINK: "blue",
  TEXT_DISABLED: "grey",
  BACKGROUND_DISABLED: "lightgrey",
  HIGHLIGHTED: "#cee7ff",
  DELETED: "#dd0000",
  DIALOG_COMMENT: "royalblue",
  FORWARD: "green",
  BACKWARD: "red",
  EXPORT: "#2588bb",
  BUTTON_TEXT_DEFAULT: "white",
  BUTTON_BACKGROUND_DEFAULT: "#66aaff",
  HEADER_TABLE_FONT: "white",
  HEADER_TABLE_BACKGROUND: "#0770a7",
  HEADER_TAB: "#44546A",
  HEADER_CARET_TAB: "#c3c3c3",
};

export const formatDate = (date) => {
  const auxArray = date.split("T")[0].split("-");
  const year = auxArray[0];
  const month = auxArray[1];
  const day = auxArray[2];
  return day + "/" + month + "/" + year;
};
