import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  font-size: 0.8rem;
  padding: 1rem;
  background-color: ${COLORES.BACKGROUND};

  button {
    cursor: pointer;
  }
`;

export const Filters = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.count}, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;

  button {
    display: flex;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
    color: ${COLORES.BUTTON_TEXT_DEFAULT};
    min-height: 1.75rem;
    align-items: center;

    :disabled {
      cursor: default;
    }
  }

  #uploadMovimientos {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
    }
  }

  #deleteMovimiento {
    background-color: #ff6666;
    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
    }
  }

  #generarPrefactura {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
    }
  }

  #revisarPromociones {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
    }
  }

  #generarPlantillaCargarGastos {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
    }
  }

  #generarPrefacturaArchivo {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
    }
  }

  #deleteMovimientoArchivo {
    background-color: #ff6666;
    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
    }
  }

  #backPage,
  #nextPage,
  #cleanSelectedIds,
  #selectAllByArea {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
    }
  }
`;

export const ShowDeleted = styled.div`
  margin: auto;
  margin-right: 0;

  label,
  input {
    vertical-align: middle;
    cursor: pointer;
  }

  button {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    :disabled {
      background-color: ${COLORES.BACKGROUND_DISABLED};
    }
  }

  input[type="checkbox"] {
    margin-right: 0.25rem;
  }
`;

export const Table = styled.div`
  display: table;
  font-size: 0.6rem;
  border-collapse: collapse;
  margin: 1rem 0;
  table-layout: fixed;
  width: 100%;
`;

export const ColGroup = styled.div`
  display: table-column-group;
  div:first-child {
    width: 2.5%;
  }
  div:nth-child(10),
  div:nth-child(11) {
    width: 9%;
  }
  div:last-child {
    width: 6%;
  }
`;

export const Col = styled.div`
  display: table-column;
`;

export const Tr = styled.div`
  display: table-row;
  height: 1.75rem;
`;

export const Th = styled.div`
  display: table-cell;
  vertical-align: middle;
  border: thin solid ${COLORES.LIGHT_BORDER};
  font-weight: 600;
  padding-left: 0.5rem;
  color: ${COLORES.HEADER_TABLE_FONT};
  background-color: ${COLORES.HEADER_TABLE_BACKGROUND};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    text-align: center;
    padding: 0;
    div {
      display: inline-block;
    }
  }
`;

export const Checkbox = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`;

export const InputCheckbox = styled.input`
  vertical-align: middle;
  height: 0.9rem;
  width: 0.9rem;
`;

export const HeaderColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.1rem;
`;

export const RecordsByPage = styled.select`
  width: 3.5rem;
  height: 1.75rem;
  text-align: center;
  outline: none;
`;
