import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  font-size: 0.8rem;
  padding: 1rem;
  background-color: ${COLORES.BACKGROUND};
`;

export const Table = styled.div`
  display: table;
  font-size: 0.7rem;
  border-collapse: collapse;
  margin: 1rem 0;
  table-layout: fixed;
  width: 100%;
`;

export const ColGroup = styled.div`
  display: table-column-group;
  div:nth-child(2) {
    width: 50%;
  }
`;

export const Col = styled.div`
  display: table-column;
`;

export const Tr = styled.div`
  display: table-row;
  height: 1.75rem;
`;

export const Th = styled.div`
  display: table-cell;
  vertical-align: middle;
  border: thin solid ${COLORES.LIGHT_BORDER};
  font-weight: 600;
  padding-left: 0.5rem;
  color: ${COLORES.HEADER_TABLE_FONT};
  background-color: ${COLORES.HEADER_TABLE_BACKGROUND};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const HeaderColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.1rem;
`;

export const PaginationComp = styled.div`
  text-align: center;
  span {
    font-weight: 700;
    margin: 0 5px;
  }
  button {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    height: 2rem;
    cursor: pointer;
    color: white;
    border: navajowhite;
    padding: 3px 17px;
    border-radius: 5px;
  }
  button:disabled {
    background-color: ${COLORES.BACKGROUND_DISABLED};
    cursor: not-allowed;
  }
`;
