import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { FieldText } from "../FieldText";
import { FieldNumber } from "../FieldNumber";
import { FieldDate } from "../FieldDate";
import { FieldList } from "../FieldList";

export const RegistroNuevoArea = ({
  metadataTable,
  area: initialValues = {},
  listas,
  type,
  handleSelectionChange,
  selected,
  disabled,
  setLoading,
  updateAreas,
  updateNuevaArea,
}) => {
  const [editing, setEditing] = useState(true);
  const [area, setArea] = useState(initialValues);
  const form = useRef();

  const handleChange = (key, value) => {
    const newValues = { ...area };
    newValues[key] = value;
    setArea(newValues);
  };

  const saveArea = (event) => {
    event.preventDefault();

    const formData = new FormData(form.current);
    const objectFormData = Object.fromEntries(formData);
    objectFormData.id = area.id;
    objectFormData.fechaModificacion = area.fechaModificacion;

    setLoading(true);
    API.guardarArea(objectFormData)
      .then((res) => {
        if (res.Estado === "OK") {
          handleChange("fechaModificacion", res.UltimaModificacion);
          toast.success("Área Guardada");
          setEditing(false);
          updateAreas();
          updateNuevaArea();
        } else if (res.Estado === "NeedReload") {
          toast.warning(
            "Se encontró una versión más reciente del área. Se requiere recargar"
          );
        } else {
          toast.info(res.Mensaje);
        }
      })
      .catch((error) => {
        toast.error("No se pudo guardar el área\n" + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.Tr ref={form} onSubmit={saveArea} highlighted={editing || selected}>
      {type === "detalleProveedor" && (
        <S.Checkbox>
          <S.InputCheckbox
            type="checkbox"
            checked={selected}
            onChange={(e) => handleSelectionChange(area.id, e.target.checked)}
            disabled={disabled}
          />
        </S.Checkbox>
      )}
      {metadataTable.map((field) => {
        const type = field.tipo;
        const idField = field.nombreCampo;
        const value = area ? area[field.nombreCampo] : "";
        const editable = field.editable;
        const required = field.requerido;

        switch (type) {
          case "text":
            return (
              <S.Td key={idField} title={value}>
                <FieldText
                  id={idField}
                  value={value || ""}
                  handleChange={handleChange}
                  readOnly={!editable || !editing}
                  required={required}
                  create={true}
                />
              </S.Td>
            );

          case "int":
          case "float":
            return (
              <S.Td key={idField} title={value}>
                <FieldNumber
                  id={idField}
                  value={value || 0}
                  empty={false}
                  handleChange={handleChange}
                  type={type}
                  readOnly={!editable || !editing}
                  required={required}
                />
              </S.Td>
            );

          case "date":
            return (
              <S.Td key={idField} title={value}>
                <FieldDate
                  id={idField}
                  value={value?.split("T")[0] || ""}
                  handleChange={handleChange}
                  readOnly={!editable || !editing}
                  required={required}
                />
              </S.Td>
            );

          case "enum":
            const currentOption = {
              value: area[idField]?.Valor || "",
              label: area[idField]?.Etiqueta,
            };
            const options = listas[idField]?.Valores.map((option) => ({
              value: option.Valor,
              label: option.Etiqueta,
            }));
            return (
              <S.Td key={idField} title={currentOption.label} type={type}>
                <FieldList
                  id={idField}
                  type={type}
                  currentOption={currentOption}
                  options={options}
                  handleChange={handleChange}
                  readOnly={!editable || !editing}
                  required={required}
                  hiddenIndicators={true}
                />
              </S.Td>
            );

          default:
            return <S.Td key={idField}>{value}</S.Td>;
        }
      })}
      {type === "list" && (
        <S.Td>
          <S.Buttons>
            <button
              key="save"
              className="action save"
              type="submit"
              title="Guardar"
            >
              ✔
            </button>
            <button
              key="cancel"
              className="action cancel"
              type="button"
              title="Cancelar"
              onClick={updateNuevaArea}
            >
              ✘
            </button>
          </S.Buttons>
        </S.Td>
      )}
    </S.Tr>
  );
};

RegistroNuevoArea.propTypes = {
  metadataTable: PropTypes.array,
  area: PropTypes.object,
  listas: PropTypes.object,
  type: PropTypes.string,
  handleSelectionChange: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  setLoading: PropTypes.func,
  updateAreas: PropTypes.func,
  updateNuevaArea: PropTypes.func,
};
