import * as S from "./styles";
import {
  faFileDownload,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useIONEngagedPage from "./useIONEgagedPage";
import { Loading } from "../../components/Loading";
import { FieldList } from "../../components/FieldList/index";
import AccordionPanel from "../../components/Accordion";

export const IONEngagedPage = ({ access }) => {
  const {
    ACCORDION_METADATA,
    loading,
    disabledApplyBTN,
    handleApplyBTN,
    downloadTemplateCargarGastosComprometidos,
    businessSelected,
    businessUnitList,
    handleChangeListInput,
    areaSelected,
    areaList,
    exerciseList,
    exerciseSelected,
    loadListSection,
  } = useIONEngagedPage({ access });

  return (
    <S.IONEngagedPageContent>
      <Loading hidden={!loading} />
      <S.Filters count={4}>
        <FieldList
          id="businessUnitSelect"
          type="enum"
          canBeEmpty={true}
          currentOption={businessSelected || {}}
          options={businessUnitList}
          handleChange={(id, value) =>
            handleChangeListInput(id, value.Valor, value.Etiqueta)
          }
          label="Unidad de Negocio"
        />

        <FieldList
          id="areaSelect"
          type="enum"
          canBeEmpty={true}
          currentOption={areaSelected || {}}
          options={areaList}
          handleChange={(id, value) =>
            handleChangeListInput(id, value.Valor, value.Etiqueta)
          }
          label="Área"
          isDisabled={areaList.length === 0 ? true : false}
        />

        <FieldList
          id="exerciseSelect"
          type="enum"
          canBeEmpty={true}
          currentOption={exerciseSelected || {}}
          options={exerciseList}
          handleChange={(id, value) =>
            handleChangeListInput(id, value.Valor, value.Etiqueta)
          }
          label="Ejercicio"
          isDisabled={exerciseList.length === 0 ? true : false}
        />

        <S.Buttons>
          <button
            id="applyBtn"
            disabled={disabledApplyBTN}
            onClick={handleApplyBTN}
          >
            <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            Aplicar
          </button>
          <button
            id="loadTemplateBtn"
            onClick={downloadTemplateCargarGastosComprometidos}
          >
            <FontAwesomeIcon icon={faFileDownload} size="lg" />
            Plantilla Cargar Gastos
          </button>
        </S.Buttons>
      </S.Filters>

      {ACCORDION_METADATA.map((section, index) => (
        <AccordionPanel
          key={index}
          label={section.label}
          content={section.content}
          access={section.access}
          openAction={section.label === "Listado" ? loadListSection : () => {}}
        />
      ))}
    </S.IONEngagedPageContent>
  );
};
