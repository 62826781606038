import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const Dialog = ({
  type,
  message,
  labelConfirm,
  confirm,
  cancel,
  labelCancel = "Cancelar",
}) => {
  const [value, setValue] = useState("");

  return (
    <S.Container onClick={cancel}>
      <S.Wrapper onClick={(event) => event.stopPropagation()}>
        <S.TitleBar>
          <S.Close onClick={cancel}>×</S.Close>
        </S.TitleBar>
        <S.Content>
          {message.split("\n").map((line, index) => (
            <p key={index} className={type}>
              {line}
            </p>
          ))}
          {type === "input" && (
            <S.Input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              autoFocus
            />
          )}
          <S.Buttons>
            {type === "input" && (
              <>
                {confirm && (
                  <button
                    disabled={!value.length}
                    onClick={() => confirm(value)}
                  >
                    {labelConfirm}
                  </button>
                )}
              </>
            )}
            {type === "confirm" && (
              <>
                {confirm && (
                  <button type="button" onClick={confirm}>
                    {labelConfirm}
                  </button>
                )}
              </>
            )}
            {cancel && <button onClick={cancel}>{labelCancel}</button>}
          </S.Buttons>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

Dialog.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  labelConfirm: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  labelCancel: PropTypes.string,
};
