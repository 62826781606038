import { createContext, useContext, useState, useEffect } from "react";
import Encrypt from "../services/Encrypt";

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    const getPermissions = async () => {
      const encryptedPermissions = localStorage.getItem("access");
      if (encryptedPermissions) {
        try {
          const decryptedPermissions = await Encrypt.decrypt(
            encryptedPermissions
          );
          setPermissions(JSON.parse(decryptedPermissions));
        } catch (error) {
          console.log("Error desencriptando los permisos");
        }
      }
    };

    getPermissions();
  }, []);

  useEffect(() => {
    if (Object.keys(permissions).length > 0) {
      Encrypt.encrypt(JSON.stringify(permissions))
        .then((encryptedAccess) =>
          localStorage.setItem("access", encryptedAccess)
        )
        .catch((error) => console.log(error));
    }
  }, [permissions]);

  return (
    <PermissionsContext.Provider value={{ permissions, setPermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (context === undefined) {
    throw new Error(
      "usePermisos debe ser utilizado dentro de un PermisoProvider"
    );
  }

  return context;
};
