import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { FieldCheckbox } from "../../components/FieldCheckbox";
import { Button } from "../../components/Button";
import { FieldText } from "../../components/FieldText";
import { usePermissions } from "../../context";

export const DetalleRol = () => {
  const { permissions } = usePermissions();
  const { idRol } = useParams();
  const [loading, setLoading] = useState(true);
  const [detalleRol, setDetalleRol] = useState({});
  const history = useHistory();
  useEffect(() => {
    API.obtenerRol(idRol)
      .then((res) => setDetalleRol(res))
      .catch((error) => {
        API.DEVELOP && console.log(error);
        error.code !== 401 &&
          toast("error", `${error.msg?.Message} (código ${error.code})`);
        history.push("/roles");
      })
      .finally(() => setLoading(false));
  }, [idRol, history]);

  const handleChange = (key, value) => {
    const newDetalleRol = { ...detalleRol };
    newDetalleRol[key] = value;
    setDetalleRol(newDetalleRol);
  };

  const handleChangeAccess = (modulo, propiedad, value) => {
    const newDetalleRol = { ...detalleRol };
    newDetalleRol.AccesoModulos[modulo][propiedad] = value;
    setDetalleRol(newDetalleRol);
  };

  const guardarRol = () => {
    setLoading(false);
    const data = detalleRol;
    API.guardarRol(data)
      .then((res) => toast.success("Rol Guardado"))
      .catch((error) => {
        toast.error("No se pudo Guardar el rol");
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const ArrayAccesoModulos = Object.entries(detalleRol.AccesoModulos || []);

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.Subtitle>
        Detalle de Rol - {detalleRol.Nombre}
        <div>
          <Button
            label="Guardar"
            onClick={guardarRol}
            disabled={!permissions?.Roles?.Editar}
          />
        </div>
      </S.Subtitle>

      <FieldText
        label="Nombre"
        id="Nombre"
        type="detail"
        value={detalleRol.Nombre || ""}
        handleChange={handleChange}
      />
      <FieldText
        label="Descripción"
        id="Descripcion"
        type="detail"
        value={detalleRol.Descripcion || ""}
        handleChange={handleChange}
      />
      <S.Subtitle>Permisos por Área</S.Subtitle>
      <S.Table>
        <thead>
          <tr>
            <th>Módulo</th>
            {ArrayAccesoModulos[0] &&
              Object.keys(ArrayAccesoModulos[0][1]).map((key) => (
                <th key={key}>{key}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {ArrayAccesoModulos.map((acceso) => (
            <tr key={acceso[0]}>
              <td>{acceso[0]}</td>
              {Object.keys(ArrayAccesoModulos[0][1]).map((key) => (
                <td key={key}>
                  <FieldCheckbox
                    modulo={acceso[0]}
                    propiedad={key}
                    value={acceso[1][key]}
                    checked={false}
                    handleChange={handleChangeAccess}
                    centered={true}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </S.Table>
    </S.Container>
  );
};
