import { useEffect, useState } from "react";
import { usePermissions } from "../../context";
import { APP_BASE_URL } from "../../api";

export const ProtectedRoute = ({ permit, redirectPath = "/", children }) => {
  const { permissions } = usePermissions();
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    const checkPermissions = () => {
      // Caso usuarios Proveedores => su homepage será /prefacturas
      if (permit === "Movimientos") {
        if (!permissions.Movimientos?.Acceso && permissions.Prefacturas?.Acceso)
          window.location.href = `${APP_BASE_URL}/prefacturas`;
      }

      return !!permissions[permit]?.Acceso;
    };

    if (Object.keys(permissions).length > 0) {
      setShowChildren(checkPermissions());
    }
  }, [permit, permissions]);

  return showChildren ? children : <></>;
};
