import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  font-size: 0.8rem;
  padding: 1rem;
  background-color: ${COLORES.BACKGROUND};

  button {
    cursor: pointer;
  }
`;

export const Filters = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  button {
    display: flex;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0.25rem 0.5rem;
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
    color: ${COLORES.BUTTON_TEXT_DEFAULT};
    min-height: 1.75rem;
    align-items: center;

    :disabled {
      cursor: default;
    }
  }

  .forward {
    background-color: ${COLORES.FORWARD};
  }

  .backward {
    background-color: ${COLORES.BACKWARD};
  }

  .hidden {
    display: none;
  }
  input {
    vertical-align: middle;
  }

  label {
    display: inline-block;
  }
  label[disabled] {
    color: darkgrey;
    background-color: lightgrey;
    cursor: default;
  }

  #uploadMovimientos {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
  }
  #uploadMovimientos[disabled] {
    background-color: lightgrey;
  }

  #darAutorizacion,
  #cancelarAutorizacion {
    :disabled {
      background-color: lightgrey;
    }
  }

  #cleanSelectedIds,
  #selectAllByArea,
  #generarPrefactura {
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    :disabled {
      background-color: lightgrey;
    }
  }
`;

export const Table = styled.div`
  display: table;
  font-size: 0.6rem;
  border-collapse: collapse;
  margin: 1rem 0;
  table-layout: fixed;
  width: 100%;
`;

export const ColGroup = styled.div`
  display: table-column-group;
  div:first-child {
    width: 2.5%;
  }
  div:nth-child(2) {
    width: 3%;
  }
  div:last-child {
    width: 10%;
  }
`;

export const Col = styled.div`
  display: table-column;
`;

export const Tr = styled.div`
  display: table-row;
  height: 1.75rem;
`;

export const Th = styled.div`
  display: table-cell;
  vertical-align: middle;
  border: thin solid ${COLORES.LIGHT_BORDER};
  font-weight: 600;
  padding-left: 0.5rem;
  color: ${COLORES.HEADER_TABLE_FONT};
  background-color: ${COLORES.HEADER_TABLE_BACKGROUND};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    text-align: center;
    padding: 0;
    div {
      display: inline-block;
    }
  }
`;

export const Checkbox = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`;

export const InputCheckbox = styled.input`
  vertical-align: middle;
  height: 0.9rem;
  width: 0.9rem;
`;

export const HeaderColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.1rem;
`;

export const Pagination = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;

  button {
    border: none;
    background-color: ${COLORES.BACKGROUND};
    color: ${COLORES.LINK};
    padding: 0;

    :disabled {
      color: ${COLORES.TEXT_DISABLED};
      cursor: default;
    }
  }
`;
