import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Accordion = styled.div`
  border: thin solid ${COLORES.BORDER};
  border-radius: 0.25rem;
  margin: 1rem 0;
`;

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  background-color: ${COLORES.HEADER_TAB};
  border-radius: ${({ visible }) => (visible ? "0.2rem 0.2rem 0 0" : "0.2rem")};
  cursor: pointer;

  span {
    font-weight: 600;
    color: white;
  }

  svg {
    transform: rotate(${({ visible }) => (visible ? "180deg" : "0")});
    transition: transform 0.5s;
    color: white;
  }

  .disabled {
    cursor: not-allowed;
  }
`;

export const Content = styled.div``;
