import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import sort from "../../assets/images/sort.svg";
import sortup from "../../assets/images/sort_up.svg";
import sortdown from "../../assets/images/sort_down.svg";

export const Sort = ({ field, sortActive, onClick }) => {
  const [status, setStatus] = useState("asc");
  useEffect(() => {
    if (field !== sortActive.IdCampo) setStatus("");
  }, [field, sortActive]);

  const icon = status === "asc" ? sortup : status === "desc" ? sortdown : sort;

  const handleClick = () => {
    switch (status) {
      case "":
      case "desc":
        onClick("asc");
        setStatus("asc");
        break;
      case "asc":
        onClick("desc");
        setStatus("desc");
        break;
      default:
        break;
    }
  };

  return (
    <S.Wrapper onClick={handleClick}>
      <img alt="sort" src={icon} />
    </S.Wrapper>
  );
};

Sort.propTypes = {
  field: PropTypes.string,
  sortActive: PropTypes.object,
  onClick: PropTypes.func,
};
