import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const Loading = ({ ...props }) => {
  return (
    <S.Container {...props}>
      <S.Wrapper>
        <S.Content>Cargando...</S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

Loading.propTypes = {
  props: PropTypes.any,
};
