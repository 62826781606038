import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { Button } from "../../components/Button";
import { FieldText } from "../../components/FieldText";
import { FieldDate } from "../../components/FieldDate";
import { FieldDatetime } from "../../components/FieldDatetime";
import { FieldList } from "../../components/FieldList";
import { FieldListSearch } from "../../components/FieldListSearch";
import { FieldCheckbox } from "../../components/FieldCheckbox";
import { usePermissions } from "../../context";

export const DetalleUsuario = () => {
  const { permissions } = usePermissions();
  const { idUsuario } = useParams();
  const [loading, setLoading] = useState(true);
  const [metadataUsuario, setMetadataUsuario] = useState([]);
  const [listas, setListas] = useState({});
  const [usuario, setUsuario] = useState({});
  const [triggerGetUsuario, setTriggerGetUsuario] = useState(true);
  const history = useHistory();
  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({ Modulo: "Usuarios", TipoDefinicion: "detalle" })
      .then((res) => isMounted && setMetadataUsuario(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerListas("Usuarios")
      .then((res) => isMounted && setListas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, [idUsuario]);
  useEffect(() => {
    let isMounted = true;
    triggerGetUsuario && isMounted && obtenerUsuario();
    return () => (isMounted = false);
  }, [triggerGetUsuario]); // eslint-disable-line react-hooks/exhaustive-deps

  const obtenerUsuario = () => {
    API.obtenerUsuario(idUsuario)
      .then((res) => setUsuario(res))
      .catch((error) => {
        if (error.code !== 401) toast.error("Error al obtener Usuario");
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

    setTriggerGetUsuario(false);
  };

  const handleChange = (key, value) => {
    const newUsuario = { ...usuario };
    newUsuario[key] = value;
    setUsuario(newUsuario);
  };

  const handleChangeCheckbox = (modulo, propiedad, value) => {
    handleChange(propiedad, value);
  };

  const handleChangeAreaPrefacturas = (modulo, propiedad, value) => {
    const newUsuario = structuredClone(usuario);
    const listaAreas = newUsuario.ListaAreasPrefacturas;
    listaAreas.forEach((area, index) => {
      if (area.Descripcion === modulo) {
        if (propiedad === "Acceso") listaAreas[index][propiedad] = value;
        else {
          if (value) {
            listaAreas[index].NivelAutorizacion.push(parseInt(propiedad, 10));
            listaAreas[index].NivelAutorizacion = listaAreas[
              index
            ].NivelAutorizacion.sort((a, b) => a - b);
          } else {
            listaAreas[index].NivelAutorizacion = listaAreas[
              index
            ].NivelAutorizacion.filter(
              (nivel) => nivel !== parseInt(propiedad, 10)
            );
          }
        }
      }
    });
    setUsuario(newUsuario);
  };

  const handleChangeAreaION = (modulo, propiedad, value) => {
    const newUsuario = structuredClone(usuario);
    const listaAreas = newUsuario.ListaAreasION;
    listaAreas.forEach((area, index) => {
      if (area.Descripcion === modulo) {
        listaAreas[index][propiedad] = value;
      }
    });
    setUsuario(newUsuario);
  };

  const guardarUsuario = () => {
    const data = { ...usuario };
    data.Rol = data.Rol?.Valor || "";
    data.Proveedor = data.Proveedor?.Valor || "";

    setLoading(true);
    API.guardarUsuario(data)
      .then((res) => {
        if (res?.Estado === "OK") {
          toast.success("Usuario Guardado");
          if (idUsuario === "create") history.push("/usuarios");
        } else {
          throw new Error(res?.Mensaje);
        }
      })
      .catch((error) => {
        toast.error("Error al guardar usuario\n" + error.message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const nivelesAutorizacion = [1, 2, 3, 4];

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.Subtitle>
        Detalle de Usuario - {usuario.NombreUsuario}
        <div>
          <Button
            label={idUsuario === "create" ? "Crear Usuario" : "Guardar"}
            onClick={guardarUsuario}
            disabled={!permissions?.Usuarios?.Editar}
          />
        </div>
      </S.Subtitle>
      <S.FieldsContainer>
        {metadataUsuario.map((field) => {
          const idField = field.id;
          const nameField = field.nombreCampo;
          const type = field.tipo;
          const label = field.etiqueta;
          const value = usuario[field.nombreCampo] || "";
          const editable = field.editable;
          const required = field.requerido;
          const currentOption = {
            value: usuario[nameField]?.Valor || "",
            label: usuario[nameField]?.Etiqueta || "",
          };

          switch (type) {
            case "text":
              return (
                <FieldText
                  key={idField}
                  id={nameField}
                  label={label}
                  value={value || ""}
                  handleChange={handleChange}
                  readOnly={!editable}
                  required={required}
                  styles={"filter"}
                />
              );

            case "date":
              return (
                <FieldDate
                  key={idField}
                  id={nameField}
                  label={label}
                  value={value.split("T")[0] || ""}
                  handleChange={handleChange}
                  readOnly={!editable}
                  required={required}
                  styles={"filter"}
                />
              );

            case "datetime":
              return (
                <FieldDatetime
                  key={idField}
                  id={nameField}
                  label={label}
                  value={value || "T"}
                  handleChange={handleChange}
                  readOnly={!editable}
                  required={required}
                  styles={"filter"}
                />
              );

            case "bool":
              return (
                <FieldCheckbox
                  key={idField}
                  id={nameField}
                  propiedad={nameField}
                  label={label}
                  value={usuario[field.nombreCampo] || false}
                  handleChange={handleChangeCheckbox}
                  readOnly={!editable}
                  centered={false}
                />
              );

            case "enum":
              const options = listas[nameField]?.Valores.map((option) => ({
                value: option.Valor,
                label: option.Etiqueta,
              }));
              return (
                <FieldList
                  key={idField}
                  id={nameField}
                  type={type}
                  label={label}
                  currentOption={currentOption}
                  options={options}
                  handleChange={handleChange}
                  canBeEmpty={!required}
                  readOnly={!editable}
                  required={required}
                />
              );

            case "relate":
              return (
                <FieldListSearch
                  key={idField}
                  id={nameField}
                  label={label}
                  listName={nameField}
                  currentOption={currentOption}
                  handleChange={handleChange}
                  canBeEmpty={!required}
                  readOnly={!editable}
                  required={required}
                />
              );

            default:
              return (
                <FieldText
                  key={idField}
                  id={idField}
                  label={label}
                  value={value.toString()}
                  readOnly={true}
                  required={false}
                />
              );
          }
        })}
      </S.FieldsContainer>

      <S.Subtitle>
        Acceso y Nivel de Autorización por Área PREFACTURAS
      </S.Subtitle>
      <S.Table>
        <S.ColGroup>
          <col />
          <col />
          <col />
          {nivelesAutorizacion.map((nivel) => (
            <col key={nivel} />
          ))}
        </S.ColGroup>
        <thead>
          <tr>
            <th>Área</th>
            <th>Código</th>
            <th>Acceso</th>
            {nivelesAutorizacion.map((nivel) => (
              <th key={nivel}>Nivel {nivel}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {usuario?.ListaAreasPrefacturas?.filter((area) => area.Acceso).map(
            (area, index) => (
              <S.Tr key={area.IdArea} index={index}>
                <td>{area.Descripcion}</td>
                <td>{area.Codigo}</td>
                <td>
                  <FieldCheckbox
                    modulo={area.Descripcion}
                    propiedad={"Acceso"}
                    value={area.Acceso}
                    handleChange={handleChangeAreaPrefacturas}
                    readOnly={false}
                    centered={true}
                  />
                </td>
                {nivelesAutorizacion.map((nivel) => (
                  <td key={nivel}>
                    <FieldCheckbox
                      modulo={area.Descripcion}
                      propiedad={nivel.toString()}
                      value={area.NivelAutorizacion.includes(nivel)}
                      handleChange={handleChangeAreaPrefacturas}
                      readOnly={false}
                      centered={true}
                    />
                  </td>
                ))}
              </S.Tr>
            )
          )}
          {usuario?.ListaAreasPrefacturas?.filter((area) => !area.Acceso).map(
            (area, index) => (
              <S.Tr
                key={area.IdArea}
                index={
                  index +
                  (usuario?.ListaAreasPrefacturas?.filter((area) => area.Acceso)
                    .length %
                    2)
                }
              >
                <td>{area.Descripcion}</td>
                <td>{area.Codigo}</td>
                <td>
                  <FieldCheckbox
                    modulo={area.Descripcion}
                    propiedad={"Acceso"}
                    value={area.Acceso}
                    handleChange={handleChangeAreaPrefacturas}
                    readOnly={false}
                    centered={true}
                  />
                </td>
                {nivelesAutorizacion.map((nivel) => (
                  <td key={nivel}>
                    <FieldCheckbox
                      modulo={area.Descripcion}
                      propiedad={nivel.toString()}
                      value={area.NivelAutorizacion.includes(nivel)}
                      handleChange={handleChangeAreaPrefacturas}
                      readOnly={true}
                      centered={true}
                    />
                  </td>
                ))}
              </S.Tr>
            )
          )}
        </tbody>
      </S.Table>

      <S.Subtitle>Acceso y Nivel de Autorización por Área ION</S.Subtitle>
      <S.Table>
        <S.ColGroup>
          <col />
          <col />
          <col />
        </S.ColGroup>
        <thead>
          <tr>
            <th>Área</th>
            <th>Código</th>
            <th>Acceso</th>
          </tr>
        </thead>
        <tbody>
          {usuario?.ListaAreasION?.filter((area) => area.Acceso).map(
            (area, index) => (
              <S.Tr key={area.IdArea} index={index}>
                <td>{area.Descripcion}</td>
                <td>{area.Codigo}</td>
                <td>
                  <FieldCheckbox
                    modulo={area.Descripcion}
                    propiedad={"Acceso"}
                    value={area.Acceso}
                    handleChange={handleChangeAreaION}
                    readOnly={false}
                    centered={true}
                  />
                </td>
              </S.Tr>
            )
          )}
          {usuario?.ListaAreasION?.filter((area) => !area.Acceso).map(
            (area, index) => (
              <S.Tr
                key={area.IdArea}
                index={
                  index +
                  (usuario?.ListaAreasION?.filter((area) => area.Acceso)
                    .length %
                    2)
                }
              >
                <td>{area.Descripcion}</td>
                <td>{area.Codigo}</td>
                <td>
                  <FieldCheckbox
                    modulo={area.Descripcion}
                    propiedad={"Acceso"}
                    value={area.Acceso}
                    handleChange={handleChangeAreaION}
                    readOnly={false}
                    centered={true}
                  />
                </td>
              </S.Tr>
            )
          )}
        </tbody>
      </S.Table>
    </S.Container>
  );
};
