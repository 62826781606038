import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { RegistroArea } from "../../components/RegistroArea";
import { Sort } from "../../components/Sort";
import { AREAS_POR_PAGINA } from "../../constants";
import { RegistroNuevoArea } from "../../components/RegistroNuevoArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { usePermissions } from "../../context";

export const Areas = () => {
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [areas, setAreas] = useState([]);
  const [metadataTable, setMetadataTable] = useState([]);
  const [listas, setListas] = useState({});
  const [nextPage, setNextPage] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [sortActive, setSortActive] = useState({});
  const [triggerGetAreas, setTriggerGetAreas] = useState(true);
  const [triggerNuevaArea, setTriggerNuevaArea] = useState(false);
  useEffect(() => {
    let isMounted = true;

    API.definicionPagina({ Modulo: "Areas", TipoDefinicion: "grilla" })
      .then((res) => isMounted && setMetadataTable(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerListas("Areas")
      .then((res) => isMounted && setListas(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, []);
  useEffect(() => {
    let isMounted = true;
    isMounted && triggerGetAreas && getAreas();
    return () => (isMounted = false);
  }, [triggerGetAreas]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAreas = (page = 1) => {
    setLoading(true);
    const data = {};
    data.Paginacion = getPagination(page);
    if (Object.keys(sortActive).length > 0) data.Ordenacion = sortActive;
    data.MostrarEliminados = false;

    API.obtenerAreas(data)
      .then((res) => {
        if (page === 1) setAreas(res);
        else setAreas([...areas, ...res]);

        if (res.length < AREAS_POR_PAGINA) setNextPage(0);
        else setNextPage(page + 1);
      })
      .catch((error) => {
        if (error.code !== 401)
          toast.error("Error al obtener Áreas\n" + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));

    setTriggerGetAreas(false);
  };

  const getPagination = (page) => ({
    Pagina: page,
    CantidadRegistros: AREAS_POR_PAGINA,
  });

  const handleSelectionChange = (id, checked) => {
    let newSelectedIds = [...selectedIds];
    if (checked) {
      newSelectedIds.push(id);
    } else {
      const index = newSelectedIds.indexOf(id);
      newSelectedIds.splice(index, 1);
    }
    setSelectedIds(newSelectedIds);
  };

  const sort = (field, value) => {
    setSortActive({
      IdCampo: field,
      Orden: value,
    });
    setTriggerGetAreas(true);
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.Table>
        <S.ColGroup>
          {metadataTable.map((campo) => (
            <S.Col key={campo.id} />
          ))}
          <S.Col />
        </S.ColGroup>
        <S.Tr>
          {metadataTable.map((campo) => (
            <S.Th key={campo.id} title={campo.etiqueta}>
              <S.HeaderColumn>
                <span>{campo.etiqueta}</span>
                {campo.ordenable && (
                  <Sort
                    field={campo.nombreCampo}
                    sortActive={sortActive}
                    onClick={(value) => sort(campo.nombreCampo, value)}
                  />
                )}
              </S.HeaderColumn>
            </S.Th>
          ))}
          <S.Th />
        </S.Tr>
        {areas.map((area) => (
          <RegistroArea
            key={area.id}
            metadataTable={metadataTable}
            area={area}
            listas={listas}
            type="list"
            handleSelectionChange={handleSelectionChange}
            selected={selectedIds.includes(area.id)}
            setLoading={setLoading}
            update={() => setTriggerGetAreas(true)}
            access={permissions}
          />
        ))}
        {triggerNuevaArea === true && (
          <>
            <RegistroNuevoArea
              key={"00000000-0000-0000-0000-000000000000"}
              metadataTable={metadataTable}
              listas={listas}
              type="list"
              handleSelectionChange={handleSelectionChange}
              setLoading={setLoading}
              updateAreas={() => setTriggerGetAreas(true)}
              updateNuevaArea={() => setTriggerNuevaArea(false)}
            />
          </>
        )}
      </S.Table>

      <S.Buttons>
        <button
          onClick={() => setTriggerNuevaArea(true)}
          disabled={!permissions?.Areas?.Editar || triggerNuevaArea}
        >
          <FontAwesomeIcon icon={faPlusSquare} size="lg" pull="left" />
          Agregar área
        </button>
      </S.Buttons>

      <S.Pagination>
        <button disabled={nextPage === 0} onClick={() => getAreas(nextPage)}>
          {nextPage === 0 ? "No hay más áreas" : "Mostrar más áreas..."}
        </button>
      </S.Pagination>
    </S.Container>
  );
};
