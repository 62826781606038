import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { Link } from "react-router-dom";
import { FieldText } from "../FieldText";
import { Button } from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "../Dialog";

export const RegistroPartida = ({
  metadataTable,
  partida,
  access,
  setLoading,
  toast,
  update,
}) => {
  const [dialogConfirmarVisible, setDialogConfirmarVisible] = useState(false);

  const eliminarPartida = () => {
    const data = { IdPartida: partida.Id };

    setLoading(true);
    API.eliminarPartida(data)
      .then((res) => {
        if (res.Estado === "OK") {
          toast.success("Partida Eliminada");
          update();
        } else if (res.Estado === "KO") {
          toast.error("No se pudo eliminar la partida\n" + res.Mensaje);
          setLoading(false);
        } else {
          toast.info("Error al eliminar la partida\n" + res.Mensaje);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Error al eliminar la partida\n" + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.Tr>
      <S.Td button>
        <Link to={`/partidas/${partida.Id}`}>
          <Button
            label="👁"
            title="Ver detalle"
            className="edit"
            disabled={!access?.IONPartidas?.Acceso}
          />
        </Link>
      </S.Td>
      {metadataTable.map((field) => {
        const type = field.tipo;
        const idField = field.nombreCampo;
        const value = partida[field.nombreCampo] || "";
        const required = field.requerido;

        switch (type) {
          case "text":
          default:
            return (
              <S.Td key={idField} title={value}>
                <FieldText
                  id={idField}
                  value={value}
                  readOnly={true}
                  required={required}
                />
              </S.Td>
            );

          case "bool":
            return (
              <S.Td key={idField}>
                <FieldText
                  id={idField}
                  value={value ? "Sí" : "No"}
                  readOnly={true}
                  required={required}
                />
              </S.Td>
            );
        }
      })}
      <S.Td>
        <S.Buttons>
          <button
            key="eliminarPartida"
            className="action delete"
            type="button"
            title="Eliminar Partida"
            onClick={() => setDialogConfirmarVisible(true)}
            disabled={!access?.IONPartidas?.Eliminar}
          >
            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
          </button>
        </S.Buttons>
        {dialogConfirmarVisible && (
          <Dialog
            type="confirm"
            message="¿Quieres eliminar la partida?"
            labelConfirm="Eliminar"
            confirm={eliminarPartida}
            cancel={() => setDialogConfirmarVisible(false)}
          />
        )}
      </S.Td>
    </S.Tr>
  );
};

RegistroPartida.propTypes = {
  metadataTable: PropTypes.array,
  partida: PropTypes.object,
  access: PropTypes.object,
  setLoading: PropTypes.func,
  toast: PropTypes.func,
  update: PropTypes.func,
};
