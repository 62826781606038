import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { Link } from "react-router-dom";
import { Button } from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { MENUS, INFORMES, MENUS_EXTRA } from "../../constants";
import { useMsal } from "@azure/msal-react";
import { usePermissions } from "../../context";
import { Loading } from "../Loading";
import logo from "../../assets/images/aliseda_anticipa_logo.png";
const { REACT_APP_INFORMES_BASE_URL } = process.env;

export const NavMenu = ({ pathname }) => {
  const { permissions } = usePermissions();
  const [active, setActive] = useState("");
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { instance, accounts } = useMsal();
  const username = accounts[0]?.username;
  const [loading, setLoading] = useState(false);

  useEffect(() => setActive(pathname), [pathname]);

  const openInformes = () => {
    setLoading(true);
    API.generarAutorizacionReporte()
      .then((res) => {
        const reportesPath = `${REACT_APP_INFORMES_BASE_URL}/signIn?key=${encodeURIComponent(
          res
        )}`;
        window.open(reportesPath, "_blank");
      })
      .catch((error) => console.log("generarAutorizacionReporte", error))
      .finally(() => setLoading(false));
  };

  const logout = () => {
    localStorage.clear();
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  };

  const menuExtra = MENUS_EXTRA.find((menu) => menu.key === active);

  return (
    <>
      <S.Nav>
        <Link to={"/"}>
          <S.Img src={logo} alt="Logo" />
        </Link>
        <S.MenuContainer>
          <ul>
            {MENUS.map((menu) => {
              if (permissions && permissions[menu.accessProperty]?.Acceso) {
                return (
                  <li
                    key={menu.key}
                    className={active === menu.key ? "active" : null}
                  >
                    <Link
                      to={`/${menu.key}`}
                      onClick={() => setActive(menu.key)}
                    >
                      {menu.label.toUpperCase()}
                    </Link>
                  </li>
                );
              } else return null;
            })}
            {permissions.Informes?.Acceso && (
              <li onClick={openInformes}>
                <div>{INFORMES.label.toUpperCase()}</div>
              </li>
            )}
            {menuExtra && (
              <li className={active === menuExtra.key ? "active" : null}>
                <Link to={`/${menuExtra.key}`}>{menuExtra.label}</Link>
              </li>
            )}
          </ul>
        </S.MenuContainer>
        <S.UserContainer>
          <S.User>{username}</S.User>
          <S.ButtonsContainer>
            <Button
              label="▾"
              onClick={() => setUserMenuOpened(!userMenuOpened)}
            />
            {userMenuOpened && (
              <S.Ul>
                {MENUS_EXTRA.map((menu) => {
                  if (permissions && permissions[menu.accessProperty]?.Acceso) {
                    const label =
                      menu.label[0] + menu.label.slice(1).toLowerCase();
                    return (
                      <Link to={`/${menu.key}`} key={menu.key}>
                        <S.Li onClick={() => setUserMenuOpened(false)}>
                          {label}
                        </S.Li>
                      </Link>
                    );
                  } else return null;
                })}
                <S.Li id="last" onClick={logout}>
                  <span>
                    <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
                  </span>
                  Cerrar sesión
                </S.Li>
              </S.Ul>
            )}
          </S.ButtonsContainer>
        </S.UserContainer>
      </S.Nav>
      <Loading hidden={!loading} />
    </>
  );
};

NavMenu.propTypes = {
  pathname: PropTypes.string.isRequired,
};
