import styled from "styled-components";
import { COLORES } from "../../constants";

export const Table = styled.div`
  display: table;
  font-size: 0.6rem;
  border-collapse: collapse;
  margin: 1rem 0;
  table-layout: fixed;
  width: 100%;
`;

export const ColGroup = styled.div`
  display: table-column-group;
`;

export const Col = styled.div`
  display: table-column;
`;

export const Tr = styled.div`
  display: table-row;
  height: 1.75rem;
`;

export const Th = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border: thin solid ${COLORES.LIGHT_BORDER};
  font-weight: 600;
  color: ${COLORES.HEADER_TABLE_FONT};
  background-color: ${COLORES.HEADER_TABLE_BACKGROUND};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const HeaderColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: fit-content;
`;

export const Td = styled.div`
  display: table-cell;
  vertical-align: middle;
  border: thin solid ${COLORES.LIGHT_BORDER};
  padding: 0.5rem;
  overflow: hidden;
  text-align: center;
`;

export const EmptyRegs = styled.div`
  display: table;
  height: 1.75rem;
  width: 100%;

  div {
    display: table-row;
    text-align: center;
    color: ${COLORES.DELETED};
    font-size: medium;
    font-weight: 700;
  }
`;

export const Pagination = styled.div`
  text-align: center;

  button {
    padding: 0.3rem;
    border-radius: 0.2rem;
    background-color: ${COLORES.BUTTON_BACKGROUND_DEFAULT};
    color: white;
    border: none;
    font-weight: 700;

    svg,
    span {
      margin: 0 0.3rem;
    }
  }

  button:disabled {
    background-color: ${COLORES.BACKGROUND_DISABLED};
  }

  span {
    font-weight: 700;
    font-size: 0.8rem;
    margin: 0 0.75rem;
  }
`;
